import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IReport, reportSourceTypeEnum } from 'src/app/core/interfaces/report.interface';
import { ReportService } from './report.service';
@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss']
})
export class AddReportComponent implements OnInit, OnDestroy {
  idReport!: string | null
  readonly sourceTypesLabels: { [key: string]: string; } = { 'power-bi': 'POWER BI', 'sap-bo': 'SAP BO', 'link': 'LINK', 'file': 'FILE' };
  reportSourceTypes: string[] = [reportSourceTypeEnum.POWER_BI, reportSourceTypeEnum.SAP_BO, reportSourceTypeEnum.FILE, reportSourceTypeEnum.LINK];
  step = 1;
  report!: IReport | null;
  resultOfStep!: Subscription;
  resultOfReportService!: Subscription;

  constructor(
    public reportService: ReportService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    // Initialization:
    this.reportService.newChildrenSelected.next([])
    this.reportService.newFolderPathItems.next([])
    this.reportService.newPlatformItem.next([])
    this.reportService.nextStep(1)
    let newSourceType!: reportSourceTypeEnum;
    this.reportService.newSourceType.next(newSourceType)

    this.resultOfStep = this.reportService.sharedStep.subscribe(data => {
      this.step = data;
    })

    this.resultOfReportService = this.reportService.sharedReport.subscribe(data => {
      this.report = data;
    })
    this.idReport = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.idReport) {
      this.reportService.nextStep(2);
    }
    else {
      this.reportService.nextStep(1)
      this.reportService.newReport.next(null)
    }
  }

  getSourceTypeOnChange(event: reportSourceTypeEnum): void {
    this.reportService.nextSourceType(event)
  }

  ngOnDestroy(): void {
    this.resultOfStep.unsubscribe();
    this.resultOfReportService.unsubscribe();
  }
}
