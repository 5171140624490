import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { concatMap } from 'rxjs/operators';
import { headerGroups } from 'src/app/core/constants/admin';
import { ISourceGroup } from 'src/app/core/interfaces/group';
import { IGroup, ITableHeader } from 'src/app/core/interfaces/section.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-all-groups',
  templateUrl: './all-groups.component.html',
  styleUrls: ['./all-groups.component.scss']
})
export class AllGroupsComponent implements OnInit {
  currentPage = 1;
  total = 0;
  skip = 0;
  readonly limit = 10;
  end = 0;
  tableHeader: ITableHeader[] = headerGroups;
  groups: IGroup[] = [];

  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllGroups();
  }

  getAllGroups(): void {
    this.dashboardService
      .getGroups(this.skip, this.limit)
      .subscribe((data) => {
        this.total = data.total;
        this.groups = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }
  getTypeGroup(grp: ISourceGroup[], key: string): ISourceGroup[] {
    return grp.filter(g => g.type === key);
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getAllGroups();
  }

  editGroup(groupId: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['group', groupId] } }]);
  }

  deleteGroup(groupId: number): void {
    if (confirm("Are you sure to delete")) {
      this.dashboardService.deleteGroup(groupId).pipe(
        concatMap(() => {
          this.snackbarService.show('Delete successfully', 'success');
          return this.dashboardService.getGroups(this.skip, this.limit);
        })
      ).subscribe((data) => {
        this.total = data.total;
        this.groups = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  addGroups(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['group'] } }]);

  }
}
