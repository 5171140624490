<section class="all-tags-container d-flex flex-column p-5">
  <div class="all-tags-header my-3">
    <h4 class="mb-3">TAG MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL TAGS</h6>
      <div class="d-flex align-items-center">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button add tag"
          id="btn-add-tag" (click)="addTags()">Add A Tag</button>
      </div>
    </div>
  </div>
  <div class="all-tags-content mt-4">
    <div class="d-flex justify-content-end">
      <span
        class="result-text">Showing {{skip + 1}}-{{end}} of {{total}} items
      </span>
    </div>
    <div class="table-all-tags pb-5">
      <table class="table table-hover table-striped mt-3">
        <thead>
          <tr>
            <ng-container *ngFor="let header of tableHeader;">
              <th scope="col" class="text-center pointer" (click)="sortTable(header)">
                <div class="d-flex justify-content-between align-items-center mx-2">
                  <div> {{ header.label }}</div>
                  <i class="icon icon-14 ml-2" [ngClass]="header?.direction ? 'icon-polygon-down' : 'icon-polygon'"></i>
                </div>
              </th>
            </ng-container>
            <th scope="col" class="text-center">DISABLE</th>
            <th scope="col" class="text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          <div class="loader-container position-absolute pt-5 mt-5" *ngIf="!tags.length">
            <app-loader></app-loader>
          </div>
          <tr *ngFor="let tag of tags">
            <td class="col-md">{{tag.name }}</td>
            <td class="col-5">{{tag.comment }}</td>
            <td class="col-md">{{tag.createdAt | dateFormat : 'short'}}</td>
            <td class="col-md">{{tag.updatedAt | dateFormat : 'short'}}</td>
            <td class="col-md">
              <div class="d-flex justify-content-center">
                <i class="icon icon-16 icon-checkmark-black" *ngIf="!tag.enabled"></i>
              </div>
            </td>
            <td class="col-md">
              <div class="actions d-flex justify-content-center ms-5">
                <div class="icon icon-25 icon-edit pointer" (click)="editTag(tag.id)"
                  tooltip="Edit" placement="bottom"
                  containerClass="tooltipClass"></div>
                <div role="button" class="icon icon-25 icon-delete ms-2 pointer" (click)="deleteTag(tag.id)"
                  tooltip="Delete" placement="bottom"
                  containerClass="tooltipClass"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end mt-3" *ngIf="tags?.length">
        <pagination [directionLinks]="false" [totalItems]="total" [itemsPerPage]="limit" [(ngModel)]="currentPage"
          (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
    </div>
  </div>
</section>
