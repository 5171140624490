<section class="e-learning-container d-flex flex-column p-5">
  <div class="e-learning-header my-3">
    <h4 class="mb-3">OFFERS MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>{{eLearning ? 'EDIT AN OFFER' : 'ADD AN OFFER'}}
      </h6>
    </div>
  </div>

  <div class="e-learning-content mt-4" *ngIf="eLearningForm">
    <form [formGroup]="eLearningForm" class="g-3">

      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="eLearningName" class="form-label">Name</label>
          <input type="text" class="form-control" id="eLearningName" formControlName="name"
            [ngClass]="{'is-invalid': eLearningForm.controls.name.invalid && eLearningForm.controls.name.touched}">
          <div *ngIf="eLearningForm.controls.name.errors?.required && eLearningForm.controls.name.touched"
            class="invalid-feedback">
            upksilling offer must have a name
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="eLearningUrl" class="form-label">URL</label>
          <input type="text" class="form-control" id="eLearningUrl" formControlName="url"
            [ngClass]="{'is-invalid': eLearningForm.controls.url.invalid && eLearningForm.controls.url.touched}">
          <div *ngIf="eLearningForm.controls.url.errors?.required && eLearningForm.controls.url.touched"
            class="invalid-feedback">
            upksilling offer must have a link
          </div>
        </div>
      </div>


      <div class="col-12 mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="enabled" id="eLearningDisabled"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="eLearningDisabled">
            Disable Offer?
          </label>
        </div>
      </div>

      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button"
          attr.aria-label="button submit eLearning" id="btn-submit-e-learning" [disabled]="eLearningForm.invalid"
          (click)="submitELearning()">
          {{eLearning ? 'Edit Offer' : 'Add An Offer'}}
        </button>
      </div>

    </form>
  </div>
</section>
