

<section class="all-sections-container d-flex flex-column p-5" *ngIf="!dashboardService.displayReports; else reportContent">
  <div class="all-sections-header my-3">
    <h4 class="mb-3">REPORT ORGANIZATION</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL SECTIONS</h6>
      <div class="d-flex align-items-center">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button add section" id="btn-add-section" (click)="addSection()">Add a section</button>
        <button class="btn-action btn px-4 py-2" type="button" role="button" attr.aria-label="button add report" id="btn-add-report" (click)="addReport()">ADD A REPORT</button>
      </div>
    </div>
  </div>
  <div class="all-sections-content mt-4">
    <div class="table-all-sections table-responsive">
      <table class="table table-hover table-striped">
      <thead>
        <tr>
          <ng-container *ngFor="let header of tableHeader;">
            <th scope="col" class="pointer ps-3">
              {{ header.label}}
            </th>
          </ng-container>
          <th scope="col" class="text-center">Visibility</th>
          <th scope="col" class="text-center"><div class="ms-5">ACTIONS</div></th>
        </tr>
      </thead>
      <tbody class="position-relative" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <div class="loader-container position-absolute pt-5 mt-5" *ngIf="!sections.length">
          <app-loader></app-loader>
        </div>
        <tr *ngFor="let section of sections; index as rowIndex" cdkDrag cdkDragLockAxis="y">
          <th class="text-center col-md" scope="row">
            <div class="drag-handle">
              <ng-container [ngTemplateOutlet]="dragHandleTmpl">
              </ng-container>
              {{section.order}}
            </div>
          </th>
          <td class="text-truncate pointer col-md" (click)="navigateToReports(section)">{{section.name}}</td>
          <td class="col-md">{{section.code | uppercase}}</td>
          <td class="col-md">{{section.createdAt | dateFormat : 'short'}}</td>
          <td class="col-md">{{section.updatedAt | dateFormat : 'short'}}</td>
          <td class="col-md">{{section.visibility }}</td>
          <td class="col-md">
            <div class="actions d-flex justify-content-center ms-5">
              <div class="icon icon-25 icon-edit pointer" (click)="editSection(section.id)" tooltip="Edit" placement="bottom" containerClass="tooltipClass"></div>
              <div class="icon icon-25 mx-2 icon-see-report pointer" (click)="navigateToReports(section)" tooltip="See reports" placement="bottom" containerClass="tooltipClass"></div>
              <div class="icon icon-25  icon-see-right pointer"  tooltip="See rights" placement="bottom" containerClass="tooltipClass"></div>
              <div role="button" class="icon icon-25 icon-delete ms-2 pointer" (click)="deleteSection(section.id)" tooltip="Delete" placement="bottom" containerClass="tooltipClass"></div>
            </div>
          </td>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
</section>
<ng-template #dragHandleTmpl>
	<svg class="bi bi-list handle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
		xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd"
			d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
			clip-rule="evenodd" />
	</svg>
</ng-template>
<ng-template #reportContent>
  <app-section-reports
    [sectionId]="dashboardService.sectionId"
    [sectionName]="dashboardService.sectionName"
    (allSectionClicked)="onAllSectionClicked()"
  ></app-section-reports>
</ng-template>

