import {HttpClient} from '@angular/common/http';
import { Injector } from '@angular/core';
import { TranslationHttpLoader } from './translation-http-loader';

/**
 * Factory function to be used by translation service
 * to load json files
 */
export function I18nHttpLoaderFactory(httpClient: HttpClient, injector: Injector): any {
  return new TranslationHttpLoader(httpClient, injector);
}
