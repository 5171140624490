<section class="news-container d-flex flex-column p-5">
  <div class="news-header my-3">
    <h4 class="mb-3">ALERT & NEWS</h4>
    <div class="d-flex justify-content-between">
      <h6>{{news ? 'EDIT A NEWS' : 'CREATE A NEWS'}}</h6>
    </div>
  </div>
  <div class="news-content mt-4" *ngIf="newsForm">
    <form [formGroup]="newsForm" class="g-3">
      <div class="row mb-3">
        <div class="row mb-4">
          <label class="form-label col-12 mt-3">Status</label>
          <div class="col-12 d-flex flex-row">
            <div style="margin-right: 5rem;">
              <input type="radio" id="published" class="mx-1" [value]="true" formControlName="published">
              <label class="form-label" for="published">Enable</label>
            </div>
            <div>
              <input type="radio" id="published-disable" class="mx-1" [value]="false" formControlName="published">
              <label class="form-label"
                for="published-disable">Disabled</label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="title" class="form-label">Title
            <span class="red-star">*</span>
          </label>
          <input class="form-control" formControlName="title" id="title"
            [ngClass]="{'is-invalid': newsForm.controls.title.invalid && newsForm.controls.title.touched}">
          <div *ngIf="newsForm.controls.title.errors?.required && newsForm.controls.title.touched"
            class="invalid-feedback">
            a news needs a title!
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="message" class="form-label">Message
            <span class="red-star">*</span></label>
          <textarea class="form-control" formControlName="message" id="message" rows="3"
            [ngClass]="{'is-invalid': newsForm.controls.message.invalid && newsForm.controls.message.touched}"></textarea>
          <div *ngIf="newsForm.controls.message.errors?.required && newsForm.controls.message.touched"
            class="invalid-feedback">
            try to give some context to your users, please?
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label class="form-label col-12 mt-3">Category</label>
        <div class="col-12 d-flex flex-row">
          <div style="margin-right: 5rem;">
            <input type="radio" id="category" class="mx-1" [value]="newsTypeEnum.NEWS" formControlName="category">
            <label class="form-label" for="category">{{newsTypeEnum.NEWS}}</label>
          </div>
          <div>
            <input type="radio" id="category-incideent" class="mx-1" [value]="newsTypeEnum.INCIDENT"
              formControlName="category">
            <label class="form-label" for="category-incideent" style="size: 50px;">{{newsTypeEnum.INCIDENT}}</label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label for="startsAt" class="form-label">Start Date
            <span class="red-star">*</span>
          </label>
          <input type="datetime-local" class="form-control" formControlName="startsAt" id="startsAt">
        </div>
        <div class="col-3">
          <label for="endsAt" class="form-label">End date</label>
          <input type="datetime-local" class="form-control" formControlName="endsAt" id="endsAt">
        </div>
      </div>
      <div class="row mb-8">
        <label class="form-label">Pin it</label>
        <div class="form-check">
          <input class="form-check-input ms-1" type="checkbox" formControlName="pinned" id="pinned-checkbox"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="pinned-checkbox">
            Yes! Display the news in the carousel on the home page
          </label>
        </div>
      </div>
      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button submit news"
          id="btn-submit-news" [disabled]="newsForm.invalid" (click)="submitNews()">
          {{news ? 'Edit a News' : 'Create a News'}}
        </button>
      </div>
    </form>
  </div>
</section>
