import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import * as microsoftTeams from '@microsoft/teams-js';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-teams-auth-popup',
  templateUrl: './teams-auth-popup.component.html',
  styleUrls: ['./teams-auth-popup.component.scss'],
})
export class TeamsAuthPopupComponent implements OnInit {
  constructor(private auth: MsalService) {}

  ngOnInit(): void {
    if (microsoftTeams) {
      microsoftTeams.initialize();
      microsoftTeams.getContext((context: any) => {
        if (context) {
          this.doLogin();
        }
      });
    }
  }

  doLogin(): void {
    this.auth
      .acquireTokenSilent({ scopes: [`${environment.clientId}/.default`] })
      .subscribe((result: AuthenticationResult) => {
        if (result.accessToken) {
          // If here we have a Teams context. Ensure we're logged in
          // and then request the access token.
          const response = JSON.stringify({
            username: result?.account?.username,
            accessToken: result?.accessToken,
            expiresOn: result?.expiresOn,
          });
          microsoftTeams.authentication.notifySuccess(response);
          //this.router.navigate(["/favorites"])
          // This call won't return - catch it on the redirect
        } else {
          microsoftTeams.authentication.notifyFailure(
            'Unexpected failure - null token received'
          );
        }
      });
  }
}
