import { AddReportComponent } from './features/add-report/add-report.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdminGuard } from './core/guards/admin-guard.service';
import { AddSectionComponent } from './features/add-section/add-section.component';
import { AllSectionsComponent } from './features/all-sections/all-sections.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { HomeComponent } from './pages/user/home/home.component';
import { ReportDetailsComponent } from './pages/user/report-details/report-details.component';
import { SearchResultsComponent } from './pages/user/search-results/search-results.component';
import { SeeAllResultsComponent } from './pages/user/see-all-results/see-all-results.component';
import { AllTagsComponent } from './features/all-tags/all-tags.component';
import { AddTagComponent } from './features/add-tag/add-tag.component';
import { AllDataSourcesComponent } from './features/all-data-sources/all-data-sources.component';
import { AddDataSourceComponent } from './features/add-data-source/add-data-source.component';
import { AllGroupsComponent } from './features/all-groups/all-groups.component';
import { AddGroupComponent } from './features/add-group/add-group.component';
import { AllUsersComponent } from './features/all-users/all-users.component';
import { AllNewsComponent } from './features/all-news/all-news.component';
import { AddNewsComponent } from './features/add-news/add-news.component';
import { AddAlertComponent } from './features/add-alert/add-alert.component';
import { AddUserComponent } from './features/add-user/add-user.component';
import { AllELearningsComponent } from './features/all-e-learnings/all-e-learnings.component';
import { AddELearningsComponent } from './features/add-e-learnings/add-e-learnings.component';
import { AddTranslationComponent } from './features/add-translation/add-translation.component';
import { AllTranslationsComponent } from './features/all-translations/all-translations.component';
import { AllWorkspaceMappingComponent } from './features/all-workspace-mapping/all-workspace-mapping.component';
import { EditWorkspaceMappingComponent } from './features/edit-workspace-mapping/edit-workspace-mapping.component';
import { PrivacyTeamsComponent } from './pages/privacy-teams/privacy-teams.component';
import { TermsTeamsComponent } from './pages/terms-teams/terms-teams.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { TeamsAuthPopupComponent } from './pages/teams-auth-popup/teams-auth-popup.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'user',
    canActivate: [MsalGuard],
    children: [
      {
        path: 'report/:id',
        component: ReportDetailsComponent,
      },
      {
        path: 'results',
        component: SearchResultsComponent,
      },
      {
        path: 'see-all',
        component: SeeAllResultsComponent,
      },
    ],
  },
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [MsalGuard, AdminGuard],
    data: {
      role: 'ADMIN',
      navigateTo: 'home',
    },
    children: [
      {
        path: 'sections',
        component: AllSectionsComponent,
        outlet: 'admin',
      },
      {
        path: 'section',
        component: AddSectionComponent,
        outlet: 'admin',
      },
      {
        path: 'section/:id',
        component: AddSectionComponent,
        outlet: 'admin',
      },
      {
        path: 'tags',
        component: AllTagsComponent,
        outlet: 'admin',
      },
      {
        path: 'tag',
        component: AddTagComponent,
        outlet: 'admin',
      },
      {
        path: 'tag/:id',
        component: AddTagComponent,
        outlet: 'admin',
      },
      {
        path: 'report',
        component: AddReportComponent,
        outlet: 'admin',
      },
      {
        path: 'report/:id',
        component: AddReportComponent,
        outlet: 'admin',
      },
      {
        path: 'dataSources',
        component: AllDataSourcesComponent,
        outlet: 'admin',
      },
      {
        path: 'dataSource',
        component: AddDataSourceComponent,
        outlet: 'admin',
      },
      {
        path: 'dataSource/:id',
        component: AddDataSourceComponent,
        outlet: 'admin',
      },
      {
        path: 'groups',
        component: AllGroupsComponent,
        outlet: 'admin',
      },
      {
        path: 'group',
        component: AddGroupComponent,
        outlet: 'admin',
      },
      {
        path: 'group/:id',
        component: AddGroupComponent,
        outlet: 'admin',
      },
      {
        path: 'users',
        component: AllUsersComponent,
        outlet: 'admin',
      },
      {
        path: 'user/:id',
        component: AddUserComponent,
        outlet: 'admin',
      },
      {
        path: 'user',
        component: AddUserComponent,
        outlet: 'admin',
      },
      {
        path: 'workspaces',
        component: AllWorkspaceMappingComponent,
        outlet: 'admin',
      },
      {
        path: 'workspace/:id',
        component: EditWorkspaceMappingComponent,
        outlet: 'admin',
      },
      {
        path: 'news',
        component: AllNewsComponent,
        outlet: 'admin',
      },
      {
        path: 'new',
        component: AddNewsComponent,
        outlet: 'admin',
      },
      {
        path: 'new/:id',
        component: AddNewsComponent,
        outlet: 'admin',
      },
      {
        path: 'alert',
        component: AddAlertComponent,
        outlet: 'admin',
      },
      {
        path: 'elearnings',
        component: AllELearningsComponent,
        outlet: 'admin',
      },
      {
        path: 'elearning',
        component: AddELearningsComponent,
        outlet: 'admin',
      },
      {
        path: 'elearning/:id',
        component: AddELearningsComponent,
        outlet: 'admin',
      },
      {
        path: 'translation',
        component: AddTranslationComponent,
        outlet: 'admin',
      },
      {
        path: 'translation/:id',
        component: AddTranslationComponent,
        outlet: 'admin',
      },
      {
        path: 'translations',
        component: AllTranslationsComponent,
        outlet: 'admin',
      },
    ],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'privacy',
    component: PrivacyTeamsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'terms',
    component: TermsTeamsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'forbidden',
    component: ForbiddenPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'teamsauthpopup',
    component: TeamsAuthPopupComponent,
  },
  { path: '**', redirectTo: 'home' },
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
