<section class="report-page">
  <app-report-header
    [report]="report"
    [loadingExport]="loadingExport"
    (favoriteClicked)="toggleFavorite()"
    (printClicked)="onPrintClicked()"
    (exportClicked)="onExportClicked($event)"
  ></app-report-header>
  <div class="report-content d-flex flex-column pt-4 mt-sm-0">
    <div class="report-description d-flex flex-column  mt-5 mt-sm-0">
      <h3 class="font-size-16 trenda-bold">{{"REPORT_DETAILS.CONTENT.DESCRIPTION" | translate }}</h3>
      <p class="font-size-16  helvetica-regular">{{report?.description}}</p>
    </div>
    <div class="row py-5 d-sm-column d-md-flex" [class.iframe-report] = "isTagsClosed">
      <div class=" d-flex flex-column justify-content-center text-white col-sm-12 font-size-16" [ngClass]="isTagsClosed ? 'col-md-12' : 'col-md-9'">
        <ng-container>
        <div  class="iframe-report-actions d-flex justify-content-between align-items-center w-100 py-3 px-4">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center dropdown pe-3 font-size-16" *ngIf="report?.sourceType === sourceTypes.POWER_BI && pages?.length">
              <a class="icon icon-20 icon-tabs me-3 pointer font-size-16" id="tabsDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false" attr.aria-label="must know">
              </a>
              <h6>{{"REPORT_DETAILS.CONTENT.TABS_LABEL" | translate }}</h6>
              <ul class="dropdown-menu font-size-16 tabs-container border-top shadow" attr.aria-labelledby="tabsDropdown">
                <ng-container *ngFor="let page of pages">
                  <li [class.active-li] = "page?.displayName === activePage?.displayName"(click)="onPageClicked(page)">{{page.displayName}}</li>
                </ng-container>
              </ul>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div attr.aria-label="icon fullscreen" id="icon-fullscreen" class="icon icon-20 icon-full-screen me-3 pointer" (click)="openFullscreen()"></div>
            <h6>{{"REPORT_DETAILS.CONTENT.FULL_SCREEN" | translate }}</h6>
          </div>
        </div>
        <div id="report" class="container-iframe" [ngStyle]="{'height': isTagsClosed ? '74rem' : '57rem'}" >
          <ng-container [ngSwitch]="report?.sourceType">
            <ng-container  *ngSwitchCase="sourceTypes.LINK">
              <iframe #fs class="report-responsive-iframe" [src]="reportUrl" frameborder="0" allowfullscreen></iframe>
            </ng-container>
            <ng-container  *ngSwitchCase="sourceTypes.SAP_BO">
              <iframe #fs class="report-responsive-iframe" [src]="reportUrl" frameborder="0" allowfullscreen></iframe>
            </ng-container>
            <ng-container  *ngSwitchCase="sourceTypes.FILE">
            <ng-container *ngIf="isPdf else office">
              <iframe #fs class="report-responsive-iframe" [src]="reportUrl" frameborder="0" allowfullscreen></iframe>
            </ng-container>
            <ng-template #office>
              <ngx-doc-viewer #viewer [url]="report.embedUrl" [viewer]="viewerType" [disableContent]="'none'" style="width:100%;height:100%;"></ngx-doc-viewer>
            </ng-template>
            </ng-container>
            <ng-container  *ngSwitchDefault>
              <div #container class="col-lg-12 col-md-12 h-100"></div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class="position-relative pt-5 mt-5" *ngIf="!reportUrl">
        <app-loader></app-loader>
      </div>
      </div>
      <ng-container *ngIf="isTagsClosed; else tagsContent">
        <div class="d-flex justify-content-end">
          <div  attr.aria-label="icon close sidebar" id="icon-close-sidebar" class="icon icon-40 icon-close-sidebar ms-2 pointer" (click)="toggleTags()"></div>
        </div>
      </ng-container>
      <ng-template #tagsContent>
        <aside class="tags-container col-sm-12 col-md-3 mt-sm-12 mt-md-0 tags-container col-sm-12 col-md-3 mt-sm-12 mt-md-0 pb-4 pt-3 ps-5 pe-3" [ngStyle]="{'max-height': isTagsClosed ? '80.4rem' : '63.4rem'}">
          <div class="header-tag d-flex justify-content-end">
            <div attr.aria-label="icon close" id="icon-close" class="icon icon-15 icon-close pointer" (click)="toggleTags()"></div>
          </div>
          <div class="specific-tags">
            <h5>{{"REPORT_DETAILS.CONTENT.OFFICIAL_TAGS" | translate }}</h5>
            <div class="d-flex flex-wrap mt-3">
              <ng-container *ngFor="let tag of report?.tags; let i = index">
                <span *ngIf="tag?.enabled" [id]="'tag-' + i" class="badge-tag px-3 me-2 mb-2 py-1">
                {{ tag.name }}
              </span>
              </ng-container>
            </div>
          </div>
          <div class="specific-tags mt-4">
            <h5>{{"REPORT_DETAILS.CONTENT.UPSKILLING" | translate }}</h5>
            <div class="d-flex flex-wrap mt-3">
              <ng-container *ngFor="let learning of report?.elearnings; let i = index">
                <span *ngIf="learning?.enabled" [id]="'learning-' + i" class="badge-learning px-3 me-2 mb-2 py-1 pointer" (click)="onLearningClick(learning)">
                {{ learning.name }}
              </span>
              </ng-container>
            </div>
          </div>
          <div class="specific-tags mt-4">
            <h5>{{"REPORT_DETAILS.CONTENT.DOCUMENTS" | translate }}</h5>
            <div class="d-flex flex-wrap mt-3">
              <ng-container *ngFor="let document of reportAttachments; let i = index">
                <span [id]="'document-' + i" class="badge-tag  me-2 mb-2  pointer d-flex align-items-center p-3" (click)="onDocumentClick(document)">
                  <i  class="icon icon-30 icon-file-grey me-2 pointer"></i>
                  <span class="mb-1 text-grey-blue">{{ document?.name }}</span>
              </span>
              </ng-container>
            </div>
          </div>
        </aside>
      </ng-template>
    </div>
  </div>
</section>
