export const sidebarMenuItems = [
  {
    id: 'reportOrganization',
    label: 'Report Organization',
    expanded: true,
    subMenuItems: [
      {
        id: 'allSections',
        label: 'All sections',
        link: 'sections',
      },
      {
        id: 'addSections',
        label: 'Add a section',
        link: 'section',
      },
      {
        id: 'addReport',
        label: 'Add a report or a dashboard',
        link: 'report',
      },

    ]
  },
  {
    id: 'tagManagment',
    label: 'Tag Management',
    expanded: false,
    subMenuItems: [
      {
        id: 'alltags',
        label: 'All Tags',
        link: 'tags',
      },
      {
        id: 'addTags',
        label: 'Add a tag',
        link: 'tag',
      }
    ]
  },

  {
    id: 'userRights',
    label: 'User rights',
    expanded: false,
    subMenuItems: [
      {
        id: 'allGroups',
        label: 'All Groups',
        link: 'groups',
      },
      {
        id: 'addGroups',
        label: 'Create a group',
        link: 'group',
      },
      {
        id: 'allUsers',
        label: 'All Users',
        link: 'users',
      },
      {
        id: 'addUser',
        label: 'Add User',
        link: 'user',
      },
      {
        id: 'allWorkspaceMapping',
        label: 'All Workspace Mapping',
        link: 'workspaces',
      },
    ]
  },
  {
    id: 'alertNews',
    label: 'Alert & News',
    expanded: false,
    subMenuItems: [
      {
        id: 'news',
        label: 'All News',
        link: 'news',
      },
      {
        id: 'new',
        label: 'Create a news',
        link: 'new',
      },
      {
        id: 'alert',
        label: 'Alert',
        link: 'alert',
      },
    ]
  },
  {
    id: 'upskillingOffers',
    label: 'Definitions & Upskilling',
    expanded: false,
    subMenuItems: [
      {
        id: 'allELearnings',
        label: 'All Offers',
        link: 'elearnings',
      },      {
        id: 'addELearnings',
        label: 'Add an Offer',
        link: 'elearning',
      },
    ]
  },
  {
    id: 'dataSources',
    label: 'Data sources',
    expanded: false,
    subMenuItems: [
      {
        id: 'allDataSources',
        label: 'All data sources',
        link: 'dataSources',
      },
      {
        id: 'addDataSource',
        label: 'Add a data source',
        link: 'dataSource',
      }
    ]
  },
  {
    id: 'translation',
    label: 'Translation Management',
    expanded: false,
    subMenuItems: [
      {
        id: 'allTranslation',
        label: 'All Translations',
        link: 'translations',
      },
      {
        id: 'addTranslation',
        label: 'Add Translation',
        link: 'translation',
      }
    ]
  }
];
