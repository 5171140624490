import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { concatMap } from 'rxjs/operators';
import { headerELearning } from 'src/app/core/constants/admin';
import { IELearning } from 'src/app/core/interfaces/e-learning.interface';
import { ITableHeader } from 'src/app/core/interfaces/section.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-all-e-learnings',
  templateUrl: './all-e-learnings.component.html',
  styleUrls: ['./all-e-learnings.component.scss']
})
export class AllELearningsComponent implements OnInit {
  readonly DEFAULT_SORT = 'name';
  readonly limit = 10;
  tableHeader: ITableHeader[] = headerELearning;
  eLearnings: IELearning[] = [];
  currentPage = 1;
  total = 0;
  skip = 0;
  end = 0;
  sort = this.DEFAULT_SORT;
  is_desc = false;
  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllELearnings();
  }

  getAllELearnings(): void {
    this.dashboardService
      .getELearnings(this.skip, this.limit, this.sort, this.is_desc)
      .subscribe((data) => {
        this.total = data.total;
        this.eLearnings = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getAllELearnings();
  }

  addELearning(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['elearning'] } }]);
  }

  editELearning(eLearningId: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['elearning', eLearningId] } }]);
  }

  deleteELearning(eLearningId: number): void {
    if (confirm("Are you sure to delete")) {
      this.dashboardService.deleteELearning(eLearningId).pipe(
        concatMap(() => {
          this.snackbarService.show('Delete successfully', 'success');
          return this.dashboardService.getELearnings(this.skip, this.limit, this.sort, this.is_desc);
        })
      ).subscribe((data) => {
        this.total = data.total;
        this.eLearnings = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  sortTable(header: ITableHeader): void {
    this.tableHeader.forEach((item) => {
      if (item.id === header.id) {
        item.direction = !item.direction;
        this.sort = header.name;
        this.is_desc = header.direction;
      } else {
        item.direction = false;
      }
    });
    this.getAllELearnings();
  }

}
