import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlatformItem } from 'src/app/core/interfaces/platform.interface';
import { reportSourceTypeEnum } from 'src/app/core/interfaces/report.interface';
import { ReportService } from '../add-report/report.service';
@Component({
  selector: 'app-add-report-step-one',
  templateUrl: './add-report-step-one.component.html',
  styleUrls: ['./add-report-step-one.component.scss']
})
export class AddReportStepOneComponent implements OnInit, OnDestroy {
  readonly sourceTypesLabels: { [key: string]: string; } = { 'power-bi': 'POWER BI', 'sap-bo': 'SAP BO', 'link': 'LINK', 'file': 'FILE' };
  reportSourceTypes: string[] = [reportSourceTypeEnum.POWER_BI, reportSourceTypeEnum.SAP_BO, reportSourceTypeEnum.FILE, reportSourceTypeEnum.LINK];
  sourceType!: reportSourceTypeEnum;
  platformItem: PlatformItem[] = [];
  childrenSelected: PlatformItem[] = [];
  folderPathItems: PlatformItem[] = [];
  step!: number;
  resultOfFolderPathItems!: Subscription;
  resultOfSourceType!: Subscription;
  resultOfPlatformItem!: Subscription;
  resultOfChildrenSelected!: Subscription;
  resultOfStep!: Subscription;
  constructor(
    public reportService: ReportService
  ) {
  }
  ngOnInit(): void {
    this.resultOfFolderPathItems = this.reportService.sharedFolderPathItems.subscribe(data => {
      this.folderPathItems = data;
    })
    this.resultOfSourceType = this.reportService.sharedSourceType.subscribe(data => {
      this.sourceType = data;
    })
    this.resultOfPlatformItem = this.reportService.sharedPlatformItem.subscribe(data => {
      this.platformItem = data;
    })
    this.resultOfChildrenSelected = this.reportService.sharedChildrenSelected.subscribe(data => {
      this.childrenSelected = data
    })
    this.resultOfStep = this.reportService.sharedStep.subscribe(data => {
      this.step = data;
    })

  }

  toNextStep(): void {
    this.reportService.nextStep(2);
  }

  ngOnDestroy(): void {
    this.resultOfFolderPathItems.unsubscribe();
    this.resultOfSourceType.unsubscribe();
    this.resultOfPlatformItem.unsubscribe();
    this.resultOfChildrenSelected.unsubscribe();
    this.resultOfStep.unsubscribe();
  }
}
