import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { EMPTY, Observable} from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { IUser } from "../interfaces/user.interface";
import { AuthService } from "../services/auth.service";

export class TranslationHttpLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient, private injector: Injector){};

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    let currentUser = {} as IUser;
    const auth = this.injector.get(AuthService);
    return auth.currentUser.pipe(
      concatMap((data) => {
        if(data){
          currentUser = data;
          if (!currentUser.languages?.length) {
            lang == "en";
          } else{
            if(!lang){
              const en = currentUser.languages?.find(l => l.code?.toLowerCase()?.includes('en'))?.code;
              lang = en ? en : currentUser.languages[0]?.code;
            }
          }
          const urls = currentUser.languages?.reduce((acc: {[key:string]: string}, cur) => {
            acc[cur.code] = cur.translationsFileUrl || ''
            return acc
          }, {})

          let offlineUrls : {[key:string]: string}= {
            en: "./assets/i18n/en.json",
            fr: "./assets/i18n/fr.json"
          };

          let observer: Observable<Object> = new Observable(observer => {
            const url = urls ? urls[lang] : offlineUrls[lang];
            this.httpClient.get(url).pipe(
              catchError(() => {
                return this.httpClient.get(offlineUrls[lang], {responseType: 'json'})
              })
            ).subscribe(
              data => {
                observer.next(data);
                observer.complete();
              },
              error => {
                console.log(error);
              }
            );
          });
          return observer;
        }

        return EMPTY;
      })
    )
  }
}
