<section class="report-container d-flex flex-column p-5">
  <div class="report-header my-3">
    <h4 class="mb-3">REPORT ORGANIZATION</h4>
    <div class="d-flex justify-content-between">
      <h6>{{ report ? "EDIT A REPORT" : "ADD A REPORT OR A DASHBOARD" }}</h6>
    </div>
  </div>
  <ng-container *ngIf="step === 1">
    <form class="row">
      <div class="col-md-6">
        <label for="validationServer01" class="form-label">Source Type</label>
        <ng-select class="custom input-uppercase" (change)="getSourceTypeOnChange($event)" [items]="reportSourceTypes"
          ngDefaultControl [closeOnSelect]="true" [multiple]="false" [clearable]="false"
          placeholder="Select source type">
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <div class="ng-label-tmp d-flex align-items-center px-2 br-4" (mousedown)="$event.stopPropagation()">
              <span> {{sourceTypesLabels[item]}}</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
            <span class="ml-2"> {{sourceTypesLabels[item]}} </span>
          </ng-template>
        </ng-select>
      </div>
    </form>
  </ng-container>
  <div class="report-content mt-4">
    <div class="row mb-3">
      <app-add-report-step-one *ngIf="step === 1"> </app-add-report-step-one>
      <app-add-report-step-two *ngIf="step === 2"> </app-add-report-step-two>
    </div>
  </div>
</section>
