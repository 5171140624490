
<ng-container>
  <div class="admin-container d-flex position-relative">
    <div id="sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="main-container">
      <router-outlet name='admin'></router-outlet>
    </div>
  </div>
</ng-container>
