<div class="back-button d-flex align-items-center pointer mb-4" *ngIf="step === 2" (click)="back()">
  <div attr.aria-label="icon back" id="icon-back" class="icon icon-12 icon-back me-2"></div>
  <label class="font-size-13 trenda-regular pointer">
    Return back
  </label>
</div>
<div class="report-container">

  <ng-container *ngIf="!editForm && ['POWER_BI', 'SAP_BO', 'power-bi', 'sap-bo'].includes(sourceType)">
    <div class="accordion report-container" id="accordionContainer" *ngIf="reportsArray.controls.length !==0">
      <form [formGroup]="reportForm" class="report-content g-3">
        <ng-container formArrayName="reports">
          <div class="accordion-item mb-4" *ngFor="let reportItemControl of reportsArray.controls; let i = index">
            <h2 class="accordion-header" [id]="'heading' + i">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse' + i" data-expanded="false" [attr.aria-controls]="'collapse' + i">
                {{ reportItemControl.value.name }}
              </button>
            </h2>
            <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i"
              data-bs-parent="#accordionContainer">
              <div class="accordion-body" style="background-color: #F3F4F6;">
                <input *ngIf="editForm" type="text" class="form-control" disabled [value]="report?.name"
                  id="displayName">
                <div class="row">
                  <label class="main-section-label text-uppercase my-4">Main Informations</label>
                </div>
                <div class="row" [formGroupName]="i">
                  <div class="col-6" *ngIf="!['link','file'].includes(sourceType)">
                    <label for="sourceName" class="form-label">Technical name<span class="red-star">*</span></label>
                    <input type="text" class="form-control" formControlName="sourceName" id="sourceName">
                  </div>
                  <div [ngClass]="!['link','file'].includes(sourceType) ?'col-6':'col-12'">
                    <label for="name" class="form-label">Display name<span class="red-star">*</span></label>
                    <input type="text" class="form-control" formControlName="name" id="name"
                      [ngClass]="{'is-invalid': reportsArray.controls[i].get('name')?.errors?.required && reportsArray.controls[i]?.get('name')?.touched}">
                    <div
                      *ngIf="reportsArray.controls[i].get('name')?.errors?.required && reportsArray.controls[i]?.get('name')?.touched"
                      class="invalid-feedback">
                      a report must have a name!
                    </div>
                  </div>
                  <div class="col-12 mt-3" *ngIf="sourceType !== 'file'">
                    <label for="url" class="form-label">URL<span class="red-star">*</span></label>
                    <input type="text" class="form-control" formControlName="url" id="url">
                  </div>
                  <div class="col-6 mt-3" *ngIf="sourceType === 'file'">
                    <label for="file" class="form-label">Upload Document<span class="red-star">*</span></label>
                    <ng-container *ngIf="editForm && !displayFileInput">
                      <div class="d-flex align-items-center">
                        <button type="button" class="btn me-4 btn-secondary btn-lg" disabled>{{fileName}}</button>
                        <div class="icon icon-13 icon-pencil-blue pointer" (click)="onDisplayFileInput()"></div>
                      </div>
                    </ng-container>
                    <input class="form-control" [ngClass]="displayFileInput ? 'd-block' : 'd-none'"
                      (change)="saveFile($event)" type="file" formControlName="mainFileId" id="file">
                  </div>
                  <label class="form-label col-12 mt-3">Choose a type</label>
                  <div class="col-12 d-flex flex-row">
                    <div style="margin-right: 5rem;">
                      <input type="radio" class="mx-1" [value]="reportTypeEnum.REPORT" formControlName="type">
                      <label>Report</label>
                    </div>
                    <div>
                      <input type="radio" class="mx-1" [value]="reportTypeEnum.DASHBOARD" formControlName="type">
                      <label for="dashboard-type">Dashboard</label>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <label for="description" class="form-label">Description</label>
                    <textarea rows="4" class="form-control" formControlName="description" id="description"></textarea>
                  </div>
                  <div class="row mt-3">
                    <div class="col-6">
                      <label for="datasources" class="form-label">Data Sources</label>
                      <ng-select class="custom input-uppercase" [items]="dataSources" bindLabel="name" bindValue="id"
                        formControlName="datasources" id="datasources" ngDefaultControl [closeOnSelect]="false"
                        [multiple]="true" placeholder="Select one or several datasources">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                            (mousedown)="$event.stopPropagation()">
                            <span>{{item.name}}</span>
                            <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                          let-search="searchTerm">
                          <span class="ml-2"> {{item.name}} </span>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="col-6">
                      <label for="refreshDate" class="form-label">Data Refresh</label>
                      <input type="text" class="form-control" formControlName="refreshDate" id="refreshDate">
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <label for="image" class="form-label">Upload an image / thumbnail
                      {{report?.previewImage?.name}}</label>
                    <ng-container *ngIf="editForm && !displayImageInput">
                      <div class="d-flex align-items-center">
                        <button type="button" class="btn me-4 btn-secondary btn-lg"
                          disabled>{{report?.previewImage?.name}}</button>
                        <div class="icon icon-13 icon-pencil-blue pointer" (click)="onDisplayImageInput()"></div>
                      </div>
                    </ng-container>
                    <input class="form-control" [ngClass]="displayImageInput ? 'd-block' : 'd-none'"
                      (change)="saveImage($event,i)" type="file" accept="image/*" formControlName="image" id="file">
                  </div>
                  <div class="row mt-3">
                    <div class="col-6 mb-3">
                      <label class="form-label">Visibility</label>
                      <div class="d-flex justify-content-between">
                        <div>
                          <input type="radio" class="mx-1" id="visibility-available" [value]="visibilityEnum.AVAILABLE"
                            formControlName="visibility">
                          <label class="form-label">{{visibilityEnum.AVAILABLE}}</label>
                        </div>
                        <div>
                          <input type="radio" class="mx-1" id="visibility-unavailable"
                            [value]="visibilityEnum.UNAVAILABLE" formControlName="visibility">
                          <label class="form-label">{{visibilityEnum.UNAVAILABLE}}</label>
                        </div>
                        <div>
                          <input type="radio" class="mx-1" id="visibility-private" [value]="visibilityEnum.PRIVATE"
                            formControlName="visibility">
                          <label class="form-label">{{visibilityEnum.PRIVATE}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 mb-3">
                      <label class="form-label">Pin it</label>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="pinned" id="pinned"
                          aria-describedby="invalidCheck3Feedback">
                        <label class="form-check-label ms-2">
                          Yes, display the report/dashboard on the homepage
                        </label>
                      </div>
                    </div>
                  </div>
                  <label class="main-section-label my-4 text-uppercase">Sections</label>
                  <div class="row">
                    <div class="col-6">
                      <label for="sectionId" class="form-label">Main section <span class="red-star">*</span></label>
                      <ng-select class="custom input-uppercase" [items]="sections" bindLabel="name" bindValue="id"
                        formControlName="sectionId" id="sectionId" ngDefaultControl [closeOnSelect]="true"
                        [multiple]="false" placeholder="Select a section">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                            (mousedown)="$event.stopPropagation()">
                            <span>{{item.name}}</span>
                            <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                          let-search="searchTerm">
                          <span class="ml-2">{{item.name}} </span>
                        </ng-template>
                      </ng-select>
                      <div
                        *ngIf="reportsArray.controls[i].get('sectionId')?.errors?.required && reportsArray.controls[i].get('sectionId')?.touched"
                        class="invalid-feedback">
                        a report must have a main section!
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-6">
                      <label class="form-label col-12 mt-3">Generate a code automatically ?</label>
                      <div class="col-12 d-flex flex-row">
                        <div style="margin-right: 5rem;">
                          <input type="radio" id="code-yes" (click)="onDisableFormProperty('code',i)" class="mx-1"
                            [value]="true" formControlName="codeGenerated">
                          <label for="code-yes">Yes</label>
                        </div>
                        <div>
                          <input type="radio" id="code-no" (click)="onEnableFormProperty('code',i)" class="mx-1"
                            [value]="false" formControlName="codeGenerated">
                          <label for="code-no">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <label for="code" class="form-label">Code Report<span class="red-star">*</span> </label>
                      <input type="text" class="form-control" formControlName="code" minlength="3" id="code"
                        [ngClass]="{'is-invalid': reportsArray.controls[i].get('code')?.invalid && reportsArray.controls[i].get('code')?.touched}">
                      <div
                        *ngIf="reportsArray.controls[i].get('code')?.errors?.required && reportsArray.controls[i].get('code')?.touched"
                        class="invalid-feedback">
                        a report must have a code!
                      </div>
                      <div *ngIf="reportsArray.controls[i].get('code')?.errors?.minlength" class="invalid-feedback">
                        a report must be at least 3 characters long.
                      </div>
                    </div>
                  </div>
                  <label class="main-section-label mt-5 mb-3 text-uppercase">METADATAS</label>
                  <div class="row">
                    <div class="col-6">
                      <label for="tags" class="form-label">Tags</label>
                      <ng-select class="custom input-uppercase" [items]="tags" bindLabel="name" bindValue="id"
                        formControlName="tags" id="tags" ngDefaultControl [closeOnSelect]="false" [multiple]="true"
                        placeholder="Select a one or several tags">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                            (mousedown)="$event.stopPropagation()">
                            <span>{{item.name}}</span>
                            <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                          let-search="searchTerm">
                          <span class="ml-2">{{item.name}} </span>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="col-6">
                      <label for="offers" class="form-label">Upskilling offers</label>
                      <ng-select class="custom input-uppercase" [items]="elearnings" bindLabel="name" bindValue="id"
                        formControlName="elearnings" id="offers" ngDefaultControl [closeOnSelect]="false"
                        [multiple]="true" placeholder="Select a one or several offers">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                            (mousedown)="$event.stopPropagation()">
                            <span>{{item.name}}</span>
                            <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                          let-search="searchTerm">
                          <span class="ml-2">{{item.name}} </span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class="d-flex align-items-center">
                      <label for="documents" class="form-label">Links documents</label>
                      <div *ngIf="startUploadingAttachments"
                        class="spinner-border spinner-border-sm text-secondary ms-2 mb-2" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ng-container *ngIf="editForm">
                      <div class="d-flex flex-column mb-2">
                        <ng-container *ngFor="let item of reportAttachments; let i = index;">
                          <div class="d-flex align-items-center mb-2">
                            <button type="button" class="btn me-4 btn-secondary btn-lg" disabled>{{item?.name}}</button>
                            <div class="icon icon-13 icon-cross-red pointer" (click)="deleteAttachment(i)"></div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <input class="form-control" type="file" formControlName="documents" id="documents" multiple
                      (change)="saveAttachments($event,i)">
                  </div>
                  <label class="main-section-label mt-5 mb-3 text-uppercase">RIGHTS MANAGEMENT</label>
                  <div class="col-6">
                    <label for="groups" class="form-label">Authorized groups</label>
                    <ng-select class="custom input-uppercase" [items]="groups" bindLabel="name" bindValue="id"
                      formControlName="groups" id="groups" ngDefaultControl [closeOnSelect]="false" [multiple]="true"
                      placeholder="Select a one or several groups">
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                          (mousedown)="$event.stopPropagation()">
                          <span>{{item.name}}</span>
                          <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                        let-search="searchTerm">
                        <span class="ml-2">{{item.name}} </span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5 d-flex justify-content-start">
            <button class="btn-action btn px-4 py-2 me-3" [disabled]="reportForm?.invalid" type="button" role="button"
              attr.aria-label="button save report" id="btn-save-report" (click)="saveReport()">Save</button>
          </div>
        </ng-container>
      </form>
    </div>
  </ng-container>

  <ng-container *ngIf="editForm || ['file', 'link'].includes(sourceType) ">
    <form [formGroup]="reportForm" class=" report-content g-3">
      <ng-container formArrayName="reports">
        <ng-container *ngFor="let reportItemControl of reportsArray.controls; let i = index">
          <input *ngIf="editForm" type="text" class="form-control" disabled [value]="report?.name" id="displayName">
          <div class="row">
            <label class="main-section-label text-uppercase my-4">Main Informations</label>
          </div>
          <div class="row" [formGroupName]="i">
            <div class="col-6" *ngIf="!['link','file'].includes(sourceType)">
              <label for="sourceName" class="form-label">Technical name<span class="red-star">*</span></label>
              <input type="text" class="form-control" formControlName="sourceName" id="sourceName">
            </div>
            <div [ngClass]="!['link','file'].includes(sourceType) ?'col-6':'col-12'">
              <label for="name" class="form-label">Display name<span class="red-star">*</span></label>
              <input type="text" class="form-control" formControlName="name" id="name"
                [ngClass]="{'is-invalid': reportsArray.controls[i].get('name')?.errors?.required && reportsArray.controls[i]?.get('name')?.touched}">
              <div
                *ngIf="reportsArray.controls[i].get('name')?.errors?.required && reportsArray.controls[i]?.get('name')?.touched"
                class="invalid-feedback">
                a report must have a name!
              </div>
            </div>
            <div class="col-12 mt-3" *ngIf="sourceType !== 'file'">
              <label for="url" class="form-label">URL<span class="red-star">*</span></label>
              <input type="text" class="form-control" formControlName="url" id="url">

              <!-- commutateur ici (Aziz) -->
              <!-- <input type="checkbox" id="toggleUrl" [(ngModel)]="urlEnabled" (change)="toggleUrl()"
                [checked]="urlEnabled" />
              <label for="toggleUrl">Enable URL</label> -->
            </div>
            <div class="col-6 mt-3" *ngIf="sourceType === 'file'">
              <label for="file" class="form-label">Upload Document<span class="red-star">*</span></label>
              <ng-container *ngIf="editForm && !displayFileInput">
                <div class="d-flex align-items-center">
                  <button type="button" class="btn me-4 btn-secondary btn-lg" disabled>{{fileName}}</button>
                  <div class="icon icon-13 icon-pencil-blue pointer" (click)="onDisplayFileInput()"></div>
                </div>
              </ng-container>
              <input class="form-control" [ngClass]="displayFileInput ? 'd-block' : 'd-none'"
                (change)="saveFile($event)" type="file" formControlName="mainFileId" id="file">
            </div>
            <label class="form-label col-12 mt-3">Choose a type</label>
            <div class="col-12 d-flex flex-row">
              <div style="margin-right: 5rem;">
                <input type="radio" class="mx-1" [value]="reportTypeEnum.REPORT" formControlName="type">
                <label>Report</label>
              </div>
              <div>
                <input type="radio" class="mx-1" [value]="reportTypeEnum.DASHBOARD" formControlName="type">
                <label>Dashboard</label>
              </div>
            </div>
            <div class="col-12 mt-3">
              <label for="description" class="form-label">Description</label>
              <textarea rows="4" class="form-control" formControlName="description" id="description"></textarea>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <label for="datasources" class="form-label">Data Sources</label>
                <ng-select class="custom input-uppercase" [items]="dataSources" bindLabel="name" bindValue="id"
                  formControlName="datasources" id="datasources" ngDefaultControl [closeOnSelect]="false"
                  [multiple]="true" placeholder="Select one or several datasources">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                      (mousedown)="$event.stopPropagation()">
                      <span>{{item.name}}</span>
                      <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                    let-search="searchTerm">
                    <span class="ml-2"> {{item.name}} </span>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-6">
                <label for="refreshDate" class="form-label">Data Refresh</label>
                <input type="text" class="form-control" formControlName="refreshDate" id="refreshDate">
              </div>
            </div>
            <div class="col-6 mt-3">
              <label for="image" class="form-label">Upload an image / thumbnail</label>
              <ng-container *ngIf="editForm && !displayImageInput">
                <div class="d-flex align-items-center">
                  <button type="button" class="btn me-4 btn-secondary btn-lg"
                    disabled>{{report?.previewImage?.name}}</button>
                  <div class="icon icon-13 icon-pencil-blue pointer" (click)="onDisplayImageInput()"></div>
                </div>
              </ng-container>
              <input class="form-control" [ngClass]="displayImageInput ? 'd-block' : 'd-none'"
                (change)="saveImage($event,i)" type="file" accept="image/*" formControlName="image" id="file">
            </div>
            <div class="row mt-3">
              <div class="col-6 mb-3">
                <label class="form-label">Visibility</label>
                <div class="d-flex justify-content-between">
                  <div>
                    <input type="radio" class="mx-1" id="visibility-available" [value]="visibilityEnum.AVAILABLE"
                      formControlName="visibility">
                    <label class="form-label">{{visibilityEnum.AVAILABLE}}</label>
                  </div>
                  <div>
                    <input type="radio" class="mx-1" id="visibility-unavailable" [value]="visibilityEnum.UNAVAILABLE"
                      formControlName="visibility">
                    <label class="form-label">{{visibilityEnum.UNAVAILABLE}}</label>
                  </div>
                  <div>
                    <input type="radio" class="mx-1" id="visibility-private" [value]="visibilityEnum.PRIVATE"
                      formControlName="visibility">
                    <label class="form-label">{{visibilityEnum.PRIVATE}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 mb-3">
                <label class="form-label">Pin it</label>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="pinned" id="pinned"
                    aria-describedby="invalidCheck3Feedback">
                  <label class="form-check-label ms-2">
                    Yes, display the report/dashboard on the homepage
                  </label>
                </div>
              </div>
            </div>
            <label class="main-section-label my-4 text-uppercase">Sections</label>
            <div class="row">
              <div class="col-6">
                <label for="sectionId" class="form-label">Main section <span class="red-star">*</span></label>
                <ng-select class="custom input-uppercase" [items]="sections" bindLabel="name" bindValue="id"
                  formControlName="sectionId" id="sectionId" ngDefaultControl [closeOnSelect]="true" [multiple]="false"
                  placeholder="Select a section">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                      (mousedown)="$event.stopPropagation()">
                      <span>{{item.name}}</span>
                      <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                    let-search="searchTerm">
                    <span class="ml-2">{{item.name}} </span>
                  </ng-template>
                </ng-select>
                <div
                  *ngIf="reportsArray.controls[i].get('sectionId')?.errors?.required && reportsArray.controls[i].get('sectionId')?.touched"
                  class="invalid-feedback">
                  a report must have a main section!
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <label class="form-label col-12 mt-3">Generate a code automatically ?</label>
                <div class="col-12 d-flex flex-row">
                  <div style="margin-right: 5rem;">
                    <input type="radio" id="code-yes" (click)="onDisableFormProperty('code', i)" class="mx-1"
                      [value]="true" formControlName="codeGenerated">
                    <label for="code-yes">Yes</label>
                  </div>
                  <div>
                    <input type="radio" id="code-no" (click)="onEnableFormProperty('code', i)" class="mx-1"
                      [value]="false" formControlName="codeGenerated">
                    <label for="code-no">No</label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <label for="code" class="form-label">Code Report<span class="red-star">*</span> </label>
                <input type="text" class="form-control" formControlName="code" minlength="3" id="code"
                  [ngClass]="{'is-invalid': reportsArray.controls[i].get('code')?.invalid && reportsArray.controls[i].get('code')?.touched}">
                <div
                  *ngIf="reportsArray.controls[i].get('code')?.errors?.required && reportsArray.controls[i].get('code')?.touched"
                  class="invalid-feedback">
                  a report must have a code!
                </div>
                <div *ngIf="reportsArray.controls[i].get('code')?.errors?.minlength" class="invalid-feedback">
                  a report must be at least 3 characters long.
                </div>
              </div>
            </div>
            <label class="main-section-label mt-5 mb-3 text-uppercase">METADATAS</label>
            <div class="row">
              <div class="col-6">
                <label for="tags" class="form-label">Tags</label>
                <ng-select class="custom input-uppercase" [items]="tags" bindLabel="name" bindValue="id"
                  formControlName="tags" id="tags" ngDefaultControl [closeOnSelect]="false" [multiple]="true"
                  placeholder="Select a one or several tags">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                      (mousedown)="$event.stopPropagation()">
                      <span>{{item.name}}</span>
                      <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                    let-search="searchTerm">
                    <span class="ml-2">{{item.name}} </span>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-6">
                <label for="offers" class="form-label">Upskilling offers</label>
                <ng-select class="custom input-uppercase" [items]="elearnings" bindLabel="name" bindValue="id"
                  formControlName="elearnings" id="offers" ngDefaultControl [closeOnSelect]="false" [multiple]="true"
                  placeholder="Select a one or several offers">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
                      (mousedown)="$event.stopPropagation()">
                      <span>{{item.name}}</span>
                      <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                    let-search="searchTerm">
                    <span class="ml-2">{{item.name}} </span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="col-6 mt-3">
              <div class="d-flex align-items-center">
                <label for="documents" class="form-label">Links documents</label>
                <div *ngIf="startUploadingAttachments" class="spinner-border spinner-border-sm text-secondary ms-2 mb-2"
                  role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <ng-container *ngIf="editForm">
                <div class="d-flex flex-column mb-2">
                  <ng-container *ngFor="let item of reportAttachments; let i = index;">
                    <div class="d-flex align-items-center mb-2">
                      <button type="button" class="btn me-4 btn-secondary btn-lg" disabled>{{item?.name}}</button>
                      <div class="icon icon-13 icon-cross-red pointer" (click)="deleteAttachment(i)"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <input class="form-control" type="file" formControlName="documents" id="documents" multiple
                (change)="saveAttachments($event,i)">
            </div>
            <label class="main-section-label mt-5 mb-3 text-uppercase">RIGHTS MANAGEMENT</label>
            <div class="col-6">
              <label for="groups" class="form-label">Authorized groups</label>
              <ng-select class="custom input-uppercase" [items]="groups" bindLabel="name" bindValue="id"
                formControlName="groups" id="groups" ngDefaultControl [closeOnSelect]="false" [multiple]="true"
                placeholder="Select a one or several groups">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <div class="ng-label-tmp d-flex align-items-center px-2 br-4" (mousedown)="$event.stopPropagation()">
                    <span>{{item.name}}</span>
                    <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
                  <span class="ml-2">{{item.name}} </span>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-12 mt-5 d-flex justify-content-start">
            <button class="btn-action btn px-4 py-2 me-3" [disabled]="reportForm?.invalid" type="button" role="button"
              attr.aria-label="button save report" id="btn-save-report" (click)="saveReport()">Save</button>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </ng-container>
</div>