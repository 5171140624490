import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-teams',
  templateUrl: './privacy-teams.component.html',
  styleUrls: ['./privacy-teams.component.scss']
})
export class PrivacyTeamsComponent  {


}
