import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NewsCategoriesEnum } from 'src/app/core/enum/news.enum';
import { SearchFacetTypesEnum } from 'src/app/core/enum/search.enum';
import { VisibilityEnum } from 'src/app/core/enum/visibility.enum';
import { IDocument } from 'src/app/core/interfaces/section.interface';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SearchService } from 'src/app/core/services/search.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  readonly newsTypeEnum = NewsCategoriesEnum;
  readonly visibilityEnum = VisibilityEnum;

  connectWithEmail = localStorage. getItem('connectWithEmail');
  isExpanded = false;
  displayResults = false;
  filters = [{name: 'HEADER.FILTERS.ALL', type: SearchFacetTypesEnum.All, selected: true}, {name: 'HEADER.FILTERS.DASHBOARDS', type: SearchFacetTypesEnum.DASHBOARD, selected: false}, {name: 'HEADER.FILTERS.REPORTS', type: SearchFacetTypesEnum.REPORT, selected: false}];
  hits: IDocument[] = [];
  searchTerm = '';
  selectedFilters: SearchFacetTypesEnum[] = [SearchFacetTypesEnum.DASHBOARD, SearchFacetTypesEnum.REPORT];
  avatarText: string = '';
  hitsFounded = 0;
  currentLanguage!: string | undefined;
  languages!: string[];
  constructor(
    public authService: AuthService,
    public analyticsService: AnalyticsService,
    public themeService: ThemeService,
    public searchService: SearchService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.setSearchServiceNews();
    this.languages = this.authService.getCurrentUser()?.languages.length > 0 ? this.authService.getCurrentUser()?.languages?.map(l => l?.code) : ['en'];
    this.currentLanguage = this.languages?.find(l => l?.toLowerCase()?.includes('en')) ? this.languages?.find(l => l?.toLowerCase()?.includes('en')) : this.languages[0] ;
  }

  @Output() logoutClicked = new EventEmitter<void>();

  collapse(): void {
    this.isExpanded = false;
  }

  displayFilters(): void {
    this.isExpanded = true;
  }

  logout(): void {
    this.logoutClicked.emit();
  }

  switchLang(language: string): void {
    this.currentLanguage = language;
    this.authService.setLanguageTimeZone(this.currentLanguage);
  }

  switchThemeMode(): void {
    this.themeService.toggleDarkMode();
  }

  addFilter(filter: {name: string, type: SearchFacetTypesEnum, selected: boolean }, i: number): void {
    if(filter.type === SearchFacetTypesEnum.All){
      this.filters = this.filters.map(f => ({...f, selected: false }));
    }
    if(this.filters?.some(f => ((f.type === SearchFacetTypesEnum.All) && f.selected))){
      this.filters = this.filters.map(f => ({...f, selected: false }));
    }
    this.filters[i].selected = !this.filters[i]?.selected;

    const facetTypes = this.checkFilters();
    if(this.searchTerm?.length > 2){
      this.searchService.searchQuery(this.searchTerm, facetTypes, this.authService.getCurrentUser()?.typesenseKey).subscribe((data) => {
        this.hitsFounded = data?.found;
        this.hits = data?.hits.sort(({ pinned: a = false }, { pinned: b = false }) =>
          Number(b) - Number(a)).slice(0, 14);
        this.displayResults = true;
      })
  }
  }

  onOptionSelected(option: IDocument): void {
    this.searchTerm = option?.name;
    if(option?.source_type_facet === 'LINK'){
      window.open(option?.url || '', "_blank");
    } else {
      this.router.navigate(['/user/report/', option?.id]);
    }
    this.displayResults = false;
    this.isExpanded = false
  }

  onSubmit(): void{
    const facetTypes = this.checkFilters();
    this.analyticsService.logSearch(this.searchTerm).subscribe();
    this.searchService.searchQueryByFacet(this.searchTerm, facetTypes, this.authService.getCurrentUser()).subscribe((data) => {
      this.searchService.facetResults = data;
      this.router.navigate(['/user/results']);
    })
    this.displayResults = false;
    this.isExpanded = false
  }

  onChangeSearchTerm(): void{
    const facetTypes = this.checkFilters();
    if(this.searchTerm?.length > 2){
        this.searchService.searchQuery(this.searchTerm, facetTypes, this.authService.getCurrentUser()?.typesenseKey).subscribe((data) => {
          this.hitsFounded = data?.found;
          this.hits = data?.hits.sort(({ pinned: a = false }, { pinned: b = false }) =>
            Number(b) - Number(a)).slice(0, 14);
        });
      this.displayResults = true;
    } else {
      this.displayResults = false;
    }
  }

  checkFilters(): SearchFacetTypesEnum[]{
    const allFilters = [SearchFacetTypesEnum.DASHBOARD, SearchFacetTypesEnum.REPORT];
    if(this.filters.some(f => ((f.type === SearchFacetTypesEnum.All) && f.selected))){
      return this.selectedFilters;
    }
    if(this.filters.some(f => f.selected)){
      return this.filters.filter(s => s.selected).map(filter => filter.type);
    }
    return  allFilters;
  }

  closeSearchResults(): void{
    this.displayResults = false;
  }

  navigateAdmin(): void{
    this.router.navigate(['/admin',{ outlets: { admin: ['sections'] }}]);
  }

  setAvatarText(): string{
    return this.authService?.getCurrentUser()?.firstName?.charAt(0) + this.authService?.getCurrentUser()?.lastName?.charAt(0) || '--';
  }

  setSearchServiceNews(): void {
    this.searchService.getNews().subscribe(
      (news) => this.searchService.setNews(news)
    );
  }

  goToCarolStatistics(): void{
    const user = this.authService.getCurrentUser();
    const url = user?.sapBoToken ? `${environment.carolStatisticsURL}?ivsLogonToken=${user.sapBoToken}`: environment.carolStatisticsURL;
    window.open(url, "_blank");
  }

  async disconnectAs(): Promise<void> {
    localStorage.removeItem('connectWithEmail');
    this.authService.updateLoggedInStatus().then(() => {
      this.connectWithEmail = localStorage.getItem('connectWithEmail');
      this.router.navigate(['/admin', { outlets: { admin: ['users'] } }]);
    });
  }
}
