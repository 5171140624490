import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { UploadService } from 'src/app/core/services/upload.service';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-translation',
  templateUrl: './add-translation.component.html',
  styleUrls: ['./add-translation.component.scss']
})
export class AddTranslationComponent implements OnInit {

  translationForm!: FormGroup;
  displayFileInput = true;
  translationsFileId!: number | null;
  translation!: ITranslation | null;
  startUploadingFile = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private snackbarService: SnackbarService,
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const translationId = paramMap.get('id');
          if (translationId) {
            return this.dashboardService.getTranslation(translationId);
          }
          this.translation = null;
          this.initTranslationForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.translation = data;
          this.initTranslationForm();
        }
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  initTranslationForm(): void{
    this.displayFileInput = this.translation?.translationsFileId ? false : true;
    this.translationForm = this.formBuilder.group({
      code: [this.translation?.code ?? null, [Validators.required]],
      name: [this.translation?.name ?? null, [Validators.required]],
      translationsFile: [null],
      enabled: [this.translation?.enabled === false]
    });

    if(!this.translation){
      this.translationForm.controls.translationsFile.setValidators(Validators.required);
      this.translationForm.updateValueAndValidity();
    }
  }

  onDisplayFileInput(): void {
    this.displayFileInput = !this.displayFileInput;
  }


  saveFile(event: any): void {
    this.startUploadingFile = true;
    this.uploadService.uploadFile(event.target.files[0]).subscribe((data) => {
      if (data) {
        this.startUploadingFile = false;
        this.translationsFileId = data.id || null
      }
    },
      () => {
        this.snackbarService.show('Sorry, something went wrong', 'error');
        this.startUploadingFile = false;
      }
    );
  }

  submitTraduction(): void{
    const  {code, name, enabled} = this.translationForm.value;
    const currentTranslation = { name, code, translationsFileId: this.translationsFileId ? this.translationsFileId : this.translation?.translationsFileId , enabled: !enabled } as ITranslation;
    this.addOrUpdate(currentTranslation);
  }

  addOrUpdate(formValue: ITranslation): void {
    if (this.translation) {
      this.dashboardService.updateTranslation(formValue, this.translation.id).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['translations'] } }]);
        this.snackbarService.show('Update successfully', 'success')
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    } else {
      this.dashboardService.addTranslation(formValue).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['translations'] } }]);
        this.snackbarService.show('Create successfully', 'success');
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }


}
