import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { ThemeService } from './core/services/theme.service';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsAuthService } from './core/services/microsoftTeams.service';
import { IUserTeams } from './core/interfaces/user.interface';
import { SearchService } from './core/services/search.service';
import { VisibilityEnum } from './core/enum/visibility.enum';
import { IResult } from './core/interfaces/section.interface';
import { SnackbarService } from './shared/snackbar/snackbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly connectWithEmail = localStorage.getItem('connectWithEmail');
  readonly visibilityEnum = VisibilityEnum;

  displayTeamsContent = false;
  hasLayout = true;
  isIframe = window !== window.parent && !window.opener;
  subscription!: Subscription;
  isLoggedIntoAD = false;

  results!: IResult;
  constructor(
    title: Title,
    public authService: AuthService,
    public themeService: ThemeService,
    private router: Router,
    public teamsAuthService: TeamsAuthService,
    private searchService: SearchService,
    private snackbarService: SnackbarService
  ) {
    title.setTitle('People Analytics Platform');
  }

  ngOnInit(): void {
    this.subscription = this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        [
          '/favorites',
          '/privacy',
          '/terms',
          '/forbidden',
          '/teamsauthpopup',
        ].includes(event.url)
      ) {
        this.hasLayout = false;
      }
    });

    // if we are in iframe => move to /favorites in not on this page
    if (this.isIframe) {
      this.hasLayout = false;
      microsoftTeams.initialize();
      this.isLoggedIntoAD = this.teamsAuthService.isLoggedIn();
      if (this.isLoggedIntoAD) {
        this.setFavoriteList();
      }
    } else {
      this.authService.updateLoggedInStatus();
      this.hasLayout = true;
    }
  }

  onDeactivate(): void {
    document.body.scrollTop = 0;
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  loginHandler(): void {
    microsoftTeams.initialize();

    const scopes: any[] = [];
    this.teamsAuthService
      .getAccessToken(scopes, microsoftTeams)
      .then((authState: IUserTeams) => {
        localStorage.setItem('accessToken', authState.accessToken as string);
        this.teamsAuthService.saveAuthState(authState);
        this.teamsAuthService.isUserLoggedIntoAD =
          this.teamsAuthService.isLoggedIn();
          window.location = '/favorites' as any;
      });
  }

  setFavoriteList(): void {
    this.authService.updateLoggedInStatus();
    this.authService.currentUser.subscribe((currentUser) => {
      if (currentUser?.typesenseKey) {
        this.searchService.getMyFavorites(currentUser, 100).subscribe(
          (data) => {
            this.results = data?.results?.map((section: any) => ({
              found: section?.found,
              hits: this.searchService.mapHitToDocument(
                section.hits,
                currentUser
              ),
              name: section.hits[0]?.document?.section_name_facet as string,
              out_of: section.out_of,
            }))[0];
          },
          () =>
            this.snackbarService.show('Sorry, something went wrong', 'error')
        );
      }
    });
  }
}
