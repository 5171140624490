<section class="all-workspace-container d-flex flex-column p-5">
  <div class="all-workspace-header my-3">
    <h4 class="mb-3">USER RIGHT & MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL WORKSPACE MAPPING</h6>
    </div>
  </div>
  <div class="all-workspace-content mt-4">
      <div class="col-6 d-flex align-items-center position-relative">
        <div id="icon-search" class="icon icon-18 icon-search ms-2 position-absolute"></div>
        <input class=" search-workspace form-control ps-5" [(ngModel)]="searchValue" name="search" type="search" autocomplete="off"
          placeholder="Search through all workspace" (search)="searchWorkspaces(false)">
        <button type="button" role="button" attr.aria-label="Search" class="btn-search ms-2"
        (click)="searchWorkspaces(true)">Search</button>
      </div>
    <div class="d-flex justify-content-end">
      <span class="result-text">Showing {{skip + 1}}-{{end}} of {{total}} items
      </span>
    </div>
    <div class="table-all-workspace pb-5">

      <table class="table table-hover table-striped mt-3">
        <thead>
          <tr>
            <ng-container *ngFor="let header of tableHeader;">
              <th scope="col" class="text-center" [ngClass]="header?.isSortable !== false ? 'pointer': '' " (click)="sortTable(header)">
                <div class="d-flex justify-content-between align-items-center mx-2">
                  <div> {{ header.label }}</div>
                  <i class="icon icon-14 ml-2" *ngIf="header.isSortable !== false" [ngClass]="header?.direction ? 'icon-polygon-down' : 'icon-polygon'"></i>
                </div>
              </th>
            </ng-container>
            <th scope="col" class="text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          <div class="loader-container position-absolute pt-5 mt-5" *ngIf="isLoading">
            <app-loader></app-loader>
          </div>
          <ng-container *ngIf="workspaces.length !== null; else noData">
            <tr *ngFor="let workspace of workspaces">
              <td class="col-md-auto"> {{workspace?.name}} </td>
              <td class="col-md-auto" *ngIf="workspace?.adGroupName">
                <div class="d-flex flex-wrap">
                  <span class="type-item ng-label-tmp" >
                    {{workspace?.adGroupName}} </span>
                </div>
              </td>
              <td *ngIf="!workspace?.adGroupName">No group Selected</td>
              <td class="col-md-1">
                <div class="actions d-flex justify-content-center ms-5">
                  <div class="icon icon-25 icon-edit pointer" (click)="editWorkspace(workspace.id)" tooltip="Edit"
                    placement="bottom" containerClass="tooltipClass"></div>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-template #noData>
            <tr>
              <td colspan="8" class="col-12 text-center">
                No Data Found
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-end mt-3" *ngIf="workspaces?.length">
        <pagination [maxSize]="4" [directionLinks]="true" [previousText]="'&laquo;'" [nextText]="'&raquo;'"
          [totalItems]="total" [itemsPerPage]="limit" [(ngModel)]="currentPage" [rotate]="false"
          (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
    </div>
  </div>
</section>
