<div [ngStyle]="{'margin-right.px': isHovered ? 145 : 0}">
  <div class="list-item"
    [ngStyle]="{'left.px': isHovered && (index * 290 - 50 + index * 10 ), 'background-color': bgColor}"
    (mouseenter)="onMouseEnter(true)" (mouseleave)="onMouseLeave(false)" (click)="navigate()"
    [ngClass]="{'hovered-item':  isHovered}">
    <div class="document">
      <ng-container *ngIf="isHovered">
        <div class="document-image-container">
          <img  [ngStyle]="{'object-fit': item?.preview_image_url ? 'cover' : 'contain'}"
          class="document-image" [src]="item?.preview_image_url ? item?.preview_image_url : (item.type_facet === REPORT ? 'assets/images/dashboard-elu.svg' : 'assets/images/default-report.svg')" [alt]="item?.preview_image_url" />
        </div>
      </ng-container>
      <div class="py-2 px-4">
        <div class="document-header d-flex justify-content-between pt-3 pb-4">
          <div class="d-flex font-size-12 fw-bold">
            <span class="me-2 px-2"
              [ngStyle]="{'background-color': item.type_facet === REPORT ? '#1C5074' : '#543C93'}">{{item?.type_facet}}</span>
            <span class="fw-bold" [ngStyle]="{'color': bgColor === '#FFFFFF' ? '#283648' : '#C1CAD4'}">{{item?.code | uppercase}}</span>
          </div>
          <div class="icon icon-16 icon-star" *ngIf="item?.isFavorite"></div>
        </div>
        <div class="document-body d-flex align-items-center mb-3">
          <div class="icon-document icon icon-45 me-2"
            [ngClass]="item.type_facet === REPORT ? 'icon-report' : 'icon-dashboard'"></div>
          <h5 class="trenda-bold fw-bold font-size-16 title-document"
            [ngStyle]="{'max-width': isHovered ? '35rem': '20rem', 'color': bgColor=== '#FFFFFF' ? '#283648' : '#FFFFFF'}">
            {{item?.name}}
          </h5>
        </div>
        <ng-container *ngIf="!isHovered else otherFooter">
          <div class="document-footer d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
              <ng-container *ngIf="item.source_type_facet!=='LINK' else isExternal">
                <span class="data-refresh font-size-13 text-truncate fw-bold"
                [ngStyle]="{'color': bgColor=== '#FFFFFF' ? '#283648' : '#FFFFFF'}">
                  {{ "HOME.SECTION.DATA_REFRESH" | translate }}
                </span>
                <span class="font-size-13 fw-light"
                [ngStyle]="{'color': bgColor === '#FFFFFF' ? '#0C1C30' : '#C1CAD4'}">
                  {{item?.data_refresh ? (item?.data_refresh | dateFormat : 'medium') : ''}} {{item?.data_refresh ? (item?.data_refresh  | date:"HH:mm") : ''}}
                </span>
              </ng-container>
              <ng-template #isExternal>
                <span class="font-size-13 text-truncate fw-bold"
                  [ngStyle]="{'color': bgColor=== '#FFFFFF' ? '#283648' : '#FFFFFF'}">
                  {{ "HOME.SECTION.EXTERNAL" | translate }}&nbsp;{{item.type_facet | titlecase}}
                </span>
              </ng-template>
            </div>
            <div class="d-flex" *ngIf="item?.tags_facet?.length">
              <ng-container *ngFor="let tag of item?.tags_facet | slice:0:1">
                <div class="badge font-size-13 fw-normal me-2 text-wrap border rounded-3"
                [ngStyle]="{'color': bgColor === '#FFFFFF' ? '#0C1C30' : '#C1CAD4', 'border-color': bgColor === '#FFFFFF' ? '#283648 !important' : '#C1CAD4 !important'}">
                  {{tag}}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-template #otherFooter>
          <div class="document-footer d-flex flex-column">
            <p class="doc-description fw-normal font-size-16">
              {{item?.description}}
            </p>
            <hr class="my-2">
            <div class="d-flex flex-wrap align-items-center mt-3" *ngIf="item?.tags_facet?.length">
              <ng-container *ngFor="let tag of item?.tags_facet">
                <div class="badge font-size-12 text-light-grey fw-light me-2 mb-2 text-wrap border rounded-3">
                  {{tag}}
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="item.source_type_facet!=='LINK' else isExternalLink">
            <div class="mt-3 mb-1 d-flex align-items-center flex-wrap">
              <span class="font-size-13 fw-bold me-2">{{ "HOME.SECTION.DATA_SOURCE" | translate }}&nbsp;:</span>
              <div class="title-document">
                <ng-container *ngFor="let source of item?.datasources_facet | slice:0:5; let isLast = last">
                  <span class="font-size-12 text-light-grey fw-light mt-1">{{source}}{{isLast ? '' : ', '}}</span>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-template #isExternalLink>
            <span class="font-size-13 text-truncate fw-bold">
              {{ "HOME.SECTION.EXTERNAL" | translate }}&nbsp;{{item.type_facet | titlecase}}
            </span>
            <span
              class="font-size-13 fw-bold me-2">{{item.type_facet | titlecase}}&nbsp;{{ "HOME.SECTION.SOURCE" | translate }}&nbsp;:</span>
            <div class="title-document">
              <ng-container *ngFor="let source of item?.datasources_facet | slice:0:5; let isLast = last">
                <span class="font-size-12 text-light-grey fw-light mt-1">{{source}}{{isLast ? '' : ', '}}</span>
              </ng-container>
            </div>
          </ng-template>
          <ng-container>
            <div *ngIf="item.source_type_facet !== 'LINK'">
              <span class="font-size-13 fw-bolder">{{ "HOME.SECTION.DATA_REFRESH" | translate }}</span>
            </div>
            <div class="d-flex align-items-start justify-content-between">
              <span class="font-size-12 text-light-grey fw-light">{{item?.data_refresh ? (item?.data_refresh | dateFormat : 'medium') : ''}} {{item?.data_refresh ? (item?.data_refresh  | date:"HH:mm") : ''}}</span>
              <ng-container [ngSwitch]="item?.source_type_facet">
                <ng-container  *ngSwitchCase="sourceTypes.SAP_BO">
                  <img class="image-type" [src]="'assets/images/BO-icon.png'" [alt]="'sap-icon'" [width]="14" [height]="14">
                </ng-container>
                <ng-container  *ngSwitchCase="sourceTypes.LINK">
                  <img class="image-type link-item-icon" [src]="'assets/images/link-file.svg'" [alt]="'link-icon'" [width]="5" [height]="5">
                </ng-container>
                <ng-container  *ngSwitchCase="sourceTypes.FILE">
                  <img class="image-type link-item-icon" [src]="'assets/images/icon-file-green.svg'" [alt]="'file-icon'" [width]="5" [height]="5">
                </ng-container>
                <ng-container  *ngSwitchDefault>
                  <img class="image-type power-bi-item" [src]="'assets/images/Power-BI-icon.svg'" [alt]="'bo-icon'" [width]="5" [height]="5">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
