<section class="workspace-container d-flex flex-column p-5">
  <div class="workspace-header my-3">
    <h4 class="mb-3">USER RIGHT & MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL WORKSPACE MAPPING - edit a group</h6>
    </div>
  </div>
  <div class="workspace-content mt-4">
    <form [formGroup]="workspacesForm" class="g-3">
      <div class="row">
        <div class="col-6">
          <label for="name2" class="form-label">Workspace</label>
          <input type="text" class="form-control" formControlName="name" id="name2">
        </div>
        <div class="col-6">
          <label for="adGroup" class="form-label">AD Groups</label>
          <ng-select placeholder="Search by email" [items]="allGroups$ | async" bindLabel="adGroupName"
            [minTermLength]="1" [loading]="groupsLoading" formControlName="adGroup" [typeahead]="groupSearch$"
            (change)="onSearchGroupChanged()">
          </ng-select>
        </div>
        <div class="col-12 mt-5 text-end">
          <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" (click)="submitForm()"
            attr.aria-label="button submit workspace" id="btn-submit-workspace"
            >Edit Group
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
