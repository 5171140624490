<div class="sidebar-container flex-shrink-0 p-3" style="height: 100vh;">
  <div class="back-button d-flex align-items-center pointer m-5" (click)="goBack()">
    <div attr.aria-label="icon back" id="icon-back" class="icon icon-12 icon-back me-2"></div>
    <label class="font-size-13 trenda-regular pointer">
      Quit Administration
    </label>
  </div>
  <ul class="list-unstyled ps-0">
    <ng-container *ngFor="let menuItem of menuItems">
      <li class="mb-3">
        <button class="btn btn-toggle align-items-center rounded collapsed text-break" data-bs-toggle="collapse"  [attr.data-bs-target]="'#' + menuItem.id + '-collapse'" [attr.aria-expanded]="menuItem.expanded">
          {{menuItem.label}}
        </button>
        <div class="collapse ms-3" [id]="menuItem.id + '-collapse'" [ngClass]="{'show': menuItem.expanded}">
          <ul *ngIf="menuItem.subMenuItems.length" class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <ng-container *ngFor="let item of menuItem.subMenuItems">
              <li class="mb-2"><a class="link-dark rounded text-break" routerLinkActive="active-link" [id]="item.id" [routerLink]="['/admin', {outlets: {admin: [item.link]}}]">{{item.label}}</a></li>
            </ng-container>
          </ul>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
