import { Injectable } from '@angular/core';
import { IUserTeams } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class TeamsAuthService {
  authState: IUserTeams;
  isUserLoggedIntoAD = false;
  constructor() {
    this.authState = {
      username: localStorage.getItem('pap.teams.authState.username'),
      accessToken: localStorage.getItem('pap.teams.authState.accessToken'),
      expiresOn: localStorage.getItem('pap.teams.authState.expiresOn')
        ? localStorage.getItem('pap.teams.authState.expiresOn')
        : null,
    };
  }

  // Determine if someone is logged in
  isLoggedIn(): boolean {
    if (this.authState.expiresOn) {
      return (
        new Date().getTime() < new Date(this.authState.expiresOn).getTime()
      );
    }
    return false;
  }

  // Get the logged in user name or null if not logged in
  getUsername(): string | null {
    return this.authState.username;
  }

  // Call this to get an access token
  getAccessToken(scopes: any, microsoftTeams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      microsoftTeams.authentication.authenticate({
        url: window.location.origin + '/teamsauthpopup',
        width: 600,
        height: 535,
        successCallback: (response: any) => {
          const { username, accessToken, expiresOn } = JSON.parse(response);
          this.authState = { username, accessToken, expiresOn };
          resolve(this.authState);
        },
        failureCallback: (reason: any) => {
          reject(reason);
        },
      });
    });
  }

  saveAuthState(authState: IUserTeams): void {
    this.authState = authState;
    localStorage.setItem(
      'pap.teams.authState.accessToken',
      this.authState.accessToken as string
    );
    localStorage.setItem(
      'pap.teams.authState.username',
      this.authState.username as string
    );
    localStorage.setItem(
      'pap.teams.authState.expiresOn',
      this.authState.expiresOn as string
    );
  }
}
