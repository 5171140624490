import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IELearning } from 'src/app/core/interfaces/e-learning.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-e-learnings',
  templateUrl: './add-e-learnings.component.html',
  styleUrls: ['./add-e-learnings.component.scss']
})
export class AddELearningsComponent implements OnInit {
  eLearning!: IELearning | null;
  eLearningForm!: FormGroup;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.init()
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const eLearningId = paramMap.get('id');
          if (eLearningId) {
            return this.dashboardService.getELearning(eLearningId);
          }
          this.eLearning = null;
          this.initELearningForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.eLearning = data;
          this.initELearningForm();
        }
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  initELearningForm(): void {
    this.eLearningForm = this.formBuilder.group({
      name: [
        this.eLearning?.name ?? null, [Validators.required]
      ],
      url: [
        this.eLearning?.url ?? null, [Validators.required]],

      enabled: [this.eLearning?.enabled === false]
    });

  }

  submitELearning(): void {
    const enabled = !this.eLearningForm.controls.enabled.value;
    const form = { ...this.eLearningForm.getRawValue(), enabled } as IELearning;
    this.addOrUpdate(form);
  }

  addOrUpdate(formValue: IELearning): void {
    if (this.eLearning) {
      this.dashboardService.updateELearning(formValue, this.eLearning.id).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['elearnings'] } }]);
        this.snackbarService.show('Update successfully', 'success')
      },
      (err) => {
        if (err.status === 422) {
          this.snackbarService.show('Invalid URL', 'error');
        } else {
          this.snackbarService.show('Sorry, something went wrong', 'error');
        }
      });
    } else {
      this.dashboardService.addELearning(formValue).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['elearnings'] } }]);
        this.snackbarService.show('Create successfully', 'success');
      },
      (err) => {
        if (err.status === 422) {
          this.snackbarService.show('Invalid URL', 'error');
        } else {
          this.snackbarService.show('Sorry, something went wrong', 'error');
        }
      });
    }
  }
}
