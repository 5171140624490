import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URLS, TYPESENSE_HOST } from 'src/environments/environment';
import { IDocument, IResult, ISectionAdmin, ITypesenseResponse } from '../interfaces/section.interface';
import { ResultTypesEnum, SearchFacetTypesEnum } from '../enum/search.enum';
import { concatMap, map } from 'rxjs/operators';
import { ISearchBody } from '../interfaces/search.interface';
import { IUser } from '../interfaces/user.interface';
import { IReport } from '../interfaces/report.interface';
import { INews, INewsItem } from '../interfaces/news.interface';
import { NewsCategoriesEnum } from '../enum/news.enum';
import { VisibilityEnum } from '../enum/visibility.enum';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchResults: IResult[] = [];
  favorites: IResult[] = [];
  filters: SearchFacetTypesEnum[] = [];
  facetResults: IResult[] = [];
  tagsFilters = [];
  dataSourcesFilters = [];
  selectedSection!: string;

  news!: {
    newsAndIncidents: INewsItem[],
    latestNews: INewsItem[],
    latestAlert: INewsItem,
    total: number,
  }

  constructor(private http: HttpClient) { }

  searchGlobal(connectedUser: IUser): Observable<ITypesenseResponse> {
    const key = connectedUser?.typesenseKey;
    let searches: ISearchBody[] = connectedUser?.sections.map((s: { id: number, name: string, description: string }) => ({ collection: 'reports', q: '*', 'per_page': 12, filter_by: `section_name_facet:\`${s?.name}\`` }));
    return this.getSectionsOrdered().pipe(
      concatMap((data) => {
        let visibility = data.items.map(v => ({ name: v.name, visibility: v.visibility }))
        searches = data.items.map((s) => ({ collection: 'reports', q: '*', 'per_page': 12, filter_by: `section_name_facet:=\`${s?.name}\`` }))
        return this.http.post<ITypesenseResponse>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${key}`, { searches })
          .pipe(
            map((res) => {
              let resultConcat = res.results.map(r => { return { ...r, visibility: visibility.filter(el => el.name === r.hits[0]?.document?.section_name_facet)[0]?.visibility } })
              return {
                results: resultConcat
              } as ITypesenseResponse
            })
          );
      })
    )
  }

  searchQuery(q: string, filters: string[], key: string): Observable<any>{
    filters = filters.map(f => '`' + f + '`')
    const searches: ISearchBody[]  = [{collection: 'reports', q, query_by:'name,code,tags_facet,description,datasources_facet',num_typos:'0',per_page: 70, filter_by: `type_facet:=[${filters}]`, exhaustive_search: true}];
    return this.http.post<any>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${key}`, {searches}).pipe(
      map(data => {
        if (data?.results) {
          return data?.results?.map((section: any) => ({
            found: section?.found,
            hits: section.hits.map((hit: { document: IDocument }) => ({
              id: hit?.document?.id || null,
              name: hit.document?.name || null,
              code: hit.document?.code || null,
              embed_url: hit?.document?.embed_url || null,
              type_facet: hit.document?.type_facet || null,
              source_type_facet: hit.document?.source_type_facet || null,
              url: hit.document?.url || null,
              visibility_facet: hit?.document?.visibility_facet || null,
              pinned: hit?.document.pinned || false,
            })),
            name: section.hits[0]?.document?.section_name_facet || null,
            type: ResultTypesEnum.SECTION
          }))[0];
        }
        return [];
      })
    );
  }

  searchQueryByFacet(q: string, filters: string[], user: IUser): Observable<any>{
    const searches: ISearchBody[] = filters.map(f => ({collection: 'reports', q , query_by:'name,code,tags_facet,description',num_typos:'0', per_page: 50, filter_by: `type_facet:=\`${f}\``, exhaustive_search: true }));
    return this.http.post<any>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${user.typesenseKey}`, {searches}).pipe(
      map(data => {
        if (data?.results) {
          return data?.results?.map((section: any, index: number) => ({
            found: section?.found,
            hits: this.mapHitToDocument(section.hits, user),
            name: filters[index] || null,
            type: filters[index]
          }));
        }
        return [];
      })
    );
  }


  getMyFavorites(connectedUser: IUser, page: number): Observable<ITypesenseResponse> {
    const key = connectedUser?.typesenseKey;
    const searches: ISearchBody[] = [{collection: 'reports', q: '*',num_typos:'0', 'per_page': page, filter_by: `favorites_facet:${connectedUser?.email}`}];
    return this.http.post<ITypesenseResponse>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${key}`, {searches});
  }

  getLastViewed(): Observable<{ items: IReport[], total: number }> {
    return this.http.get<{ items: IReport[], total: number }>(`${API_URLS.REPORTS}/views/last`).pipe(
      map(result => (result as any)?.items?.map((item: IReport, index: number) => ({
        id: item?.id,
        order: index + 1
      })))
    );
  }

  getLastViewedFromTypesense(connectedUser: IUser, filters: { id: number, order: number }[]): Observable<any> {
    const filtersId = filters?.map(f => f?.id);
    const key = connectedUser?.typesenseKey;
    const searches: ISearchBody[] = [{ collection: 'reports', q: '*', num_typos: '0', 'per_page': 50, filter_by: `id_facet:=[${filtersId}]`, 'query_by':'id_facet'}];
    return this.http.post<any>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${key}`, {searches}).pipe(
      map(data => {
        if (data?.results) {
          return data?.results?.map((section: any) => ({
            found: section?.found,
            hits: this.mapHitToDocument(section.hits, connectedUser)?.map((hit) => {
              const foundedHit = filters?.find(f => f?.id?.toString() == hit?.id);
              if (foundedHit) {
                hit.order = foundedHit?.order
              }
              return hit;
            })?.sort((a: IDocument, b: IDocument) => (Number(a?.order) - Number(b?.order)))
          }))
        }
        return [];
      })
    );
  }

  mapHitToDocument(hits: { document: IDocument }[], connectedUser: IUser): IDocument[] {
    return hits?.map((hit) => ({
      id: hit?.document?.id || null,
      name: hit.document?.name,
      description: hit.document?.description || null,
      preview_image_url: hit.document?.preview_image_url || null,
      code: hit.document?.code || null,
      type_facet: hit.document?.type_facet || null,
      tags_facet: hit.document?.tags_facet || null,
      source_type_facet: hit.document?.source_type_facet || null,
      embed_url: hit?.document?.embed_url || null,
      datasources_facet: hit?.document?.datasources_facet || [],
      data_refresh: hit?.document?.data_refresh || null,
      isFavorite: hit?.document?.favorites_facet?.includes(connectedUser.email) || false,
      url: hit.document?.url || null,
      visibility_facet: hit.document?.visibility_facet || VisibilityEnum.AVAILABLE,
    }));
  }


  searchBySection(connectedUser: IUser, section: string): Observable<ITypesenseResponse> {
    const key = connectedUser?.typesenseKey;
    const searches = [{collection: 'reports', q: '*',num_typos:'0', 'per_page': 50, filter_by: `section_name_facet:=\`${section}\`` }];
    return this.http.post<ITypesenseResponse>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${key}`, {searches});
  }

  getSectionsOrdered(): Observable<{ items: ISectionAdmin[], total: number }> {
    return this.http.get<{ items: ISectionAdmin[], total: number }>(`${API_URLS.SECTIONS}?skip=0&limit=50&sort=order&is_desc=false`);
  }

  searchFilters(q: string, type: 'TAG'|'DATA_SOURCE'|'GLOSSARY', section: string, isFavorite: boolean, connectedUser: IUser): Observable<any>{
    let searches: ISearchBody[] = [];
    switch (type) {
      case 'DATA_SOURCE':
        if(isFavorite){
          searches = [{collection: 'reports', q:'*', num_typos:'0','query_by':'datasources_facet', filter_by: `favorites_facet:=${connectedUser?.email}`,'per_page': 50, facet_by:'datasources_facet' ,facet_query: `datasources_facet:=${q}`}];
        } else{
          searches = [{collection: 'reports', q:'*', num_typos:'0','query_by':'datasources_facet', filter_by: `section_name_facet:=\`${section}\``, 'per_page': 50, facet_by:'datasources_facet' ,facet_query: `datasources_facet:=${q}`}];
        }
        break;
      case 'TAG':
        if(isFavorite){
          searches = [{collection: 'reports', q:'*', num_typos:'0','query_by':'tags_facet', filter_by: `favorites_facet:=${connectedUser?.email}`,'per_page': 50,facet_by:'tags_facet' ,facet_query: `tags_facet:=${q}`}];
        } else {
          searches = [{collection: 'reports', q:'*', num_typos:'0','query_by':'tags_facet',filter_by: `section_name_facet:=\`${section}\``, 'per_page': 50,facet_by:'tags_facet' ,facet_query: `tags_facet:=${q}`}];
        }
        break;
      default:
        break;
    }
    return this.http.post<any>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${connectedUser?.typesenseKey}`, {searches}).pipe(
      map(data => {
        if(data?.results){
          return  data?.results?.map((section: any) => ({
            found: section?.found,
            facet_counts: section?.facet_counts[0]?.counts?.map((f: any) => ({count: f?.count, value: f?.value})),
            hits: section.hits.map((hit: {document: IDocument}) => ({
              id: hit?.document?.id || null,
              name: hit.document?.name || null,
              code: hit.document?.code || null,
              embed_url: hit?.document?.embed_url || null,
              type_facet: hit.document?.type_facet || null,
            })),
            name: section.hits[0]?.document?.section_name_facet || null,
            type: ResultTypesEnum.SECTION
          }))[0];
        }
        return [];
      })
    );
  }

  searchByFilters(section: string, isFavorite: boolean, datasourcesFilters: string[], tagFilters: string[], connectedUser: IUser):Observable<ITypesenseResponse>{
    const key = connectedUser?.typesenseKey;
    datasourcesFilters = datasourcesFilters.map(f => '`' + f + '`')
    tagFilters = tagFilters.map(f => '`' + f + '`')
    const dataSourcesQuery = `${datasourcesFilters.length ? `&& datasources_facet:=[${datasourcesFilters}]` : ''}`;
    const tagsQuery = `${tagFilters.length ? `&& tags_facet:=[${tagFilters}]` : ''}`;
    let searches: ISearchBody[] = [];
    if(isFavorite){
      searches = [{collection: 'reports', q: '*', num_typos:'0', per_page: 50, filter_by: `favorites_facet:=${connectedUser?.email} ${dataSourcesQuery} ${tagsQuery}`}];
    } else{
      searches = [{collection: 'reports', q: '*', num_typos:'0', query_by:'name,description', 'per_page': 50, filter_by: `section_name_facet:=\`${section}\`  ${dataSourcesQuery} ${tagsQuery}`, exhaustive_search: true}];
    }
    return this.http.post<ITypesenseResponse>(`${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${key}`, {searches});

  }
  getNews(): Observable<INews> {
    return this.http.get<INews>(`${API_URLS.NEWS}?skip=0&sort=order&is_desc=true&only_current=true`);
  }

  setNews(news: INews): void {

    const newsAndIncidents = news.items
      .filter(newsItem => newsItem.category !== NewsCategoriesEnum.ALERT)
      .sort((item1, item2) => {
        if (item1.category === NewsCategoriesEnum.NEWS && item2.category === NewsCategoriesEnum.INCIDENT) {
          return -1;
        }
        if (item1.category === NewsCategoriesEnum.INCIDENT && item2.category === NewsCategoriesEnum.NEWS) {
          return 1;
        }
        return 0;
      });

    const latestNews = news.items.filter(newsItem => newsItem.category === NewsCategoriesEnum.NEWS && newsItem.pinned);
    const latestAlert = news.items.filter(newsItem => newsItem.category === NewsCategoriesEnum.ALERT)
      .sort((a, b) => new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime())[0];

    const total = news.total;

    this.news = {
      newsAndIncidents,
      latestNews,
      latestAlert,
      total
    }
  }

}
