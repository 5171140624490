import { Component, OnInit } from '@angular/core';
import { SnackbarService } from './snackbar.service';
import { trigger } from '@angular/animations';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  animations: [trigger('state', [])]
})
export class SnackbarComponent implements OnInit {
  show = false;
  message = 'This is snackbar';
  type = 'success';
  toBeTranslated = false;

  constructor(private snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.snackbarService.snackbarState.subscribe(
      state => {
        if (state.type) {
          this.type = state.type;
        } else {
          this.type = 'success';
        }
        this.message = state.message;
        this.show = state.show;
        this.toBeTranslated = state.toBeTranslated;
        setTimeout(() => {
          this.show = false;
        }, 2500);
      }
    );
  }

  hideSnackBar(): void {
    this.show = false;
  }
}
