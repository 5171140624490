import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { IWorkspace } from 'src/app/core/interfaces/group';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-edit-workspace-mapping',
  templateUrl: './edit-workspace-mapping.component.html',
  styleUrls: ['./edit-workspace-mapping.component.scss']
})
export class EditWorkspaceMappingComponent implements OnInit {
  workspacesForm!: FormGroup;
  workspaces!: IWorkspace | null;
  allGroups$!: Observable<{ adGroupId: string, adGroupName: string }[]>;
  groupSearch$ = new Subject<string>();
  groupsLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.init();
    this.onSearchGroupChanged();
  }

  init(): void {
    this.initWorkSpaceForm();
    const workspaceId = this.activatedRoute.snapshot.paramMap.get('id');
    if (workspaceId) {
      this.dashboardService
        .getWorkspace(workspaceId)
        .subscribe((data) => {
          if (data) {
            this.workspaces = data;
            this.initWorkSpaceForm();
          }
        },
          () => this.snackbarService.show('Sorry, something went wrong', 'error')
        );
    }
  }

  initWorkSpaceForm(): void {
    this.workspacesForm = this.formBuilder.group({
      name: { value: this.workspaces?.name ?? null, disabled: true },
      adGroup: [this.workspaces?.adGroupName ?? null]
    });
  }

  onSearchGroupChanged(): void {
    this.allGroups$ = concat(
      of([]), // default items
      this.groupSearch$.pipe(
        filter((res) => { return res !== null && res.length >= 1; }),
        distinctUntilChanged(),
        debounceTime(600),
        tap(() => (this.groupsLoading = true)),
        switchMap((term: string) => {
          return this.dashboardService.getAdGroup(term).pipe(
            map(resp => resp.value.map((elem: { id: string, displayName: string }) => ({
              adGroupId: elem?.id,
              adGroupName: elem?.displayName,
            })).filter(e => e?.adGroupName !== null)),
            catchError(() => of([])), // empty list on error
            tap(() => (this.groupsLoading = false))
          );
        })
      )
    );
  }

  submitForm(): void {
    if (this.workspacesForm.controls.adGroup.value === null) {
      this.workspacesForm.controls.adGroup.setValue({
        adGroupId: null,
        adGroupName: null
      });
    }
    this.workspacesForm.removeControl('name');
    let form = {
      ...this.workspacesForm.controls.adGroup.value
    };
    this.updateWorkspace(form);
  }

  updateWorkspace(formValue: IWorkspace): void {
    if (this.workspaces) {
      this.dashboardService.updateWorkspace(formValue, this.workspaces.id).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['workspaces'] } }]);
        this.snackbarService.show('Update successfully', 'success')
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

}
