 <ng-container *ngIf="authService?.isAuthenticated | async; else withoutTrad">
   <div class="container d-flex text-center pt-5 align-items-center flex-column justify-content-center h-100">
     <h1>{{"FORBIDDEN.NO_ACCESS" | translate }}</h1>
     <h2>{{"FORBIDDEN.MSG_NO_ACCESS" | translate }}</h2>
   </div>
 </ng-container>
 <ng-template #withoutTrad>
   <div class="container d-flex text-center pt-5 align-items-center flex-column justify-content-center h-100">
     <h1>You do not have access to People Analytics</h1>
     <h2>
       People Analytics is a centralized analytics platform for all HR-related dashboards and reports. If you are an HR
       user and do not have access to People Analytics please create a ticket using CAROL/ONE PROFILE/Service Now.
     </h2>
   </div>
 </ng-template>
