import { IELearning } from "./e-learning.interface";

export interface IReport {
  bookmarkManager: any;
  sourceParentId?: string;
  sourceId: string;
  sourceName: string;
  name: string;
  code: string;
  description: string;
  type: ReportTypeEnum;
  sourceType: string;
  url: string;
  embedUrl: string;
  tags: ITag[];
  visibility: string;
  unavailabilityMessage?: any;
  previewImageUrl: string;
  coverImageUrl: string;
  sectionId: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  isFavorite: boolean;
  section: ISection;
  pinned: boolean;
  users: {
    id: number;
    email: string;
  }[];
  datasources: IDataSource[];
  groups?: number[];
  disabled?: boolean;
  order?: number;
  mainFileId?: number;
  mainFile?: {
    id: number,
    name: string,
    status: string
  }
  paginated?: boolean;
  previewImageId?: number;
  attachments?: number[] | IAttachment[];
  previewImage?: IAttachment;
  elearnings: IELearning[];
  selected?: boolean;
}

export interface ITag{
  name: string;
  comment: string;
  enabled: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface ISection{
  description: string;
  visibility: string;
  unavailabilityMessage?: any;
  id: number;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  name: string;
  code: string;
}

export interface IDataSource{
  id: number;
  name: string;
  refreshDate: string;
  enabled?: boolean;
}

declare global {
  interface Document {
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }
}

export enum reportSourceTypeEnum {
  POWER_BI = 'power-bi',
  SAP_BO = 'sap-bo',
  LINK = 'link',
  FILE = 'file'
}

export enum ReportTypeEnum {
  REPORT = 'REPORT',
  DASHBOARD = 'DASHBOARD'
}

export interface IAttachment {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  size: number;
  type: string;
  status: string;
}

export interface IAttachmentResponse {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  size: number;
  type: string;
  status: string;
  downloadUrl: string;
}
