<section class="all-news-container d-flex flex-column p-5">
  <div class="all-news-header my-3">
    <h4 class="mb-3">ALERT & NEWS</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL NEWS</h6>
      <div class="d-flex align-items-center">
        <button (click)="addNews()" class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button add news"
          id="btn-add-news">Create a News</button>
      </div>
    </div>
  </div>
  <div class="all-news-content mt-4">
    <div class="d-flex justify-content-end">
      <span
        class="result-text">Showing {{skip + 1}}-{{end}} of {{total}} items
      </span>
    </div>
    <div class="table-all-news pb-5">
      <table class="table table-hover table-striped mt-3">
        <thead>
          <tr>
            <ng-container *ngFor="let header of tableHeader;">
              <th scope="col" class="text-center pointer" (click)="sortTable(header)">
                <div class="d-flex justify-content-between align-items-center mx-2">
                  <div> {{ header.label }}</div>
                  <i class="icon icon-14 ml-2" [ngClass]="header?.direction ? 'icon-polygon-down' : 'icon-polygon'"></i>
                </div>
              </th>
            </ng-container>
            <th scope="col" class="text-center">PIN IT</th>
            <th scope="col" class="text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          <div class="loader-container position-absolute pt-5 mt-5" *ngIf="!news.length">
            <app-loader></app-loader>
          </div>
          <tr *ngFor="let new of news">
            <td class="col-md-auto ">
              <div class="d-flex align-items-center">
                <div class="icon-news-table icon icon-30 me-4"
                  [ngClass]="new?.category === NEWS ? 'icon-news-table' : 'icon-incident'"></div>
                <div>{{new?.title}}</div>
              </div>
            </td>
            <td class="col-md-auto">{{new?.message }}</td>
            <td class="col-md-1">{{ new?.published ? "Published" : "Unpublished" }}</td>
            <td class="col-md-1">{{new?.startsAt | dateFormat : 'short'}}</td>
            <td class="col-md-auto">{{new?.endsAt ? (new?.endsAt | dateFormat : 'short') : ''}}
              <td class="col-md-1">
              <div class="d-flex justify-content-center">
                <i class="icon icon-16 icon-checkmark-black" *ngIf="new?.pinned"></i>
              </div>
            </td>
            <td class="col-md-1">
              <div class="actions d-flex justify-content-center ms-5">
                <div class="icon icon-25 icon-edit pointer" (click)="editNews(new.id)"
                  tooltip="Edit" placement="bottom"
                  containerClass="tooltipClass"></div>
                <div role="button" class="icon icon-25 icon-delete ms-2 pointer" (click)="deleteNews(new.id)"
                  tooltip="Delete" placement="bottom"
                  containerClass="tooltipClass"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end mt-3" *ngIf="news?.length">
        <pagination [directionLinks]="false" [totalItems]="total" [itemsPerPage]="limit" [(ngModel)]="currentPage"
          (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
    </div>
  </div>
</section>
