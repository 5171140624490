import { ITableHeader } from "../interfaces/section.interface";

export const headerSections: ITableHeader[] = [
  {
    id: 0,
    name: 'order',
    label: 'POSITION',
    direction: false
  },
  {
    id: 1,
    name: 'name',
    label: 'NAME',
    direction: false
  },
  {
    id: 2,
    name: 'code',
    label: 'CODE',
    direction: false
  },
  {
    id: 3,
    name: 'createdAt',
    label: 'CREATION DATE',
    direction: false
  },
  {
    id: 4,
    name: 'updatedAt',
    label: 'MODIF. DATE',
    direction: false
  }
]

export const headerReports: ITableHeader[] = [
  {
    id: 0,
    name: 'code',
    label: 'CODE',
    direction: false
  },
  {
    id: 1,
    name: 'name',
    label: 'HEADER',
    direction: false
  },
  {
    id: 2,
    name: 'sourceType',
    label: 'SOURCE TYPE',
    direction: false
  }

]


export const headerTags: ITableHeader[] = [

  {
    id: 0,
    name: 'name',
    label: 'NAME',
    direction: false
  },
  {
    id: 1,
    name: 'comment',
    label: 'COMMENT',
    direction: false
  },
  {
    id: 2,
    name: 'createdAt',
    label: 'CREATION DATE',
    direction: false
  },
  {
    id: 3,
    name: 'updatedAt',
    label: 'MODIF. DATE',
    direction: false
  }
]

export const headerDataSource: ITableHeader[] = [

  {
    id: 0,
    name: 'id',
    label: 'ID',
    direction: false
  },
  {
    id: 1,
    name: 'name',
    label: 'NAME',
    direction: false
  },
  {
    id: 2,
    name: 'refreshDate',
    label: 'DATA REFRESH',
    direction: false
  },
]

export const headerGroups: ITableHeader[] = [

  {
    id: 0,
    name: 'name',
    label: 'NAME',
    direction: false
  },
  {
    id: 1,
    name: 'boGroup',
    label: 'BO GROUPS',
    direction: false
  },
  {
    id: 2,
    name: 'successFactorsGroup',
    label: 'SUCCESS FACTORS GROUPS',
    direction: false
  },
]
export const headerUsers: ITableHeader [] = [

  {
    id: 0,
    name: 'firstName',
    label: 'FIRST NAME',
    direction: false
  },
  {
    id: 1,
    name: 'lastName',
    label: 'LAST NAME',
    direction: false
  },
  {
    id: 2,
    name: 'email',
    label: 'UPN',
    direction: false
  },
  {
    id: 3,
    name: 'sapBoId',
    label: 'SAP BO ID',
    direction: false
  },
  {
    id: 4,
    name: 'groups',
    label: 'GROUPS',
    direction: false,
    isSortable: false
  },

  {
    id: 5,
    name: 'isAdmin',
    label: 'ADMIN\'S RIGHT',
    direction: false
  },

]

export const headerWorkspaces: ITableHeader [] = [
  {
    id: 0,
    name: 'name',
    label: 'Workspaces',
    direction: false
  },
  {
    id: 1,
    name: 'adGroupName',
    label: 'Groups',
    direction: false,
    isSortable: false,
  },
]

export const headerNews: ITableHeader[] = [
  {
    id: 0,
    name:'title',
    label: 'TITLE',
    direction: false
  },
  {
    id: 1,
    name:'message',
    label: 'EXCERPT',
    direction: false
  },
  {
    id: 2,
    name:'published',
    label: 'STATUS',
    direction: false
  },
  {
    id: 3,
    name:'startsAt',
    label: 'START DATE',
    direction: false
  },
  {
    id: 4,
    name:'endsAt',
    label: 'END DATE',
    direction: false
  }
]

export const headerELearning: ITableHeader[] = [

  {
    id: 0,
    name: 'name',
    label: 'NAME',
    direction: false
  },
  {
    id: 1,
    name: 'url',
    label: 'URL',
    direction: false
  },
]

export const headerTranslations: ITableHeader[] = [

  {
    id: 0,
    name: 'name',
    label: 'LANGUAGE',
    direction: false
  },
  {
    id: 1,
    name: 'code',
    label: 'CODE',
    direction: false
  }
]
