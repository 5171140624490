export const environmentFrSettings = {
  firstDayOfWeek: {
    code: 1
  },
  language: {
    code: 'fr-FR',
    dateFormat: {
      mini: 'DD/MM', // 20/10
      short: 'DD/MM/YYYY', // 20/10/2019
      medium: 'DD MMMM YYYY', // 20 octobre 2019
      long: 'dddd DD MMMM YYYY' // dimanche 20 octobre 2019
    }
  },
  timeZone: 'Europe/Paris',
};

export const environmentEnSettings = {
  firstDayOfWeek: {
    code: 7
  },
  language: {
    code: 'en-US',
    dateFormat: {
      mini: 'DD/MM', // 20/10
      short: 'DD/MM/YYYY', // 20/10/2019
      medium: 'DD MMMM YYYY', // 20 octobre 2019
      long: 'dddd DD MMMM YYYY' // dimanche 20 octobre 2019
    }
  },
  timeZone: 'Europe/Paris',
};


export const dateFormatsLanguages = [
  {
    label: 'Français (France)',
    code: 'fr-FR',
    mini: 'DD/MM',
    short: 'DD/MM/YYYY',
    medium: 'DD MMMM YYYY',
    long: 'dddd DD MMMM YYYY'
  },
  {
    label: 'English (USA)',
    code: 'en-US',
    mini: 'MM/DD',
    short: 'MM/DD/YYYY',
    medium: 'MMMM DD YYYY',
    long: 'dddd MMMM DD YYYY'
  }
];

export const countries = [
  {
    code: 'fr',
    label: 'France'
  },
  {
    code: 'us',
    label: 'USA'
  }
];
