<section class="users-container d-flex flex-column p-5">
  <div class="users-header my-3">
    <h4 class="mb-3">USER RIGHT & MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>{{user ? 'EDIT USER' : 'ADD USER'}}</h6>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-xs-12 col-md-8 col-lg-6">
      <ng-select placeholder="Search by UPN" [items]="allUsers$ | async" bindLabel="email" [minTermLength]="1"
        [loading]="usersLoading" typeToSearchText="Please enter {{1}} or more characters" [typeahead]="emailSearch$"
        [(ngModel)]="selectedEmail" (ngModelChange)="onEmailSelected()">
      </ng-select>
    </div>
  </div>
  <div class="users-content mt-4" *ngIf="userForm">
    <form [formGroup]="userForm" class="g-3">
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="email" class="form-label">UPN</label>
          <input type="text" class="form-control" id="email" formControlName="email"
            [ngClass]="{'is-invalid': userForm.controls.email.invalid && userForm.controls.email.touched}">
          <div *ngIf="userForm.controls.email.errors?.required && userForm.controls.email.touched"
            class="invalid-feedback">
            User Need an UPN!
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-3">
          <label for="firstName" class="form-label">First Name</label>
          <input type="text" class="form-control" id="firstName" formControlName="firstName"
            [ngClass]="{'is-invalid': userForm.controls.firstName.invalid && userForm.controls.firstName.touched}">
          <div *ngIf="userForm.controls.firstName.errors?.required && userForm.controls.firstName.touched"
            class="invalid-feedback">
            User Need a First Name
          </div>
        </div>
        <div class="col-3">
          <label for="lastName" class="form-label">Last Name</label>
          <input type="text" class="form-control" id="lastName" formControlName="lastName"
            [ngClass]="{'is-invalid': userForm.controls.lastName.invalid && userForm.controls.lastName.touched}">
          <div *ngIf="userForm.controls.lastName.errors?.required && userForm.controls.lastName.touched"
            class="invalid-feedback">
            User Need a Last Name
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-3">
          <label for="sapBoId" class="form-label">SAP BO ID</label>
          <input type="text" class="form-control" id="sapBoId" formControlName="sapBoId">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-3 ml-4">
          <input class="form-check-input" type="checkbox" formControlName="isAdmin" id="isAdmin"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="isAdmin">
            Make this User Admin!
          </label>
        </div>
        <div class="col-3 form-check">
          <input class="form-check-input" type="checkbox" formControlName="enabled" id="userDisabled"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="userDisabled">
            Yes, disable this User!
          </label>
        </div>
      </div>

      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button submit user"
          id="btn-submit-user" [disabled]="!userForm.controls.email.value" (click)="submitForm()">
          {{user ? ' Edit User' : 'Add User'}}
        </button>
      </div>

    </form>
  </div>
</section>
