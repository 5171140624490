import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAlertAdmin } from 'src/app/core/interfaces/news-alert.interface';
import { SearchService } from 'src/app/core/services/search.service';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-alert',
  templateUrl: './add-alert.component.html',
  styleUrls: ['./add-alert.component.scss']
})
export class AddAlertComponent implements OnInit {
  alert!: IAlertAdmin | null;
  alertForm!: FormGroup;
  idAlert!: number;
  readonly ALERT = 'ALERT';

  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.getUniqueAlert();
  }

  getUniqueAlert(): void {
    this.dashboardService.getUniqueAlert().subscribe((alert => {
      this.alert = alert.title?.length ? alert : null;
      this.idAlert = alert.id;
      this.initNewsForm();
    }),
      () => this.snackbarService.show('Sorry, something went wrong', 'error')
    );
  }

  initNewsForm(): void {
    this.alertForm = this.formBuilder.group({
      title: [this.alert?.title ?? null, [Validators.required]],
      message: [this.alert?.message ?? null, [Validators.required]],
      category: [this.ALERT],
      startsAt: [this.alert?.startsAt ?? null, [Validators.required]],
      endsAt: [this.alert?.endsAt ?? null],
      published: [this.alert?.published?? true ] ,
      deactivateSite: [this.alert?.deactivateSite === true]
    });
  }

  submitAlert(): void {
    if (this.alertForm.value.endsAt === null) this.alertForm.removeControl('endsAt');
    const form = this.alertForm.getRawValue() as IAlertAdmin;
    this.addOrUpdate(form);
  }

  addOrUpdate(formValue: IAlertAdmin): void {
    this.dashboardService.updateNews(formValue, this.idAlert).subscribe((data) => {
      this.setSearchServiceNews();
      this.alert = data;
      this.snackbarService.show('Update successfully', 'success')
    },
    (err) => {
      if (err.status === 422) {
        this.snackbarService.show('Start date must be earlier than end date', 'error');
      } else {
        this.snackbarService.show('Sorry, something went wrong', 'error');
      }
    }
    );
  }

  setSearchServiceNews(): void {
    this.searchService.getNews().subscribe(
      (news) => this.searchService.setNews(news)
    );
  }

}
