import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IDataSources } from 'src/app/core/interfaces/data-source.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
@Component({
  selector: 'app-add-data-source',
  templateUrl: './add-data-source.component.html',
  styleUrls: ['./add-data-source.component.scss']
})
export class AddDataSourceComponent implements OnInit {
  dataSource!: IDataSources | null;
  dataSourceForm!: FormGroup;
  editForm: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const dataSourceId = paramMap.get('id');
          if (dataSourceId) {
            return this.dashboardService.getgetDataSource(dataSourceId);
          }
          this.dataSource = null;
          this.initDataSourceForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.editForm = true;
          this.dataSource = data;
          this.initDataSourceForm();
          this.dataSourceForm.controls.technicalId.disable();
          this.dataSourceForm.controls.refreshDate.disable();
        }
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  initDataSourceForm(): void {
    this.dataSourceForm = this.formBuilder.group({
      name: [
        this.dataSource?.name ?? null, [Validators.required]
      ],
      refreshDate: [
        this.dataSource?.refreshDate ?? null
      ],
      technicalId: [this.dataSource?.id ?? ""
      ],

      enabled: [this.dataSource?.enabled === false
      ]
    });
  }

  submitForm(): void {
    const enabled = this.dataSourceForm.controls.enabled.value ? false : true;
    this.dataSourceForm.removeControl('technicalId')
    if (!this.editForm) {
      this.dataSourceForm.removeControl('refreshDate');
      const form = {
        ...this.dataSourceForm.getRawValue(), enabled,
      } as IDataSources;
      this.addOrUpdate(form);
    }
    else
 {     let form = {
        ...this.dataSourceForm.getRawValue(), enabled
      };
      this.addOrUpdate(form);}
  }

  addOrUpdate(formValue: IDataSources): void {
    if (this.dataSource) {
      this.dashboardService.updateDataSource(formValue, this.dataSource.id).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['dataSources'] } }]);
        this.snackbarService.show('Update successfully', 'success')
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    } else {
      this.dashboardService.addDataSource(formValue).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['dataSources'] } }]);
        this.snackbarService.show('Create successfully', 'success');
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }
}
