import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAttachmentResponse, IReport } from 'src/app/core/interfaces/report.interface';
import { API_URLS } from 'src/environments/environment';

@Injectable()
export class ReportDetailsService {

  constructor(
    private http: HttpClient,
  ) { }

  getReport(reportId: string): Observable<IReport> {
    return this.http.get<IReport>(`${API_URLS.REPORTS}/${reportId}`);
  }

  addToFavorite(reportId: number): Observable<IReport> {
    return this.http.get<IReport>(`${API_URLS.REPORTS}/${reportId}/favorite`);
  }

  removeFromFavorite(reportId: number): Observable<IReport> {
    return this.http.get<IReport>(`${API_URLS.REPORTS}/${reportId}/unfavorite`);
  }

  exportPoFile(reportId: number, format: string, powerbiToken: string, state: string): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'x-powerbi-token': powerbiToken
      })
    };

    return this.http.get<Blob>(`${API_URLS.REPORTS}/${reportId}/powerbi/export?format=${format}&state=${state}`, httpOptions);
  }

  exportFile(reportId: number): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${API_URLS.REPORTS}/${reportId}/file/download`, httpOptions);
  }

  downloadAttachment(reportId: number, attachmentId: number): Observable<IAttachmentResponse> {
    return this.http.get<IAttachmentResponse>(`${API_URLS.REPORTS}/${reportId}/attachments/${attachmentId}/download`);
  }
}
