export interface INewsAdmin {
  title: string;
  message: string;
  category: string;
  deactivateSite: boolean,
  published: boolean,
  pinned: boolean,
  id: number,
  startsAt: string,
  endsAt: string,
  createdAt: string,
  updatedAt: string
}
export enum newsTypeEnum {
  NEWS = 'NEWS',
  INCIDENT = 'INCIDENT'
}

export interface IAlertAdmin{
  title: string;
  message: string;
  category: string;
  deactivateSite: boolean,
  published: boolean,
  id: number,
  startsAt: string,
  endsAt: string,
  createdAt: string,
  updatedAt: string
}
