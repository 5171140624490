<div class="container-fluid" *ngIf="connectWithEmail">
  <div class="top-bar-connect-as row">
      <div class="connect-as-data align-items-center col-md-6 d-flex pb-2 pt-2">
        <p>{{'HEADER.CONNECT_AS.MSG' | translate }}</p>
        <div class="profile-pic pt-1 font-size-16">
          {{ setAvatarText() | uppercase }}
      </div>
      <div class="profile-name">
        <p>{{authService?.getCurrentUser()?.firstName?? "-"}} {{authService?.getCurrentUser()?.lastName?? "-"}}</p>
      </div>
      </div>
      <div class="connect-as-action col-md-6 text-right">
        <button (click)="disconnectAs()">{{'HEADER.CONNECT_AS.DISCONNECT' | translate}}
        <span class="icon-close"></span></button>
      </div>
  </div>
</div>
<div class="header-container" id="header-container"
  [ngClass]="themeService.isDarkMode ? 'bg-main-dark-color' : 'bg-main-light-color'">
  <div class="navbar" [ngClass]="{
    'main-dark-mode': themeService.isDarkMode,
    'main-light-mode': !themeService.isDarkMode,
    'expand-filters': isExpanded
  }">
    <div class="navbar-wrapper" id="navbar-wrapper">
      <div class="logo-container ms-2 pointer mt-2" [routerLink]="['/home']">
        <img class="d-none d-lg-block" src="assets/images/logo.svg" alt="pap-logo" id="logo" width="212" height="33" />
        <img class="d-lg-none" src="assets/images/logo-mobile.svg" alt="pap-logo" />
      </div>
      <form class="search-container d-none d-lg-flex" id="search-container" (ngSubmit)="onSubmit()" #searchForm="ngForm"
        autocomplete="off">
        <div class="d-flex flex-column search-input position-relative" appClickOutside
          (clickOutside)="closeSearchResults()">
          <div attr.aria-label="icon search" id="icon-search" class="icon icon-16 icon-search mr-1"></div>
          <input [ngClass]="themeService.isDarkMode ? 'bg-search-main-dark' : 'bg-search-main-light'"
            class="search-input helvetica-regular font-size-16" name="search-term" id="searchTerm" type="search"
            (click)="displayFilters()" [(ngModel)]="searchTerm" (ngModelChange)="onChangeSearchTerm()"
            autocomplete="off" />
          <ng-container *ngIf="displayResults">
            <div class="autocomplete-container d-flex flex-column">
              <div class="d-flex justify-content-end pe-3">
                <a class="font-size-14 trenda-regular">{{hitsFounded}} {{'HEADER.FILTERS.RESULTS' | translate}}</a>
                <span class="mx-2 font-size-14">-</span>
                <a class="font-size-14 pointer trenda-regular text-uppercase text-decoration-underline"
                  (click)="onSubmit()">{{ "HOME.SECTION.SEE_ALL" | translate }}</a>
              </div>
              <ul attr.aria-label="search result" class="list-group list-group-flush w-100">
                <div *ngFor="let hit of hits" class="d-flex justify-content-between align-items-center tag-hovered"
                  [ngClass]="{'pointer': hit.visibility_facet === visibilityEnum.AVAILABLE} ">
                  <li [id]="hit?.name" class="list-group-item pointer font-size-14" (click)="onOptionSelected(hit)" [ngClass]="{'disabled-hit': hit.visibility_facet === visibilityEnum.UNAVAILABLE}">
                    <div class="d-flex align-items-center">
                      <div class="icon icon-30 me-3 pointer"
                        [ngClass]="hit.type_facet === 'REPORT' ? 'icon-report' : 'icon-dashboard'"></div>
                      <label class="pointer">{{hit?.code | uppercase}} <span *ngIf="hit?.code">-</span>
                        {{hit?.name}}</label>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </ng-container>
          <ng-container *ngIf="isExpanded">
            <div class="filters-containers d-none d-lg-flex" id="filters-containers">
              <div attr.aria-label="icon filter" id="icon-filters" class="icon icon-16 icon-filters"></div>
              <ng-container *ngFor="let filter of filters; let i = index">
                <a (click)="addFilter(filter, i)" attr.aria-label="filter option" [id]="'filter-option-' + i">
                  <span [ngClass]="filter.selected ? 'badge-filter-light': 'badge-filter-dark'"
                    class="badge-filter font-size-11 px-3 ms-2 py-1">
                    {{ filter.name | translate }}
                  </span>
                </a>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </form>
      <div class="profile-container" id="profile-container">
      <!-- <div class="d-none d-lg-flex me-2">
        <button class="btn ms-4 p-3 mx-2 btn-outline-none rounded-circle" type="button" role="button" attr.aria-label="add button" [ngClass]="
            themeService.isDarkMode ? 'btn-navbar-main-dark': 'btn-navbar-main-light'" id="btn-add">
          <div class="icon icon-18 icon-add"></div>
        </button>
      </div> -->
        <div class="d-lg-flex dropdown news-dropdown position-relative" [ngClass]="{'news-flag-active':searchService.news?.total}">
          <div *ngIf="searchService.news?.total" class="news-notification position-absolute d-none d-lg-block"></div>
          <button class= "btn-imp" onclick="window.location.href='https://app.snowflake.com/loreal/hrdp_prod/#/streamlit-apps/HRDP_SDDS_NP_DB.BTDP_DS_C1_H05_EMPLOYMENT_INFORMATION_EU_NP.ZAEZ36E__GTSBH8V?ref=snowsight_shared';">
          Impersonate
          </button>
          <div class="space"></div>
          <button class="btn p-3 me-2 btn-outline-none" type="button" id="newsDropdown"
            [attr.data-bs-toggle]="searchService.news?.total ? 'dropdown' : ''" aria-expanded="false" role="button"
            [ngClass]="
            themeService.isDarkMode
              ? 'btn-navbar-main-dark'
              : 'btn-navbar-main-light'
          " attr.aria-label="news button" id="btn-news">
            <div class="icon icon-18 icon-news"></div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="newsDropdown"
            [ngClass]="themeService.isDarkMode ? 'must-know-dropdown-dark' : 'must-know-dropdown-light'">
            <div class="news-dropdown-content overflow-auto">
              <li *ngFor="let item of searchService.news?.newsAndIncidents">
                <div class="news-item">
                  <div class="header d-flex align-items-center">
                    <div class="icon icon-26 icon-world-wide" [ngClass]="{
                      'icon-world-wide': item.category === newsTypeEnum.NEWS,
                      'icon-alert-dark': item.category === newsTypeEnum.INCIDENT
                    }"></div>
                    <div class="new-title">
                      <span class="title">{{item.title}}</span>
                      <span class="sub-title"> - {{ item.startsAt | dateFormat: 'short'}} {{ item.startsAt | date:
                        'hh:mm'}} </span>
                    </div>
                  </div>
                  <p class="content">
                    {{item.message}}
                  </p>
                </div>
              </li>
            </div>
          </ul>
        </div>
      <!-- <div class="d-none d-lg-flex me-2">
        <button class="btn p-3 btn-outline-none me-2" type="button" role="button" [ngClass]="
            themeService.isDarkMode ? 'btn-navbar-main-dark' : 'btn-navbar-main-light'" attr.aria-label="icon button" id="btn-add-2">
          <div class="icon icon-18 icon-add"></div>
        </button>
      </div> -->

        <!-- <div class="px-3 font-size-16">
           <a class="profile-text d-none d-lg-block" id="glossary" attr.aria-label="glossary">{{'HEADER.GLOSSARY' | translate }}</a>
        </div> -->
      <div class="ps-3 me-lg-4 font-size-16 dropdown order-1 order-lg-0 info-menu">
        <!-- <a class="dropdown-toggle font-size-16 position-relative d-none d-lg-block" id="mustKnowDropdown" role="button" data-bs-toggle="dropdown"
          aria-expanded="false" attr.aria-label="must know">
          {{'HEADER.MUST_KNOW' | translate }}
        </a> -->
        <!-- <a class="dropdown-toggle font-size-16 position-relative d-lg-none menu-mobile " role="button" data-bs-toggle="dropdown" >
          <img alt="menu button" src="assets/images/mobile-burger-icon.svg"/>
        </a> -->
        <ul class="dropdown-menu must-know-dropdown-menu font-size-16 must-know-dropdown px-2 py-3" attr.aria-labelledby="mustKnowDropdown"
          [ngClass]="themeService.isDarkMode ? 'must-know-dropdown-dark' : 'must-know-dropdown-light'">
          <li class="d-lg-none">
            <a class="dropdown-item px-4 mb-2 mt-1 d-flex align-items-center">
              <div class="item-icon me-2"><span class="icon icon-18 icon-add"></span></div>
              Create a report / dashboard (trad)
            </a>
          </li>
          <li class="d-lg-none">
            <a class="dropdown-item px-4 mb-2 mt-1 d-flex align-items-center">
              <div class="item-icon me-2"><span class="icon icon-18 icon-add"></span></div>
              Raise a request
            </a>
          </li>
          <li class="d-lg-none">
            <hr class="dropdown-divider mx-2 my-2 my-lg-4">
          </li>
          <li class="d-lg-none"><a id="must-know-header" class="dropdown-item px-4 mb-2 mt-1">Glossary</a></li>
          <li class="d-lg-none">
            <hr class="dropdown-divider mx-2 my-2 my-lg-4">
          </li>
          <li><a id="must-know-header" class="dropdown-item px-4 mb-2 mt-1">{{'HEADER.GETTING STARTED' | translate }}</a></li>
          <li><a id="tips" class="dropdown-item px-4 mb-2">{{'HEADER.TIPS' | translate }}</a></li>
          <li><a id="data-privacy" class="dropdown-item px-4">{{'HEADER.DATA_PRIVACY' | translate }}</a></li>
          <li>
            <hr class="dropdown-divider mx-2 my-2 my-lg-4">
          </li>
          <li><a id="data-survival-tools" class="dropdown-item px-4 mb-2">{{'HEADER.DATA_SURVIVAL_TOOLS' | translate }}</a></li>
          <li><a id="data-upskilling" class="dropdown-item px-4 mb-2">{{'HEADER.DATA_UPSKILLING' | translate }}</a></li>
          <li><a id="faq" class="dropdown-item px-4 mb-2">{{'HEADER.FAQ' | translate }}</a></li>
        </ul>
      </div>
        <div class="toggle dropdown ms-lg-3" id="toggle">
          <div class="toggle-ball pt-1 font-size-16 " id="infoDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img [src]="authService?.imageToShow" alt="image user" class="photo-user"
              *ngIf="authService?.imageToShow; else noImageFound" />
            <ng-template #noImageFound>
              {{ setAvatarText() | uppercase }}
            </ng-template>
          </div>
          <ul class="dropdown-menu dropdown-info-user font-size-16 p-4" attr.aria-labelledby="infoDropdown"
            [ngClass]="themeService.isDarkMode ? 'must-know-dropdown-dark' : 'must-know-dropdown-light'">
            <li><a
                class="email-user trenda-regular text-start text-truncate ms-3">{{authService?.getCurrentUser()?.firstName
                | titlecase}} {{authService?.getCurrentUser()?.lastName | uppercase}}</a></li>
            <li><span class="text-about ms-3 text-truncate">{{authService?.getCurrentUser()?.email}}</span></li>
            <li>
              <hr class="dropdown-divider mx-2 my-4">
            </li>
            <li>
              <h4 class="user-setting ms-3 trenda-bold font-size-19 mb-3"
                [ngClass]="themeService.isDarkMode ? 'text-main-dark-color' : 'text-main-light-color'">
                {{'HEADER.SETTING.TITLE' | translate }}</h4>
            </li>
            <li>
              <span class="user-language text-about mb-2 ms-3">{{'HEADER.SETTING.LANGUAGE' | translate }}</span>
            </li>
            <li class="d-flex ms-3 mt-3">
              <ng-container *ngFor="let language of languages" >
                <a (click)="switchLang(language)" attr.aria-label="english"><span
                  class="font-size-16 me-3 px-3 rounded-main-dark"
                  [ngClass]="this.currentLanguage ===  language ? 'current-language' : ''">{{language | uppercase}}</span></a>
              </ng-container>
            </li>
            <li>
              <hr class="dropdown-divider mx-2 mt-5 mb-4">
            </li>
            <li class="d-none d-lg-flex">
              <button id="btn-statistics" type="button" class="btn btn-statistic font-size-13 trenda-regular my-3 w-100"
                role="button" (click)="goToCarolStatistics()"
                [ngClass]="themeService.isDarkMode ? 'main-dark-mode' : 'main-light-mode border'"
                attr.aria-label="statistic button">
                {{'HEADER.SETTING.ACCESS_STATISTICS' | translate }}
              </button>
            </li>
            <li *appHasAuthorization="true" class="d-none d-lg-flex">
              <a id="btn-administration" (click)="navigateAdmin()"
                class="btn w-100 my-2 btn-statistic font-size-13 trenda-regular  d-flex align-items-center justify-content-center"
                [ngClass]="themeService.isDarkMode ? 'main-dark-mode' : 'main-light-mode border'"
                attr.aria-label="administration button">
                <div class="icon icon-13 icon-settings me-4"></div>
                <span>{{'HEADER.SETTING.ADMINISTRATION' | translate }}</span>
              </a>
            </li>
            <li>
              <a id="btn-logout" (click)="logout()"
                class="btn w-100 mt-3 btn-statistic rounded font-size-16 trenda-regular fw-bold d-flex align-items-center justify-content-center"
                [ngClass]="themeService.isDarkMode ? 'main-dark-mode' : 'main-light-mode border'"
                attr.aria-label="logout button">
                <div class="icon icon-13 icon-logout me-2"></div>
                <span>{{'HEADER.SETTING.LOG_OUT' | translate }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-lg-none ">
    <div class="header-search-mobile row mt-4 pb-4">
      <div class="col-12 px-5">
        <form class="d-lg-none " (ngSubmit)="onSubmit()" #searchForm="ngForm" autocomplete="off">
          <div class="d-flex flex-column search-input position-relative " appClickOutside
            (clickOutside)="closeSearchResults()">
            <div attr.aria-label="icon search" id="icon-search-mobile"
              class="icon icon-18 icon-search position-absolute mt-3 ms-3"></div>
            <input [ngClass]="themeService.isDarkMode ? 'bg-search-main-dark' : 'bg-search-main-light'"
              class="search-input-mobile helvetica-regular font-size-16 ps-5 " name="search-term-mobile"
              id="searchTerm-mobile" type="search" (click)="displayFilters()" [(ngModel)]="searchTerm"
              (ngModelChange)="onChangeSearchTerm()" autocomplete="off" />
            <ng-container *ngIf="isExpanded">
              <div class="filters-containers-mobile d-flex mt-3" id="filters-containers-mobile">
                <div attr.aria-label="icon filter" id="icon-filters-mobile" class="icon icon-16 icon-filters"></div>
                <ng-container *ngFor="let filter of filters; let i = index">
                  <a (click)="addFilter(filter, i)" attr.aria-label="filter option" [id]="'filter-option-mobile-' + i">
                    <span [ngClass]="filter.selected ? 'badge-filter-light': 'badge-filter-dark'"
                      class="badge-filter font-size-11 px-3 ms-2 py-1">
                      {{ filter.name | translate }}
                    </span>
                  </a>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="displayResults">
              <div class="autocomplete-container-mobile d-flex flex-column">
                <div class="d-flex justify-content-end pe-3">
                  <a class="font-size-14 trenda-regular">{{hitsFounded}} {{'HEADER.FILTERS.RESULTS' | translate}}</a>
                  <span class="mx-2 font-size-14">-</span>
                  <a class="font-size-14 pointer trenda-regular text-uppercase text-decoration-underline"
                    (click)="onSubmit()">{{ "HOME.SECTION.SEE_ALL" | translate }}</a>
                </div>
                <ul attr.aria-label="search result" class="list-group-mobile list-group-flush w-100">
                  <div *ngFor="let hit of hits" class="d-flex justify-content-between align-items-center tag-hovered"
                    [ngClass]="{'pointer': hit.visibility_facet === visibilityEnum.AVAILABLE} ">
                    <li [id]="hit?.name" class="list-group-item pointer font-size-14" (click)="onOptionSelected(hit)"
                      [ngClass]="{'disabled-hit': hit.visibility_facet === visibilityEnum.UNAVAILABLE}">
                      <div class="d-flex align-items-center">
                        <div class="icon icon-30 me-3 pointer"
                          [ngClass]="hit.type_facet === 'REPORT' ? 'icon-report' : 'icon-dashboard'"></div>
                        <label class="pointer">{{hit?.code | uppercase}} <span *ngIf="hit?.code">-</span>
                          {{hit?.name}}</label>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
