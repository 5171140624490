import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EMPTY} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {VisibilityEnum} from 'src/app/core/enum/visibility.enum';
import {IGroup, ISectionAdmin} from 'src/app/core/interfaces/section.interface';
import {DashboardService} from 'src/app/pages/admin/dashboard/dashboard.service';
import {SnackbarService} from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss']
})
export class AddSectionComponent implements OnInit {

  section!: ISectionAdmin | null;
  groups: IGroup[] = [];
  sectionForm!: FormGroup;
  visibilityEnum = VisibilityEnum;
  sectionList: string[] = []

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.init()
    this.setGroups();
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const sectionId = paramMap.get('id');
          if (sectionId) {
            return this.dashboardService.getSection(sectionId);
          }
          this.section = null;
          this.initSectionForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
          if (data) {
            this.section = data;
            this.initSectionForm();
          }
        },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );

    this.dashboardService
      .getSections(0, 50, 'order', false)
      .subscribe((data) => {
          this.sectionList = data.items.map((section_: ISectionAdmin) =>
            section_.name
          );
        },
      );
  }

  setGroups(): void {
    this.dashboardService.getGroups().subscribe((data) => {
        this.groups = data.items;
      },
      () => this.snackbarService.show('Sorry, something went wrong', 'error'));
  }

  submitSection(): void {
    const form = this.sectionForm.getRawValue() as ISectionAdmin;
    this.addOrUpdate(form);
  }

  initSectionForm(): void {
    this.sectionForm = this.formBuilder.group({
      order: [this.section?.order ?? null],
      name: [
        this.section?.name ?? null, [Validators.required]
      ],
      code: [
        this.section?.code ?? null, [Validators.required]
      ],
      groups: [this.section?.groups?.map(group => group?.id) ?? null],
      visibility: [this.section?.visibility ?? "AVAILABLE"]
    });

    this.sectionForm?.controls.order.disable();
    this.sectionForm?.controls.order.updateValueAndValidity();
  }

  addOrUpdate(formValue: ISectionAdmin): void {
    if (this.section) {
      this.dashboardService.updateSection(formValue, this.section.id).subscribe(() => {
          this.router.navigate(['/admin', {outlets: {admin: ['sections']}}]);
          this.snackbarService.show('Update successfully', 'success')
        },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    } else {
      if (this.sectionList.includes(formValue.name)) {
        this.snackbarService.show(`Sorry, ${formValue.name} already exist`, 'Constraint error')
        return
      }
      this.dashboardService.addSection(formValue).subscribe(() => {
          this.router.navigate(['/admin', {outlets: {admin: ['sections']}}]);
          this.snackbarService.show('Create successfully', 'success');
        },
        () => this.snackbarService.show('Sorry, something went wrong.', 'error')
      );
    }
  }

}
