<section id="favorites-last-viewed" class="favorites-container position-relative pt-4 pb-5">
  <div class="d-flex align-items-center justify-content-between px-2 px-lg-0">
    <div class="favorites-last-viewed--toggle d-flex p-1 mb-lg-3">
      <div class="input_container pe-3 py-1 ps-3" [ngStyle]="{'background-color': option  === 'favorites' ? '#283648' : 'transparent'}">
        <input type="radio" class="btn-check" name="option" value="favorites" id="favorites" autocomplete="off" [(ngModel)]="option" (ngModelChange)="onChangeOption()">
        <div id="favorites-icon" class="icon icon-14 me-2" [ngClass]="option  === 'favorites'? 'icon-favorites-white' : 'icon-favorites'"></div>
        <label class="btn btn-transparent ps-1" [ngStyle]="{'color': option  === 'favorites' ? '#fff' : '#8C969F'}" for="favorites">{{ "HOME.FAVORITES" | translate }}</label>
      </div>
      <div class="input_container pe-3 py-1 ps-3" [ngStyle]="{'background-color': option  === 'viewed' ? '#283648' : 'transparent'}">
        <input type="radio" class="btn-check" name="option" value="viewed" id="lastViewed" autocomplete="off" [(ngModel)]="option" (ngModelChange)="onChangeOption()">
        <div  id="last-viewed-icon" class="icon icon-14 me-2" [ngClass]="option  === 'viewed'? 'icon-last-viewed-white' : 'icon-last-viewed'"></div>
        <label class="btn btn-transparent" [ngStyle]="{'color': option  === 'viewed' ? '#fff' : '#8C969F'}" for="lastViewed">{{ "HOME.LAST_VIEWED" | translate }}</label>
      </div>
    </div>
    <div class="d-flex align-items-center me-lg-5" *ngIf="seeAll">
      <div class="font-size-16 pointer trenda-regular list-display-all me-1" (click)="sellAll()">{{ "HOME.SECTION.SEE_ALL" | translate }}</div>
      <div class="icon icon-12 pointer icon-arrow icon-arrow-left"></div>
    </div>
  </div>
  <ng-container *ngFor="let item of searchService.favorites; let i = index">
    <app-sliders-list [sliderType]="sliderTypesEnum.FAVORITES" [index]="i" [section]="item"></app-sliders-list>
  </ng-container>
</section>
