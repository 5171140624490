<div class="home-container">
  <app-latest-news [latestNews]="searchService.news?.latestNews"></app-latest-news>
  <app-favorites></app-favorites>
  <ng-container *ngFor="let item of searchService.searchResults; let i = index">
    <app-sliders-list [sliderType]="sliderTypesEnum.SECTION" [index]="i" [section]="item"></app-sliders-list>
  </ng-container>
  <div class="position-relative loader-container" *ngIf="!searchService.searchResults?.length && !loader">
    <app-loader></app-loader>
  </div>
</div>
