<div class="list mb-5"
  [ngClass]="{'pt-5 pb-5': sliderType === sliderTypesEnum.SECTION, 'position-relative': hoveredChild}"
  [ngStyle]="{'background-color': index % 2 === 0 && sliderType === sliderTypesEnum.SECTION ? '#0c1c30' :
(sliderType === sliderTypesEnum.FAVORITES ? 'linear-gradient(318.54deg, rgba(255, 255, 255, 0.19) -4.27%, rgba(255, 253, 253, 0.1) 97.87%)' : '#162334' ) }">
  <div class="d-flex align-items-center" *ngIf="sliderType === sliderTypesEnum.SECTION">
    <div class="list-title  me-4">{{section?.name | uppercase}}</div>
    <ng-container *ngIf="(section?.type !== 'DASHBOARD') && (section?.type !== 'REPORT') else found">
      <ng-container *ngIf="(section?.visibility !== visibilityEnum.UNAVAILABLE); else disabled_content">
        <div class="font-size-16 pointer trenda-regular list-display-all me-1" (click)="sellAll(section?.name )">
          {{ "HOME.SECTION.SEE_ALL" | translate }}</div>
        <div class="icon icon-12 pointer icon-arrow icon-arrow-left"></div>
      </ng-container>
      <ng-template  #disabled_content>
        <div class="font-size-16">
          {{ "HOME.SECTION.DISABLED" | translate }} </div>
      </ng-template >
    </ng-container>
    <ng-template #found>
      <div class="font-size-16 pointer trenda-regular list-display-all me-1">{{section.found}}
        {{'SEE_ALL.RESULTS' | translate }} </div>
    </ng-template>
  </div>
  <div
    [ngClass]="section.visibility !== visibilityEnum.UNAVAILABLE ? 'wrapper-items' : ' wrapper-items disabled-section'">
    <div class="slider-arrow left" (click)="handleClick('left')" [ngStyle]="{'display': !hasPrev ? 'none' : 'block' }">
      <div class="icon icon-40 icon-arrow icon-arrow-left"></div>
    </div>
    <div class="container-list" #listRef>
      <ng-container *ngFor="let item of section?.hits; let i = index">
        <app-slider-item [ngClass]="{'disabled-section': item.visibility_facet === visibilityEnum.UNAVAILABLE}" [bgColor]="bgColor" [index]="i" [item]="item" (hoveredItem)="onHoveredItem($event)"></app-slider-item>
      </ng-container>
    </div>
    <div class=" slider-arrow right" (click)="handleClick('right')"
      [ngStyle]="{'display': !hasNext ? 'none' : 'block' }">
      <div class="icon icon-40 icon-arrow"></div>
    </div>
  </div>
</div>
