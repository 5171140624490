import { Component } from '@angular/core';
import { SearchService } from 'src/app/core/services/search.service';
import { SliderTypesEnum } from 'src/app/features/sliders-list/slider.enum';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {
  readonly sliderTypesEnum = SliderTypesEnum;

  constructor(
    public searchService: SearchService)
    { }

}
