import { ITag } from './../../../core/interfaces/report.interface';
import { map } from 'rxjs/operators';
import { PlatformItem, DataSource } from './../../../core/interfaces/platform.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IReport, reportSourceTypeEnum } from 'src/app/core/interfaces/report.interface';
import { IGroup, ISectionAdmin } from 'src/app/core/interfaces/section.interface';
import { API_URLS, GRAPH_GROUP_AD, GRAPH_USERS } from 'src/environments/environment';
import { ITagAdmin } from 'src/app/core/interfaces/tag.interface';
import { IDataSources } from 'src/app/core/interfaces/data-source.interface';
import { IGraphUser, IUser } from 'src/app/core/interfaces/user.interface';
import { IGraphWorkspace, IGroupForm, IPlateformGroup, IWorkspace } from 'src/app/core/interfaces/group';
import { IAlertAdmin, INewsAdmin } from 'src/app/core/interfaces/news-alert.interface';
import { IELearning } from 'src/app/core/interfaces/e-learning.interface';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';

@Injectable()
export class DashboardService {
  displayReports = false;
  sectionId!: number;
  sectionName!: string;
  constructor(
    private http: HttpClient,
  ) { }

  getSections(skip: number, limit: number, sort?: string, is_desc?: boolean): Observable<{ items: ISectionAdmin[], total: number }> {
    return this.http.get<{ items: ISectionAdmin[], total: number }>(`${API_URLS.SECTIONS}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}`);
  }

  getUsers(skip?: number, limit?: number, sort?: string, is_desc?: boolean, search?: string): Observable<{ items: IUser[], total: number }> {
    if (skip || limit || sort || is_desc || search) {
      return this.http.get<{ items: IUser[], total: number }>(`${API_URLS.USERS}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}&search=${search}`);
    }
    return this.http.get<{ items: IUser[], total: number }>(`${API_URLS.USERS}`);
  }

  getUser(id: string): Observable<IUser> {
    return this.http.get<IUser>(`${API_URLS.USERS}/${id}`);
  }

  addUser(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(`${API_URLS.USERS}`, user);
  }

  updateUser(user: IUser, id: number): Observable<IUser> {
    return this.http.put<IUser>(`${API_URLS.USERS}/${id}`, user);
  }

  deleteUser(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.USERS}/${id}`);
  }

  getReportsBySectionId(id: number, skip: number, limit: number, sort?: string, is_desc?: boolean): Observable<{ items: IReport[], total: number }> {
    return this.http.get<{ items: IReport[], total: number }>(`${API_URLS.SECTIONS}/${id}/reports?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}`);
  }

  getSection(id: string): Observable<ISectionAdmin> {
    return this.http.get<ISectionAdmin>(`${API_URLS.SECTIONS}/${id}`);
  }

  addSection(section: ISectionAdmin): Observable<ISectionAdmin> {
    return this.http.post<ISectionAdmin>(`${API_URLS.SECTIONS}`, section);
  }

  updateSection(section: ISectionAdmin, id: number): Observable<ISectionAdmin> {
    return this.http.put<ISectionAdmin>(`${API_URLS.SECTIONS}/${id}`, section);
  }

  deleteSection(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.SECTIONS}/${id}`);
  }

  reorderSections(sections: { id: number, order: number }[]): Observable<ISectionAdmin[]> {
    return this.http.post<ISectionAdmin[]>(`${API_URLS.SECTIONS}/reorder`, { sections });
  }

  getGroups(skip?: number, limit?: number): Observable<{ items: IGroup[], total: number }> {
    if (limit) {
      return this.http.get<{ items: IGroup[], total: number }>(`${API_URLS.GROUPS}?skip=${skip}&limit=${limit}`);
    }
    return this.http.get<{ items: IGroup[], total: number }>(`${API_URLS.GROUPS}`);
  }

  getGroupType(sap_sf: string): Observable<IPlateformGroup[]> {
    return this.http.get<IGroupForm[]>(`${API_URLS.PLATFORM_FOLDERS}/${sap_sf}/groups`).pipe(
      map(resp => resp.map(elem => ({
        name: elem.name,
        sourceId: elem.id,
        type: elem.platform
      })))
    )
  }

  getGroup(id: string): Observable<IGroup> {
    return this.http.get<IGroup>(`${API_URLS.GROUPS}/${id}`);
  }

  updateGroup(dataSource: IGroup, id: number): Observable<IGroup> {
    return this.http.put<IGroup>(`${API_URLS.GROUPS}/${id}`, dataSource);
  }

  addGroup(section: IGroup): Observable<IGroup> {
    return this.http.post<IGroup>(`${API_URLS.GROUPS}`, section);
  }

  deleteGroup(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.GROUPS}/${id}`);
  }

  getPlatoformFolders(sourceType: reportSourceTypeEnum): Observable<PlatformItem[]> {
    return this.http.get<PlatformItem[]>(`${API_URLS.PLATFORM_FOLDERS}/${sourceType}/folders`);
  }

  getPlatoformSubFolders(sourceType: reportSourceTypeEnum, id: string): Observable<PlatformItem[]> {
    return this.http.get<PlatformItem[]>(`${API_URLS.PLATFORM_FOLDERS}/${sourceType}/folders/${id}/children`);
  }

  getDatasources(): Observable<DataSource[]> {
    return this.http.get<DataSource[]>(`${API_URLS.DATASOURCES}`).pipe(
      map(result => (result as any).items)
    );
  }

  getAllDatasources(skip: number, limit?: number, sort?: string, is_desc?: boolean): Observable<{ items: IDataSources[], total: number }> {
    return this.http.get<{ items: IDataSources[], total: number }>(`${API_URLS.DATASOURCES}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}`);
  }

  getgetDataSource(id: string): Observable<IDataSources> {
    return this.http.get<IDataSources>(`${API_URLS.DATASOURCES}/${id}`);
  }

  updateDataSource(dataSource: IDataSources, id: number): Observable<IDataSources> {
    return this.http.put<IDataSources>(`${API_URLS.DATASOURCES}/${id}`, dataSource);
  }

  addDataSource(dataSource: IDataSources): Observable<IDataSources> {
    return this.http.post<IDataSources>(`${API_URLS.DATASOURCES}`, dataSource);
  }

  deleteDataSource(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.DATASOURCES}/${id}`);
  }

  getTags(skip: number, limit?: number, sort?: string, is_desc?: boolean): Observable<{ items: ITag[], total: number }> {
    if (!limit) {
      return this.http.get<{ items: ITag[], total: number }>(`${API_URLS.TAGS}?skip=${skip}`);
    }
    if (sort && is_desc) {
      return this.http.get<{ items: ITag[], total: number }>(`${API_URLS.TAGS}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}`);
    }
    return this.http.get<{ items: ITag[], total: number }>(`${API_URLS.TAGS}?skip=${skip}&limit=${limit}`);
  }

  getTag(id: string): Observable<ITagAdmin> {
    return this.http.get<ITagAdmin>(`${API_URLS.TAGS}/${id}`);
  }

  addTag(tag: ITagAdmin): Observable<ITagAdmin> {
    return this.http.post<ITagAdmin>(`${API_URLS.TAGS}`, tag);
  }

  updateTag(tag: ITagAdmin, id: number): Observable<ITagAdmin> {
    return this.http.put<ITagAdmin>(`${API_URLS.TAGS}/${id}`, tag);
  }

  deleteTag(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.TAGS}/${id}`);
  }

  saveReport(body: any): Observable<IReport> {
    return this.http.post<IReport>(`${API_URLS.SAVE_REPORTS}`, body);
  }

  updateReport(body: any, id: number): Observable<IReport> {
    return this.http.put<IReport>(`${API_URLS.REPORTS}/${id}`, body);
  }

  getReportById(id: string): Observable<any> {
    return this.http.get(`${API_URLS.REPORTS}/${id}`).pipe(
      map(result => {
        (result as any).tags = (result as any).tags.map((tag: any) => { return tag.id });
        (result as any).datasources = (result as any).datasources.map((datasource: any) => { return datasource.id });
        (result as any).groups = (result as any).groups.map((group: any) => { return group.id });
        return result;
      })
    );
  }

  deleteReport(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.REPORTS}/${id}`);
  }

  getNews(skip: number, limit: number, sort?: string, is_desc?: boolean, without_alert = true): Observable<{ items: INewsAdmin[], total: number }> {
    return this.http.get<{ items: INewsAdmin[], total: number }>(`${API_URLS.NEWS}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}&without_alert=${without_alert}`);
  }

  getNew(id: string): Observable<INewsAdmin> {
    return this.http.get<INewsAdmin>(`${API_URLS.NEWS}/${id}`);
  }

  addNews(news: INewsAdmin): Observable<INewsAdmin> {
    return this.http.post<INewsAdmin>(`${API_URLS.NEWS}`, news);
  }

  addAlert(alert: IAlertAdmin): Observable<IAlertAdmin> {
    return this.http.post<IAlertAdmin>(`${API_URLS.NEWS}`, alert);
  }

  updateNews(body: any, id: number): Observable<INewsAdmin> {
    return this.http.put<INewsAdmin>(`${API_URLS.NEWS}/${id}`, body);
  }

  deleteNews(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.NEWS}/${id}`);
  }

  getUniqueAlert(): Observable<IAlertAdmin> {
    return this.http.get<IAlertAdmin>(`${API_URLS.NEWS}/unique/alert`);
  }

  getELearnings(skip: number, limit?: number, sort?: string, is_desc?: boolean): Observable<{ items: IELearning[], total: number }> {
    if (!limit) {
      return this.http.get<{ items: IELearning[], total: number }>(`${API_URLS.ELEARNINGS}?skip=${skip}`);
    }
    if (sort && is_desc) {
      return this.http.get<{ items: IELearning[], total: number }>(`${API_URLS.ELEARNINGS}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}`);
    }
    return this.http.get<{ items: IELearning[], total: number }>(`${API_URLS.ELEARNINGS}?skip=${skip}&limit=${limit}`);
  }

  getELearning(id: string): Observable<IELearning> {
    return this.http.get<IELearning>(`${API_URLS.ELEARNINGS}/${id}`);
  }

  addELearning(eLearning: IELearning): Observable<IELearning> {
    return this.http.post<IELearning>(`${API_URLS.ELEARNINGS}`, eLearning);
  }

  updateELearning(eLearning: IELearning, id: number): Observable<IELearning> {
    return this.http.put<IELearning>(`${API_URLS.ELEARNINGS}/${id}`, eLearning);
  }

  deleteELearning(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.ELEARNINGS}/${id}`);
  }

  getTranslations(skip: number, limit?: number, sort?: string, is_desc?: boolean): Observable<{ items: ITranslation[], total: number }> {
    return this.http.get<{ items: ITranslation[], total: number }>(`${API_URLS.LANGUAGES}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}`);
  }

  addTranslation(eLearning: ITranslation): Observable<ITranslation> {
    return this.http.post<ITranslation>(`${API_URLS.LANGUAGES}`, eLearning);
  }

  updateTranslation(eLearning: ITranslation, id: number): Observable<ITranslation> {
    return this.http.put<ITranslation>(`${API_URLS.LANGUAGES}/${id}`, eLearning);
  }

  deleteTranslation(id: number): Observable<void> {
    return this.http.delete<void>(`${API_URLS.LANGUAGES}/${id}`);
  }

  getTranslation(id: string): Observable<ITranslation> {
    return this.http.get<ITranslation>(`${API_URLS.LANGUAGES}/${id}`);
  }

  downloadTranslationTemplate(): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${API_URLS.LANGUAGES}/template/download`, httpOptions);
  }

  validateUserByAD(value: string):Observable<IGraphUser> {
    return this.http.get<IGraphUser>(`${GRAPH_USERS}?$search="mail:${value}" OR "userPrincipalName:${value}"`, {headers: new HttpHeaders({
      'ConsistencyLevel': 'eventual'
    })});
  }

  getWorkspaces(skip: number, limit: number, sort?: string, is_desc?: boolean, search?:string): Observable<{ items: IWorkspace[], total: number }> {
    return this.http.get<{ items: IWorkspace[], total: number }>(`${API_URLS.WORKSPACES}?skip=${skip}&limit=${limit}&sort=${sort}&is_desc=${is_desc}&search=${search}`);
  }

  getWorkspace(id: string): Observable<IWorkspace> {
    return this.http.get<IWorkspace>(`${API_URLS.WORKSPACES}/${id}`);
  }

  updateWorkspace(workspace: IWorkspace, id: number): Observable<IWorkspace> {
    return this.http.put<IWorkspace>(`${API_URLS.WORKSPACES}/${id}`, workspace);
  }

  getAdGroup(value: string):Observable<IGraphWorkspace> {
    return this.http.get<IGraphWorkspace>(`${GRAPH_GROUP_AD}?$search="displayName:${value}"`, {headers: new HttpHeaders({
      'ConsistencyLevel': 'eventual'
    })});
  }
}
