import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { VisibilityEnum } from 'src/app/core/enum/visibility.enum';
import { IResult } from 'src/app/core/interfaces/section.interface';
import { SearchService } from 'src/app/core/services/search.service';
import { SliderTypesEnum } from './slider.enum';

@Component({
  selector: 'app-sliders-list',
  templateUrl: './sliders-list.component.html',
  styleUrls: ['./sliders-list.component.scss']
})
export class SlidersListComponent implements OnChanges, OnInit {
  readonly sliderTypesEnum = SliderTypesEnum;
  readonly visibilityEnum = VisibilityEnum;

  @ViewChild('listRef', {static: false}) listRef!: ElementRef;
  @Input() section!: IResult;
  @Input() index!: number;
  @Input() sliderType!: SliderTypesEnum;

  slideNumber = 0
  clickLimit = window.innerWidth / 310;
  hasPrev = false;
  hasNext = true;
  countElements = 10;
  bgColor = '#283648';
  hoveredChild = false;
  
  constructor(
    private router: Router,
    private searchService: SearchService,
  ) { }
  
  ngOnInit(): void {
    this.handleSlideStyle();
    this.hasPrev = this.slideNumber > 0  ;
    this.hasNext = ((this.slideNumber + this.clickLimit) < this.countElements);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.section?.currentValue){
      this.countElements = changes.section?.currentValue?.hits?.length;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    event.target.innerWidth;
    this.clickLimit = window.innerWidth / 310;
    this.hasNext = ((this.slideNumber + this.clickLimit) < this.countElements);
  }

  handleClick(direction: string): void{
    // 310 => the width of the card (290px) + padding right 10px
    // 50 => margin-left of the parent "container-list" class
    let distance = this.listRef.nativeElement.getBoundingClientRect().x - 50;
    if (direction === "left" && this.slideNumber > 0) {
      this.slideNumber= this.slideNumber - 1;
      this.listRef.nativeElement.style.transform = `translateX(${310 + distance}px)`;
    }
    if (direction === "right" && this.slideNumber < this.countElements - this.clickLimit) {
      this.slideNumber = this.slideNumber + 1;
      this.listRef.nativeElement.style.transform = `translateX(${-310 + distance}px)`;
    }
    this.hasPrev = this.slideNumber > 0  ;
    this.hasNext = ((this.slideNumber + this.clickLimit) < this.countElements);

  }

  handleSlideStyle(): void{
    switch (this.sliderType) {
      case SliderTypesEnum.SECTION:
        this.bgColor = '#283648';
      return;
      case SliderTypesEnum.FAVORITES:
        this.bgColor = '#0C1C30';
      return;

      default:
        break;
    }
  }

  sellAll(name: string | undefined): void{
    this.searchService.selectedSection = name || '';
    this.router.navigate(['/user/see-all']);
  }

  onHoveredItem(isHovered: boolean): void{
    this.hoveredChild = isHovered;
  }
}
