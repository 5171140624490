import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { SearchService } from 'src/app/core/services/search.service';
import { SliderTypesEnum } from '../sliders-list/slider.enum';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent {
  readonly sliderTypesEnum = SliderTypesEnum;

  option: 'favorites' | 'viewed' = 'favorites';
  seeAll = true;

  constructor(
    public searchService: SearchService,
    private router: Router,
    private authService: AuthService,
    ) { }

  onChangeOption(): void{
    const currentUser = this.authService.getCurrentUser();
    if(this.option === 'viewed'){
      this.seeAll = false;
      this.searchService.getLastViewed().pipe(
        concatMap( (result) => {
          let filters: {id:number, order: number}[] = result as any;
          return this.searchService.getLastViewedFromTypesense(currentUser,filters);
        })
      )
      .subscribe((items) => {
        this.searchService.favorites = items
      })
    } else {
      this.seeAll = true;
      this.searchService.getMyFavorites(currentUser, 12).subscribe(
        (data) => {
          this.searchService.favorites = data?.results?.map((section: any) => ({
            found: section?.found,
            hits: this.searchService.mapHitToDocument(section.hits, currentUser),
            name: section.hits[0]?.document?.section_name_facet as string,
            out_of: section.out_of
          }));
        }
      );
    }
  }
  sellAll(): void{
    this.searchService.selectedSection = this.option === 'favorites' ? 'FAVORITES' : 'LAST VIEWED';
    this.router.navigate(['/user/see-all']);
  }
}
