<ng-container *ngIf="!isIframe; else teams_context">
  <ng-container *ngIf="hasLayout; else without_layout">
    <div id="app-container" *ngIf="authService?.isAuthenticated | async; else loading">
      <ng-container *ngIf="!displayTeamsContent; else loading">
        <app-header (logoutClicked)="logout()"></app-header>
        <div class="app-content" [ngStyle]="{'height': connectWithEmail ? 'calc(100vh - 11rem)': 'calc(100vh - 7rem)'}"
          *ngIf="authService.loggedIn">
          <div class="main-content" [ngClass]="themeService.isDarkMode ? 'main-dark-mode' : 'main-light-mode'">
            <router-outlet *ngIf="!isIframe" (deactivate)="onDeactivate()"></router-outlet>
          </div>
          <div class="footer w-100 position-relative">
            <app-footer></app-footer>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-template #loading>
      <div class="loader-container d-flex justify-content-center align-items-center">
        <ng-container *ngFor="let i of [1,2,3]">
          <div class="spinner-grow me-3 text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
      </div>
    </ng-template>
    <app-snackbar></app-snackbar>
  </ng-container>
  <ng-template #without_layout>
    <router-outlet></router-outlet>
  </ng-template>
</ng-container>

<ng-template #teams_context>
  <div class="d-flex flex-column align-items-center justify-content-center container pap-logo-login p-5"
    *ngIf="!isLoggedIntoAD else favorites_content">
    <div class="row">
      <div class="d-flex align-items-center justify-content-center">
        <img src="assets/images/logo-animated.gif" alt="Sign into PAP" width="360" height="100" />
      </div>
    </div>
    <div class="row">
      <div class="centered text-center">
        <p class="authentication-required">
          <span class="required-auth">Authentication Required</span>
        </p>
        <p class="font-size-16">Please sign in using the button below to be able to view your favorites .</p>
        <Button class="btn btn-dark rounded-pill" (click)="loginHandler()">Connect</Button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #favorites_content>
  <div class="favorites-section p-5">
    <div class="favorites-results d-flex align-items-center justify-content-start flex-wrap mx-auto"
      [style.height]="!results ? '100%' : 'unset'">
      <ng-container *ngFor="let item of results?.hits; let i = index">
        <app-slider-item-popover [ngClass]="{'disabled': item.visibility_facet === visibilityEnum.UNAVAILABLE}"
          [bgColor]="'#FFFFFF'" [index]="i" [item]="item" [isTeams]="true" class="mb-4"></app-slider-item-popover>
      </ng-container>
      <div class="d-flex justify-content-center align-items-center w-100" *ngIf="!results"
        [style.background-color]="'#f3f4f6'">
        <ng-container *ngFor="let i of [1,2,3]">
          <div class="spinner-grow me-3 text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
