import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-teams',
  templateUrl: './terms-teams.component.html',
  styleUrls: ['./terms-teams.component.scss']
})
export class TermsTeamsComponent  {

}
