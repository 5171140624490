import { Injectable } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";

@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  constructor() {}

  handle(params: MissingTranslationHandlerParams): string {
    return '[MISSING]' + params.key;
  }

}
