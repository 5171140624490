import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { IUploadSession } from '../interfaces/upload-session.interface';
import { Observable, Observer } from 'rxjs';
import { API_URLS, environment, } from 'src/environments/environment';
import { IFile } from '../interfaces/file.interface';

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    constructor(private http: HttpClient) { }

    uploadFile(file: File): Observable<IFile> {
        const o = new Observable<IFile>(observer => {
            this.addFile(file).subscribe(f => {
                const session = {
                    file: file,
                    fileId: f.id,
                    fileSize: file.size, // saved, because file.size can be expensive
                    chunkSize: environment.uploadChunkSize,
                    successfullyUploaded: 0,
                    uploadProgress: 0,
                    uploadStarted: true,
                    uploadComplete: false,
                    uploadPaused: false,
                    uploadError: true,
                    uploadUri: f.uploadUrl,
                    retries: 0
                } as IUploadSession

                this.uploadChunk(session, 0, observer)
            }, err => {
                console.error(err);
                observer.error(err);
            });
        })
        return o
    }

    addFile(file: File): Observable<IFile> {
        const fileToAdd = {
            name: file.name,
            size: file.size,
            type: file.type
        } as IFile;
        return this.http.post<IFile>(`${API_URLS.FILES}`, fileToAdd);
    }

    setFileUploaded(fileId: number): Observable<IFile> {
        return this.http.get<IFile>(`${API_URLS.FILES}/${fileId}/uploaded`);
    }

    uploadComplete(fileId: number, observer: Observer<IFile>): void {
        this.setFileUploaded(fileId).subscribe(f => {
            observer.next(f);
        }, err => {
            console.error(err);
            observer.error(err);
        });
    }

    uploadChunk(session: IUploadSession, start: number, observer: Observer<IFile>): void {
        // calculate the end of the byte range
        let end = this.getChunkEnd(start, session);

        // call http put on the session uri
        // append the blob of the file chunk as the body
        session.currentRequest = this.http
            .put(session.uploadUri, session.file.slice(start, end), {
                // let the observable respond with all events, so that it can report on the upload progress
                observe: 'events',
                reportProgress: true,
                // set the content range header to let gcp know which part of the file is sent
                headers: {
                    'Content-Range': `bytes ${start}-${end - 1}/${session.fileSize}`,
                },
            })
            .subscribe(
                // because we are observing 'events' the response is an HttpEvent
                (res: HttpEvent<any>) => {
                    // If the response is an HttpEvent and  the status code is 200 the file upload has complete in its entirety.
                    if (res.type === HttpEventType.Response && res.status === 200) {
                        session.uploadComplete = true;
                        this.uploadComplete(session.fileId, observer)
                    }
                    // If the type is upload progress, we can use it for showing a pretty progress bar.
                    else if (res.type === HttpEventType.UploadProgress) {
                        session.uploadProgress = start + res.loaded;
                    }
                },
                // GCP responds with 308, if a chunk was uploaded, but the file is incomplete.
                // For the angular http module any non 2xx code is an error. Therefore we need to use the error callback to continue.
                async (res: HttpResponse<object>) => {
                    // the range header contains the confirmation by google which bytes have actually been written to the bucket
                    const range = res.headers.get('range');

                    if (res.status === 308 && range) {
                        end = +range.substring(range.indexOf('-') + 1, range.length);
                        session.successfullyUploaded = end;
                        session.retries = 0;

                        // Check, whether the upload is paused, otherwise make a recursive call to upload the next chunk.
                        if (!session.uploadPaused) {
                            this.uploadChunk(session, end, observer);
                        }
                    } else {
                        // if the code is not 308, handle the error
                        console.warn(res);
                        session.retries++;
                        if (session.retries > environment.uploadMaxRetries) {
                            session.uploadError = true;
                            observer.error('Max retries reached')
                        } else {
                            if (!session.uploadPaused) {
                                // retry same request after exp backoff
                                console.warn('retrying...');
                                setTimeout(() => {
                                    this.uploadChunk(session, end, observer);
                                }, 2 ** session.retries * 10);
                            }
                        }
                    }
                }
            );
    }

    getChunkEnd(start: number, session: IUploadSession): number {
        if (start + session.chunkSize > session.fileSize) {
            return session.fileSize;
        } else {
            return start + session.chunkSize;
        }
    }
}
