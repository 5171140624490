

<section class="section-reports-container d-flex flex-column p-5">
  <div class="section-reports-header my-3">
    <h4 class="mb-3">REPORT ORGANIZATION</h4>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <h6 class="text-decoration-underline pointer me-2" (click)="navigateToSections()">ALL SECTIONS</h6>
        <h6>/ {{sectionName | uppercase}}</h6>
      </div>
      <div class="d-flex align-items-center">
        <button class="btn-action btn px-4 py-2" type="button" role="button" attr.aria-label="button add report" id="btn-add-report" (click)="addReport()">ADD A REPORT</button>
      </div>
    </div>
  </div>
  <div class="section-reports-content mt-4">
    <div class="d-flex justify-content-end">
      <span class="result-text">Showing {{skip + 1}}-{{end}} of {{total}} items</span>
    </div>
    <div class="table-section-reports pb-5">
      <table class="table table-hover table-striped mt-3">
          <thead>
        <tr>
          <ng-container *ngFor="let header of tableHeader;">
            <th scope="col" class="text-center pointer" (click)="sortTable(header)">
              <div class="d-flex justify-content-between align-items-center mx-2">
                <div> {{ header.label}}</div>
                <i class="icon icon-14 ml-2" [ngClass]="header?.direction ? 'icon-polygon-down' : 'icon-polygon'"></i>
              </div>
            </th>
          </ng-container>
          <th scope="col" class="text-center">PIN IT</th>
          <th scope="col" class="text-center">Visibility</th>
          <th scope="col" class="text-center">ACTIONS</th>
        </tr>
      </thead>
      <tbody class="position-relative">
        <tr *ngFor="let report of reports; index as rowIndex">
          <th class="text-center" scope="row">{{report.code}}</th>
          <td class="text-truncate">
            <div class="d-flex align-items-center">
              <div class="icon-document icon icon-30 me-2" [ngClass]="report.type === REPORT ? 'icon-report' : 'icon-dashboard'"></div>
              <div class="name-report text-truncate">{{report?.name}}</div>
            </div>
          </td>
          <td>{{report?.sourceType}}</td>
          <td>
            <div class="d-flex justify-content-center">
              <i class="icon icon-16 icon-checkmark-black" *ngIf="report.pinned"></i>
            </div>
          </td>
          <td class="text-center">{{report.visibility}}</td>
          <td>
            <div class="actions d-flex justify-content-center">
              <div class="icon icon-25 icon-edit pointer" (click)="editReport(report.id)" tooltip="Edit" placement="bottom" containerClass="tooltipClass"></div>
              <div role="button" class="icon icon-25 icon-delete ms-2 pointer" (click)="deleteReport(report.id)" tooltip="Delete" placement="bottom" containerClass="tooltipClass"></div>
            </div>
          </td>
        </tr>
      </tbody>
      </table>
      <div class="d-flex justify-content-end mt-3" *ngIf="reports?.length">
          <pagination
            [directionLinks]="false"
            [totalItems]="total"
            [itemsPerPage]="limit"
            [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)">
          </pagination>
      </div>
    </div>
  </div>
</section>
