import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { delay, mergeMap, takeUntil } from 'rxjs/operators';
import { sourceTypeEnum } from 'src/app/core/enum/report.enum';
import { IDocument } from 'src/app/core/interfaces/section.interface';

@Component({
  selector: 'app-slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss']
})
export class SliderItemComponent implements OnInit, OnDestroy {

  readonly REPORT = 'REPORT';
  readonly SAP = 'SAP';
  readonly sourceTypes = sourceTypeEnum;

  @Input() index: number = 0;
  @Input() item!: IDocument;
  @Input() bgColor!: string;
  @Input() toBeHovered = true;
  @Input() isTeams = false;
  @Output() hoveredItem: EventEmitter<boolean> = new EventEmitter();

  _mouseEnterStream: Subject<boolean> = new Subject();
  _mouseLeaveStream: Subject<boolean> = new Subject();
  isHovered = false;
  subscription = new Subscription();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if(window.innerWidth > 991){
      this.subscription.add(this._mouseEnterStream.pipe(mergeMap((e) => {
        return of(e).pipe(
          delay(1000),
          takeUntil(this._mouseLeaveStream)
        );
      })).subscribe((e)=> {
        this.isHovered = e;
        this.hoveredItem.emit(this.isHovered);
      }));

      this.subscription.add(this._mouseLeaveStream.subscribe((e) => {
        this.isHovered = e;
        this.hoveredItem.emit(this.isHovered);
      }));
    }
  }

  onMouseEnter(hovered: boolean): void {
    if(this.toBeHovered){
      this._mouseEnterStream.next(hovered);
    }
  }

  onMouseLeave(hovered: boolean): void{
    if(this.toBeHovered){
      this._mouseLeaveStream.next(hovered);
    }
  }

  navigate(): void{
    if(this.item?.source_type_facet === 'LINK'){
      window.open(this.item?.url || '', "_blank");
    } else {
      if(this.isTeams){
        const url = this.router.createUrlTree(['/user/report/', this.item?.id]);
        window.open(url.toString() || '', "_blank");
      } else {
        this.router.navigate(['/user/report/', this.item?.id]);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
