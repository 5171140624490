import { Component, OnInit } from '@angular/core';
import { headerDataSource } from 'src/app/core/constants/admin';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { IDataSources } from 'src/app/core/interfaces/data-source.interface';
import { ITableHeader } from 'src/app/core/interfaces/section.interface';
@Component({
  selector: 'app-all-data-sources',
  templateUrl: './all-data-sources.component.html',
  styleUrls: ['./all-data-sources.component.scss']
})
export class AllDataSourcesComponent implements OnInit {
  tableHeader: ITableHeader[] = headerDataSource;
  total = 0;
  skip = 0;
  end = 0;
  currentPage = 1;
  dataSources: IDataSources[] = [];
  readonly DEFAULT_SORT = 'id';
  sort = this.DEFAULT_SORT;
  is_desc = false;
  readonly limit = 10;

  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllDataSources()
  }

  getAllDataSources(): void {
    this.dashboardService
      .getAllDatasources(this.skip, this.limit, this.sort, this.is_desc)
      .subscribe((data) => {
        this.total = data.total;
        this.dataSources = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getAllDataSources();
  }

  addDataSource(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['dataSource'] } }]);
  }

  editDataSource(dataSourceId: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['dataSource', dataSourceId] } }]);
  }

  deleteDataSource(id: number): void {
    if (confirm("Are you sure to delete")) {
      this.dashboardService.deleteDataSource(id).subscribe(() => {
        this.snackbarService.show('Delete successfully', 'success');
        return this.getAllDataSources();
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  sortTable(header: ITableHeader): void {
    this.tableHeader.forEach((item) => {
      if (item.id === header.id) {
        item.direction = !item.direction;
        this.sort = header.name;
        this.is_desc = header.direction;
      } else {
        item.direction = false;
      }
    });
    this.getAllDataSources();
  }
}
