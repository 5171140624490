export enum sourceTypeEnum {
  POWER_BI = 'POWER_BI',
  SAP_BO = 'SAP_WEBI',
  FILE = 'FILE',
  LINK = 'LINK'
}

export enum exportTypeEnum {
  PDF = 'PDF',
  PNG = 'PNG',
  PPT = 'PPTX',
  EXCEL = 'XLSX',
  NONE = 'NONE'
}
