import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EMPTY} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {IPlateformGroup} from 'src/app/core/interfaces/group';
import {PlatformGroupTypeEnum} from 'src/app/core/interfaces/platform.interface';
import {IGroup} from 'src/app/core/interfaces/section.interface';
import {IUser} from 'src/app/core/interfaces/user.interface';
import {DashboardService} from 'src/app/pages/admin/dashboard/dashboard.service';
import {SnackbarService} from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
  groupForm!: FormGroup;
  isBOload: boolean = false;
  isSFload: boolean = false
  group!: IGroup | null;
  users: IUser[] = [];
  skip = 0;
  groupSF: IPlateformGroup[] = [];
  groupBO: IPlateformGroup[] = [];
  readonly limit = 10;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getGroupsWithTypeSF();
    this.getGroupsWithTypeBO();
    this.getAllUser();
    this.init();
  }

  get isResourceLoad(): boolean {
    return this.isSFload && this.isBOload
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const groupId = paramMap.get('id');
          if (groupId) {
            return this.dashboardService.getGroup(groupId);
          }
          this.group = null;
          this.initGroupForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
          if (data) {
            this.group = data;
            this.initGroupForm();
          }
        },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  initGroupForm(): void {
    const groupSfIds = this.group?.sourcegroups.filter(grpType => grpType.type === "SAP_SF").map(grpName => grpName.sourceId);
    const groupBoIds = this.group?.sourcegroups.filter(grpType => grpType.type === "SAP_BO").map(grpName => grpName.sourceId);
    this.groupForm = this.formBuilder.group({
      name: [
        this.group?.name ?? null, [Validators.required]
      ],
      sourcegroupsSF: [groupSfIds ?? []],
      sourcegroupsBO: [groupBoIds ?? []],
      users: [this.group?.users.map(user => user.id) ?? []],
      enabled: [this.group?.enabled === false]
    });
  }

  submitSourceGroupe(): void {
    const enabled = !this.groupForm.controls.enabled.value;
    const users = this.users.filter(user => this.groupForm.controls.users.value.includes(user.id))
      .map(el => {
        return {email: el.email, isAdmin: el.isAdmin, enabled: true}
      })
    const sourceGroup = [...this.groupForm.controls.sourcegroupsSF.value, ...this.groupForm.controls.sourcegroupsBO.value]
    const allSourceGroups = [...this.groupSF, ...this.groupBO];
    const filterSourceGroup = allSourceGroups.filter(el => sourceGroup.includes(el.sourceId))
      .map(el => {
        return {name: el.name, sourceId: el.sourceId, type: el.type}
      })
    filterSourceGroup.forEach(el => {
      if (el.type === PlatformGroupTypeEnum.SAP_SF) {
        el.type = "SAP_SF"
      } else {
        el.type = "SAP_BO"
      }
    });
    this.groupForm.removeControl('sourcegroupsSF');
    this.groupForm.removeControl('sourcegroupsBO');
    const form = {...this.groupForm.getRawValue(), sourcegroups: filterSourceGroup, users, enabled} as IGroup;
    this.save(form);
  }

  save(formValue: IGroup): void {
    if (this.group) {
      this.dashboardService.updateGroup(formValue, this.group.id).subscribe(() => {
          this.router.navigate(['/admin', {outlets: {admin: ['groups']}}]);
          this.snackbarService.show('Update successfully', 'success')
        },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    } else {
      this.dashboardService.addGroup(formValue).subscribe(() => {
          this.router.navigate(['/admin', {outlets: {admin: ['groups']}}]);
          this.snackbarService.show('Create successfully', 'success');
        },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  getGroupsWithTypeSF(groupType = PlatformGroupTypeEnum.SAP_SF): void {
    this.dashboardService.getGroupType(groupType).subscribe((groupSf => {
        this.groupSF = groupSf;
      }),
      () => this.snackbarService.show('Sorry, something went wrong', 'error')
    ).add(() => this.isSFload = true);
  }

  getGroupsWithTypeBO(groupType = PlatformGroupTypeEnum.SAP_BO): void {
    this.dashboardService.getGroupType(groupType).subscribe((groupsBO => {
      this.groupBO = groupsBO;
    })).add(() => this.isBOload = true);
  }

  getAllUser(): void {
    this.dashboardService
      .getUsers()
      .subscribe((data) => {
          this.users = data.items;
        },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }
}
