import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'dateFormat',
  pure: false
})
export class DateFormatPipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }

  transform(value: any, args?: string): any {
    const currentLanguage = this.authService.environmentSettings.language.code;

    if (!(value instanceof moment)) {
      value = moment(value);
    }
    if (args === 'mini' || args === 'short' || args === 'medium' || args === 'long') {
      const pattern = this.authService.environmentSettings.language.dateFormat[args]
      return value
        .locale(currentLanguage)
        .format(pattern);
    }

    return value.locale(currentLanguage).format(args);
  }
}
