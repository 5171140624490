import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  isDarkBg = true;
  subscription!: Subscription;
  adminWorkplace = false;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('report') || event.url.includes('see')) {
            this.isDarkBg = false;
          } else {
            this.isDarkBg = true;
          }
        }
        if (event instanceof NavigationEnd) {
          if (event.url.includes('admin')) {
            this.adminWorkplace = true;
          } else {
            this.adminWorkplace = false;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
