import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlatformItem } from 'src/app/core/interfaces/platform.interface';
import { IReport, reportSourceTypeEnum } from 'src/app/core/interfaces/report.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  currentItem?: PlatformItem;

  newSourceType = new BehaviorSubject<reportSourceTypeEnum>("" as reportSourceTypeEnum);
  sharedSourceType = this.newSourceType.asObservable();

  newPlatformItem = new BehaviorSubject<PlatformItem[]>([] as PlatformItem[]);
  sharedPlatformItem = this.newPlatformItem.asObservable();

  newChildrenSelected = new BehaviorSubject<PlatformItem[]>([] as PlatformItem[]);
  sharedChildrenSelected = this.newChildrenSelected.asObservable()


  newFolderPathItems = new BehaviorSubject<PlatformItem[]>([] as PlatformItem[]);
  sharedFolderPathItems = this.newFolderPathItems.asObservable()

  newStep = new BehaviorSubject(1)
  sharedStep = this.newStep.asObservable()

  newReport = new BehaviorSubject<IReport | null>({} as IReport)
  sharedReport = this.newReport.asObservable()


  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
  ) { }

  nextSourceType(sourceType: reportSourceTypeEnum): void {
    this.newSourceType.next(sourceType)
    this.getFolders(true)
  }

  nextStep(step: number): void {
    this.newStep.next(step);
  }

  getFolders(initPlatformItem?: Boolean): void {
    if (initPlatformItem) {
      this.newChildrenSelected.next([])
      this.newPlatformItem.next([])
    }

    this.newFolderPathItems.next([])
    this.newPlatformItem.getValue().map(item => item.selected = false);

    if (this.newSourceType.getValue() === reportSourceTypeEnum.POWER_BI || this.newSourceType.getValue() === reportSourceTypeEnum.SAP_BO) {
      this.dashboardService.getPlatoformFolders(this.newSourceType.getValue()).subscribe(
        data => {
          this.newPlatformItem.next(data);

        }, () => this.snackbarService.show('Sorry, something went wrong', 'error')
      )
    }
  }

  selectItem(item: PlatformItem): void {
    if (item.type === "REPORT") {
      item.selected = !item.selected;
      const found = this.newChildrenSelected.getValue().find((el: PlatformItem) => el.id == item.id);

      this.newChildrenSelected.next(found?.id ? this.newChildrenSelected.getValue().filter((el: PlatformItem) => el.id !== found.id) : [...this.newChildrenSelected.getValue(), item])
    }
    else {
      let newFolderPathItems = this.newFolderPathItems.getValue()
      newFolderPathItems.push(item)
      this.newFolderPathItems.next(newFolderPathItems);


      this.getPlatformSubFolders(item.id);
    }
  }

  getPlatformSubFolders(itemId: string): void {
    this.dashboardService.getPlatoformSubFolders(this.newSourceType.getValue(), itemId).subscribe(items => {
      items.map((item) => {
        let exist = this.newChildrenSelected.getValue().find(x => x.id == item.id)
        item.selected = exist ? true : false;
      });
      this.newPlatformItem.next(items)
    },
      () => {
        this.snackbarService.show('Sorry, something went wrong', 'error');
      });
  }

  backToFolder(platformItem: PlatformItem, index: number): void {
    if (index === this.newFolderPathItems.getValue().length - 1) {
      return;
    }
    this.newFolderPathItems.getValue().length = index + 1;
    this.getPlatformSubFolders(platformItem.id);
  }



}
