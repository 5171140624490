<section class="alert-container d-flex flex-column p-5">
  <div class="alert-header my-3">
    <h4 class="mb-3">ALERT & NEWS</h4>
    <div class="d-flex justify-content-between">
      <h6>{{alert?.title ? 'EDIT AN ALERT' : 'Create An Alert'}}</h6>
    </div>
  </div>
  <div class="alert-content mt-4" *ngIf="alertForm">
    <form [formGroup]="alertForm" class="g-3">
      <div class="row mb-4">
        <div class="col-12 d-flex flex-row">
          <div class="d-flex align-items-center">
            <input type="radio" id="published" class="mx-1" [value]="true" formControlName="published">
            <label class="form-label mb-0 me-5" for="published">Enable</label>
          </div>
          <div class="d-flex align-items-center">
            <input type="radio" id="published-disable" class="mx-1" [value]="false" formControlName="published">
            <label class="form-label mb-0" for="published-disable">Disabled</label>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="title" class="form-label">Title
            <span class="red-star">*</span>
          </label>
          <input class="form-control" formControlName="title" id="title"
            [ngClass]="{'is-invalid': alertForm.controls.title.invalid && alertForm.controls.title.touched}">
          <div *ngIf="alertForm.controls.title.errors?.required && alertForm.controls.title.touched"
            class="invalid-feedback">
            an alert needs a title!
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="message" class="form-label">Message
            <span class="red-star">*</span></label>
          <textarea class="form-control" formControlName="message" id="message" rows="3"
            [ngClass]="{'is-invalid': alertForm.controls.message.invalid && alertForm.controls.message.touched}"></textarea>
          <div *ngIf="alertForm.controls.message.errors?.required && alertForm.controls.message.touched"
            class="invalid-feedback">
            try to give some context to your users, please?
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-3">
          <label for="startsAt" class="form-label">Start Date
            <span class="red-star">*</span>
          </label>
          <input type="datetime-local" class="form-control" formControlName="startsAt" id="startsAt">
        </div>
        <div class="col-3">
          <label for="endsAt" class="form-label">End date</label>
          <input type="datetime-local" class="form-control" formControlName="endsAt" id="endsAt">
        </div>
      </div>
      <div class="row mt-4">
        <label class="form-label">Deactivate site ?</label>
        <div class="form-check">
          <input class="form-check-input ms-1" type="checkbox" formControlName="deactivateSite"
            id="deactivateSite-checkbox" aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="deactivateSite-checkbox">
            Deactivate site
          </label>
        </div>
      </div>
      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button submit alert"
          id="btn-submit-alert" [disabled]="alertForm.invalid" (click)="submitAlert()">
          {{alert?.title ? 'Edit An Alert' : 'Create An Alert'}}
        </button>
      </div>
    </form>
  </div>
</section>
