import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITagAdmin } from 'src/app/core/interfaces/tag.interface';
import { mergeMap } from 'rxjs/operators';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { EMPTY } from 'rxjs';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss']
})
export class AddTagComponent implements OnInit {
  tag!: ITagAdmin | null;
  tagForm!: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const tagId = paramMap.get('id');
          if (tagId) {
            return this.dashboardService.getTag(tagId);
          }
          this.tag = null;
          this.initTagForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.tag = data;
          this.initTagForm();
        }
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  initTagForm(): void {
    this.tagForm = this.formBuilder.group({
      name: [
        this.tag?.name ?? null, [Validators.required]
      ],
      comment: [
        this.tag?.comment ?? "",],

      enabled: [this.tag?.enabled === false]
    });

  }

  submitTag(): void {
    const enabled = this.tagForm.controls.enabled.value ? false : true;
    const form = { ...this.tagForm.getRawValue(), enabled } as ITagAdmin;
    this.addOrUpdate(form);
  }

  addOrUpdate(formValue: ITagAdmin): void {
    if (this.tag) {
      this.dashboardService.updateTag(formValue, this.tag.id).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['tags'] } }]);
        this.snackbarService.show('Update successfully', 'success')
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    } else {
      this.dashboardService.addTag(formValue).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['tags'] } }]);
        this.snackbarService.show('Create successfully', 'success');
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }
}
