import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { concatMap } from 'rxjs/operators';
import { headerTags } from 'src/app/core/constants/admin';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { ITableTagsHeader, ITagAdmin, } from './../../core/interfaces/tag.interface';

@Component({
  selector: 'app-all-tags',
  templateUrl: './all-tags.component.html',
  styleUrls: ['./all-tags.component.scss']
})
export class AllTagsComponent implements OnInit {
  readonly DEFAULT_SORT = 'code';
  readonly limit = 10;

  tableHeader: ITableTagsHeader[] = headerTags;
  tags: ITagAdmin[] = [];
  currentPage = 1;
  total = 0;
  skip = 0;
  end = 0;


  sort = this.DEFAULT_SORT;
  is_desc = false;
  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllTags();
  }

  getAllTags(): void {
    this.dashboardService
      .getTags(this.skip, this.limit, this.sort, this.is_desc)
      .subscribe((data) => {
        this.total = data.total;
        this.tags = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getAllTags();
  }

  addTags(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['tag'] } }]);
  }

  editTag(tagId: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['tag', tagId] } }]);
  }

  deleteTag(tagId: number): void {
    if (confirm("Are you sure to delete")) {
      this.dashboardService.deleteTag(tagId).pipe(
        concatMap(() => {
          this.snackbarService.show('Delete successfully', 'success');
          return this.dashboardService.getTags(this.skip, this.limit, this.sort, this.is_desc);
        })
      ).subscribe((data) => {
        this.total = data.total;
        this.tags = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }
  sortTable(header: ITableTagsHeader): void {
    this.tableHeader.forEach((item) => {
      if (item.id === header.id) {
        item.direction = !item.direction;
        this.sort = header.name;
        this.is_desc = header.direction;
      } else {
        item.direction = false;
      }
    });
    this.getAllTags();
  }

}
