import { Component, OnInit } from '@angular/core';
import { ISectionAdmin, ITableHeader } from 'src/app/core/interfaces/section.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { headerSections } from 'src/app/core/constants/admin';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-all-sections',
  templateUrl: './all-sections.component.html',
  styleUrls: ['./all-sections.component.scss'],
})
export class AllSectionsComponent implements OnInit {
  readonly DEFAULT_SORT = 'order';
  readonly limit = 50;
  readonly skip = 0;
  readonly sort = 'order';
  readonly is_desc = false;

  sections: ISectionAdmin[] = [];
  tableHeader: ITableHeader[] = headerSections;

  constructor(
    public dashboardService: DashboardService,
    private router: Router,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.getAllSections();
  }

  getAllSections(): void {
    this.dashboardService
      .getSections(this.skip, this.limit, this.sort, this.is_desc)
      .subscribe((data) => {
        this.sections = data.items.map((section: ISectionAdmin) => ({
          ...section,
          disabled: section.visibility === 'UNAVAILABLE',
        }));
      },
      () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  navigateToReports(section: ISectionAdmin): void {
    this.dashboardService.sectionId = section.id;
    this.dashboardService.sectionName = section.name;
    this.dashboardService.displayReports = !this.dashboardService.displayReports;
  }

  onAllSectionClicked(): void {
    this.dashboardService.displayReports= !this.dashboardService.displayReports;
  }

  onDrop(event: CdkDragDrop<string[], string[], any>): void {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
    this.sections.forEach((section, idx) => {
      section.order = idx + 1;
    });
    const orderList = this.sections.map(section => ({id: section.id, order: section.order}));
    this.orderSections(orderList);

  }

  addSection(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['section'] } }]);
  }

  editSection(sectionId: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['section', sectionId] } }]);
  }

  deleteSection(sectionId: number): void {
    if(confirm("Are you sure to delete")) {
      this.dashboardService.deleteSection(sectionId).pipe(
        concatMap( () => {
          this.snackbarService.show('Delete successfully', 'success');
          return this.dashboardService.getSections(this.skip, this.limit, this.sort, this.is_desc);
        })
      ).subscribe((data) => {
        this.sections = data.items.map((section: ISectionAdmin) => ({
          ...section,
          disabled: section.visibility === 'UNAVAILABLE',
        }));
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  orderSections(sections: {id: number, order: number}[]): void {
    this.dashboardService
    .reorderSections(sections)
    .subscribe((data) => {
      this.sections = data?.map((section: ISectionAdmin) => ({
        ...section,
        disabled: section.visibility === 'UNAVAILABLE',
      }));
    },
    () => this.snackbarService.show('Sorry, something went wrong', 'error')
    );
  }

  addReport(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['report'] } }]);
  }
}
