<section class="see-all-container">
  <div class="see-all-header d-flex flex-column justify-content-between">
    <div class="back-button d-flex align-items-center pointer mb-5" (click)="goBack()">
      <div attr.aria-label="icon back" id="icon-back" class="icon icon-12 icon-back me-2"></div>
      <label class="font-size-13 trenda-regular pointer">
        {{ "REPORT_DETAILS.HEADER.RETURN" | translate }}
      </label>
    </div>
    <div class="px-3 font-size-16 dropdown">
      <a class="dropdown-toggle selected-section fw-bold font-size-24 d-flex align-items-center" id="all-sections" role="button" data-bs-toggle="dropdown"
        aria-expanded="false" attr.aria-label="must know">
        <ng-container [ngSwitch]="defaultSection">
          <ng-container *ngSwitchCase="'FAVORITES'">
            <div class="d-flex align-items-center">
              <div id="favorites-icon" class="icon icon-28 me-2 icon-favorite-circle"></div>
              <div>{{ "HOME.FAVORITES" | translate | uppercase}}</div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'LAST VIEWED'">
            {{ "HOME.LAST_VIEWED" | translate | uppercase}}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{defaultSection | uppercase}}
          </ng-container>
        </ng-container>
      </a>
      <ul class="dropdown-menu font-size-16 must-know-dropdown must-know-dropdown-dark px-2 py-3">
        <ng-container *ngFor="let item of allSections; let i = index" [ngSwitch]="item">
          <li class="pointer mb-4" (click)="setSelected(item)">
            <a [id]="'section-' + i" class="dropdown-item  d-flex align-items-center mb-2 mt-1">
              <div *ngIf="i < 1" id="favorites-icon" class="icon icon-14 me-2"
              [ngClass]="{'icon-favorites': i  === 0 , 'icon-last-viewed' : i  === 1 }"></div>
              <div class="text-wrap" *ngSwitchCase="'FAVORITES'">{{ "HOME.FAVORITES" | translate | uppercase}}</div>
              <div class="text-wrap" *ngSwitchCase="'LAST VIEWED'">{{ "HOME.LAST_VIEWED" | translate | uppercase }}</div>
              <div class="text-wrap" *ngSwitchDefault>{{item | uppercase}}</div>
            </a>
          </li>
          <li *ngIf="i === 0">
            <hr class="dropdown-divider mx-2 my-4">
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="d-flex">
      <div class="filter-container ps-3 d-flex align-items-center justify-content-between" (click)="filterResult()">
        <button type="button" role="button" attr.aria-label="filter all section result" id="btn-filter" [style.color]="filterClicked ? '#70D1FD' : '#C1CAD4'" >{{'SEE_ALL.FILTER' | translate }}</button>
        <i class="icon icon-8 me-4" [ngClass]="!filterClicked ? 'icon-polygon-down' : 'icon-polygon'"></i>
      </div>
      <button *ngIf="filterClicked" class="clear-all" type="button" role="button" attr.aria-label="clear all filters" id="btn-clear-all" (click)="clearAllFilters()">{{'SEE_ALL.CLEAR_ALL' | translate }}</button>
    </div>
  </div>
  <div class="see-all-body d-flex px-3 flex-column">
    <div class="filters-options-wrapper row d-flex flex-sm-column flex-md-row align-items-sm-start align-items-md-center px-2 px-sm-5 py-4 mx-0 mx-sm-5" *ngIf="filterClicked">
      <div class="col-8 col-sm-8 col-md-4 filter-option-container mx-5 mb-3 mb-sm-0">
        <label>{{'SEE_ALL.BY_TAGS' | translate }}</label>
        <hr class="dropdown-divider">
        <div class="d-flex align-items-center flex-wrap">
          <ng-container *ngFor="let option of tagsFilters; let i = index">
            <div class="chip me-3 mb-2">
              {{option}}
              <span class="close-btn" (click)="deleteTag(i)">&times;</span>
            </div>
          </ng-container>
          <div class="option-wrapper d-flex flex-column position-relative">
            <div class="d-flex">
              <input *ngIf="displayInputTag || !tagsFilters.length" type="text" [size]="(searchTagTerm.length > 0) ? searchTagTerm.length : 12" [(ngModel)]="searchTagTerm"  (ngModelChange)="onChangeSearchTagTerm()" autocomplete="off" id="input-filter" class="input-filter mb-2 me-3" [placeholder]="'SEE_ALL.ENTER_TAG' | translate" />
              <button type="button" role="button" attr.aria-label="add filter" id="btn-add-filter" class="btn-add-filter me-2" (click)="displayTag()">+</button>
            </div>
            <ng-container *ngIf="displayTagFilters && allTags?.length">
              <ul class="result-filter-options p-0" #tagContainer>
                <ng-container *ngFor="let tag of allTags" >
                  <li class="list-group-item pointer py-2 font-size-14" (click)="onTagSelected(tag.value)">
                    <div class="d-flex align-items-center px-2 justify-content-between">
                      <label>{{tag?.value}}</label>
                      <label>{{tag?.count}}</label>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-8 col-sm-8 col-md-4 filter-option-container mx-5">
        <label>{{'SEE_ALL.BY_DATA_SOURCES' | translate }}</label>
        <hr class="dropdown-divider">
        <div class="d-flex align-items-center flex-wrap">
          <ng-container *ngFor="let option of dataSourcesFilters; let i = index">
            <div class="chip me-3 mb-2">
              {{option}}
              <span class="close-btn" (click)="deleteDataSource(i)">&times;</span>
            </div>
          </ng-container>
          <div class="option-wrapper d-flex flex-column position-relative">
            <div class="d-flex">
              <input *ngIf="displayInputDataSource || !dataSourcesFilters.length" type="text" [size]="(searchDataSourceTerm.length > 0) ? searchDataSourceTerm.length : 23" [(ngModel)]="searchDataSourceTerm"  (ngModelChange)="onChangeSearchDataSourceTerm()" autocomplete="off" id="input-filter-data-source" class="input-filter mb-2 me-3" [placeholder]="'SEE_ALL.ENTER_DATA_SOURCES' | translate" />
              <button type="button" role="button" attr.aria-label="add filter" id="btn-add-filter" class="btn-add-filter me-2" (click)="displayDataSource()">+</button>
            </div>
            <ng-container *ngIf="displayDataSourcesFilters && allDataSources?.length">
              <ul class="result-filter-options p-0" #dataSourcesContainer>
                <ng-container *ngFor="let dataSource of allDataSources" >
                  <li class="list-group-item pointer py-2 font-size-14" (click)="onDataSourceSelected(dataSource.value)">
                    <div class="d-flex align-items-center px-2 justify-content-between">
                      <label>{{dataSource?.value}}</label>
                      <label>{{dataSource?.count}}</label>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="result-count d-flex justify-content-end mt-5  mb-4 pe-5 mx-auto">
      <span class="result-text font-size-16">{{results?.found}} {{'SEE_ALL.RESULTS' | translate }} </span>
    </div>
    <div class="see-all-results position-relative d-flex align-items-center justify-content-start flex-wrap mx-auto">
      <div class="loader-container position-absolute pt-5 mt-5" *ngIf="isLoading">
        <app-loader></app-loader>
      </div>
      <ng-container *ngFor="let item of results?.hits; let i = index">
        <app-slider-item-popover  [ngClass]="{'disabled': item.visibility_facet === visibilityEnum.UNAVAILABLE}" [bgColor]="'#FFFFFF'" [index]="i" [item]="item" class="mb-4"></app-slider-item-popover>
      </ng-container>
    </div>
  </div>
</section>
