import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { headerUsers } from 'src/app/core/constants/admin';
import { ITableHeader } from 'src/app/core/interfaces/section.interface';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit {
  currentPage = 1;
  total = 0;
  skip = 0;
  end = 0;
  tableHeader: ITableHeader[] = headerUsers;
  users: IUser[] = [];
  readonly limit = 10;
  readonly DEFAULT_SORT = 'email';
  sort = this.DEFAULT_SORT;
  is_desc = false;
  isLoading = false;
  searchValue = "";

  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private router: Router,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers(): void {
    this.isLoading = true;
    this.dashboardService
      .getUsers(this.skip, this.limit, this.sort, this.is_desc, this.searchValue)
      .subscribe((data) => {
        this.total = data.total;
        this.users = data.items;
        this.isLoading = false;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  searchUsers():void {
    this.skip = 0;
    this.currentPage = 1;
    this.getAllUsers();
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getAllUsers();
  }

  editUser(userId: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['user', userId] } }]);
  }

  sortTable(header: ITableHeader): void {
    if (header.isSortable !== false) {
      this.tableHeader.forEach((item) => {
        if (item.id === header.id) {
          item.direction = !item.direction;
          this.sort = header.name;
          this.is_desc = header.direction;
        } else {
          item.direction = false;
        }
      });
      this.getAllUsers();
    }
  }

  deleteUser(id: number): void {
    if (confirm("Are you sure to delete")) {
      this.dashboardService.deleteUser(id).subscribe(() => {
        this.snackbarService.show('Delete successfully', 'success');
        return this.getAllUsers();
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  connectAs(email:string): void{
    localStorage.setItem('connectWithEmail', email);
    location.reload();
  }
}
