import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IReport } from 'src/app/core/interfaces/report.interface';
import { ReportDetailsService } from 'src/app/pages/user/report-details/report-details.service';
import { exportTypeEnum, sourceTypeEnum } from 'src/app/core/enum/report.enum';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnChanges {
  readonly REPORT = 'REPORT';
  readonly sourceTypes = sourceTypeEnum;
  readonly exportTypeEnum = exportTypeEnum;

  @Input() report!: IReport;
  @Input() loadingExport = false;
  @Output() favoriteClicked = new EventEmitter<void>();
  @Output() printClicked = new EventEmitter<void>();
  @Output() exportClicked = new EventEmitter<exportTypeEnum>();

  modalType: 'EXPORT' | 'SEE_ALL' | null = 'EXPORT';
  latestUpdate!: string;
  dataSources: string[] = [];
  exportType!: exportTypeEnum | null;
  isExpanded = false;
  constructor(
    public reportService: ReportDetailsService,
    private router: Router,
    private snackbarService: SnackbarService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.report?.currentValue){
      this.dataSources = this.report?.datasources?.filter(d => d?.enabled)?.map(src => src?.name);
      this.latestUpdate = [...this.report?.datasources].sort((x, y) => new Date(x?.refreshDate).getTime() - new Date(y?.refreshDate).getTime()).slice(-1)[0]?.refreshDate;
    }
    if(changes?.loadingExport?.currentValue){
      this.loadingExport = changes?.loadingExport?.currentValue;
    }
  }

  openModal(): void{
    this.modalType = 'SEE_ALL';
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  onToggleFavorite(): void{
    this.favoriteClicked.emit();
  }

  openExportModal(): void{

    if(this.report?.sourceType === sourceTypeEnum.POWER_BI){
      this.modalType = 'EXPORT';
    } else {
      this.modalType = null;
      this.exportClicked.emit(exportTypeEnum.NONE);
    }
  }

  printReport(): void{
    this.printClicked.emit();
  }

  setExportType(type: exportTypeEnum): void{
    this.exportType = type;
  }

  exportFile(): void{
    if(this.report?.sourceType === sourceTypeEnum.POWER_BI){
      switch (this.exportType) {
        case exportTypeEnum.EXCEL:
          this.exportClicked.emit(exportTypeEnum.EXCEL)
          return;
        case exportTypeEnum.PNG:
          this.exportClicked.emit(exportTypeEnum.PNG)
          return;
        case exportTypeEnum.PPT:
          this.exportClicked.emit(exportTypeEnum.PPT)
          return;
        default:
          this.exportClicked.emit(exportTypeEnum.PDF)
          return;
      }
    }

  }

  cancelExport(): void{
    this.exportType = null;
  }

  onShareClicked(): void{
    navigator.clipboard.writeText(location?.href).then(text => this.snackbarService.show('REPORT_DETAILS.CLIPBOARD_URL_SUCCESS', 'success', true));
  }

}
