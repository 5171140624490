import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { headerReports } from 'src/app/core/constants/admin';
import { IReport } from 'src/app/core/interfaces/report.interface';
import { ITableHeader } from 'src/app/core/interfaces/section.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { sourceTypeEnum } from 'src/app/core/enum/report.enum';

@Component({
  selector: 'app-section-reports',
  templateUrl: './section-reports.component.html',
  styleUrls: ['./section-reports.component.scss'],
})
export class SectionReportsComponent implements OnInit {
  readonly DEFAULT_SORT = 'code';
  readonly REPORT = 'REPORT';
  readonly limit = 10;

  @Input() sectionId!: number;
  @Input() sectionName!: string;
  @Output() allSectionClicked = new EventEmitter<void>();


  reports: IReport[] = [];
  total = 0;
  skip = 0;
  end = 0;
  currentPage = 1;
  tableHeader: ITableHeader[] = headerReports;
  sort = this.DEFAULT_SORT;
  is_desc = false;
  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getReportsBySection();
  }

  getReportsBySection(): void {
    this.dashboardService.getReportsBySectionId(this.sectionId, this.skip, this.limit, this.sort, this.is_desc).subscribe((data) => {
      this.total = data.total;
      this.reports = data.items?.map((report: IReport) => ({ ...report, disabled: report.visibility === 'PRIVATE', sourceType: this.setSourceTypeName(report.sourceType) }));
      this.end = Math.min(10 * (this.currentPage), this.total);
    })
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getReportsBySection();
  }

  sortTable(header: ITableHeader): void {
    this.tableHeader.forEach((item) => {
      if (item.id === header.id) {
        item.direction = !item.direction;
        this.sort = header.name;
        this.is_desc = header.direction;
      } else {
        item.direction = false;
      }
    });
    this.getReportsBySection();
  }

  navigateToSections(): void {
    this.allSectionClicked.emit();
  }

  addReport(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['report'] } }]);
  }

  editReport(id: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['report', id] } }]);
  }

  deleteReport(id: number): void {
    if (confirm("Are you sure to delete")) {
      this.dashboardService.deleteReport(id).subscribe(() => {
        this.snackbarService.show('Delete successfully', 'success');
        return this.getReportsBySection();
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  setSourceTypeName(sourceType: string): string {
    switch (sourceType) {
      case sourceTypeEnum.POWER_BI:
        return 'Power BI'
      case sourceTypeEnum.SAP_BO:
        return 'BO'
      case sourceTypeEnum.FILE:
        return 'File'
      default:
        return 'Link'
    }
  }
}
