<div>
  <ng-container>
    <form class="row mt-4">
      <div class="path pointer"
        *ngIf="['power-bi','sap-bo'].includes(sourceType) && ( platformItem.length || folderPathItems.length)">
        <span (click)="reportService.getFolders()">
          {{sourceType | uppercase }}
        </span>
        &gt;
        <ng-container *ngFor="let item of folderPathItems; let i = index; let last = last">
          <span (click)="reportService.backToFolder(item, i)">{{item.name}}</span> {{last ? '' : ' &gt; ' }}
        </ng-container>
      </div>
      <ng-container>
        <div *ngFor="let item of this.platformItem" (click)="reportService.selectItem(item)"
          class="pointer rectangle d-flex flex-row justify-content-between align-items-center m-2"
          [ngClass]="{'bg-selected': item.selected}">
          <div class="d-flex align-items-center">
            <div class="icon icon-30 mx-2"
              [ngClass]="{'icon-folder': item.type === 'FOLDER', 'icon-file': item.type === 'REPORT'}">
            </div>
            <span class="item-name">{{item.name}}</span>
          </div>
          <span *ngIf="item.selected" class="icon icon-20 mx-2 icon-done mr-4"></span>
        </div>
      </ng-container>
    </form>
  </ng-container>
  <div *ngIf="childrenSelected.length" class="pt-4">
    <label>Selected Reports</label>
    <div class="row">
      <div class="col-md-4 d-flex" *ngFor="let item of childrenSelected">
        <div class="pointer w-100 bg-white rectangle d-flex align-items-center justify-content-between m-2">
          <div class="d-flex align-items-center">
            <div class="icon icon-30 mx-2 icon-file">
            </div>
            <span class="item-name">{{item.name}}</span>
          </div>
          <span (click)="reportService.selectItem(item)" class="icon icon-20 mx-2 icon-close mr-4"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12 mt-5 d-flex justify-content-end"
  *ngIf="((childrenSelected.length || ['link','file'].includes(sourceType)) )">
  <button *ngIf="step === 1" class="btn-action btn px-4 py-2 me-3" type="button" role="button"
    attr.aria-label="button report next step" id="btn-add-report-next" (click)="toNextStep()">
    Next</button>
</div>
