import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SeeAllResultsComponent } from './see-all-results.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SeeAllResultsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forRoot(),
    FormsModule
  ],
  exports: [
    SeeAllResultsComponent,
  ],
  providers:[
  ]
})
export class SeeAllResultsModule { }
