<section class="data-source-container d-flex flex-column p-5">
  <div class="data-source-header my-3">
    <h4 class="mb-3">DATA SOURCE MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>
        {{dataSource ? 'EDIT A DATA SOURCE' : 'ADD A DATA SOURCE'}}
      </h6>
    </div>
  </div>
  <div class="data-source-content mt-4" *ngIf="dataSourceForm">
    <form [formGroup]="dataSourceForm" class="g-3">
      <div class="row mt-3">
        <div class="col-6" *ngIf="editForm">
          <label for="datasources"
            class="form-label">Technical ID</label>
          <input type="text" class="form-control" formControlName="technicalId" id="technicalId">
        </div>
        <div class="col-6">
          <label for="refreshDate" class="form-label">Name</label>
          <input type="text" class="form-control" formControlName="name"
            [ngClass]="{'is-invalid': dataSourceForm.controls.name.invalid && dataSourceForm.controls.name.touched}">
          <div *ngIf="dataSourceForm.controls.name.errors?.required && dataSourceForm.controls.name.touched"
            class="invalid-feedback">
            a data source must always have a name!
          </div>
        </div>
      </div>
      <div class="row mb-3  mt-3" *ngIf="editForm">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="refreshDate"
            class="form-label">Data refresh</label>
          <input type="text" class="form-control" formControlName="refreshDate" id="refreshDate">
        </div>
      </div>
      <div class="col-12 mb-3  mt-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="enabled" id="enabled"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="data-sourceDisabled">
            Yes, disable this data source
          </label>
        </div>
      </div>
      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button"
          attr.aria-label="button submit data-source" id="btn-submit-data-source" [disabled]="dataSourceForm.invalid"
          (click)="submitForm()">
          {{dataSource ? ' Edit A Data Source ' : ' Add A Data Source'}}
        </button>
      </div>
    </form>
  </div>
</section>
