export enum SearchFacetTypesEnum {
  DASHBOARD = 'DASHBOARD',
  REPORT = 'REPORT',
  GLOSSARY = 'GLOSSARY',
  DATA_UPSKILLING = 'DATA_UPSKILLING',
  All = 'All'
}

export enum ResultTypesEnum {
  DASHBOARD = 'DASHBOARD',
  REPORT = 'REPORT',
  SECTION = 'SECTION'
}
