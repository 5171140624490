import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from 'src/app/features/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { AllSectionsComponent } from 'src/app/features/all-sections/all-sections.component';
import { DashboardService } from './dashboard.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddSectionComponent } from 'src/app/features/add-section/add-section.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddReportComponent } from 'src/app/features/add-report/add-report.component';
import { SectionReportsComponent } from 'src/app/features/section-reports/section-reports.component';
import { AllTagsComponent } from 'src/app/features/all-tags/all-tags.component';
import { AddTagComponent } from 'src/app/features/add-tag/add-tag.component';
import { AllDataSourcesComponent } from 'src/app/features/all-data-sources/all-data-sources.component';
import { AddDataSourceComponent } from 'src/app/features/add-data-source/add-data-source.component';
import { AllGroupsComponent } from 'src/app/features/all-groups/all-groups.component';
import { AddGroupComponent } from 'src/app/features/add-group/add-group.component';
import { AllUsersComponent } from 'src/app/features/all-users/all-users.component';
import { AllNewsComponent } from 'src/app/features/all-news/all-news.component';
import { AddNewsComponent } from 'src/app/features/add-news/add-news.component';
import { AddAlertComponent } from 'src/app/features/add-alert/add-alert.component';
import { AddUserComponent } from 'src/app/features/add-user/add-user.component';
import { AddELearningsComponent } from 'src/app/features/add-e-learnings/add-e-learnings.component';
import { AllELearningsComponent } from 'src/app/features/all-e-learnings/all-e-learnings.component';
import { AllTranslationsComponent } from 'src/app/features/all-translations/all-translations.component';
import { AddTranslationComponent } from 'src/app/features/add-translation/add-translation.component';
import { AddReportStepTwoComponent } from 'src/app/features/add-report-step-two/add-report-step-two.component';
import { AddReportStepOneComponent } from 'src/app/features/add-report-step-one/add-report-step-one.component';
import { AllWorkspaceMappingComponent } from 'src/app/features/all-workspace-mapping/all-workspace-mapping.component';
import { EditWorkspaceMappingComponent } from 'src/app/features/edit-workspace-mapping/edit-workspace-mapping.component';


@NgModule({
  declarations: [
    DashboardComponent,
    SidebarComponent,
    AllSectionsComponent,
    SectionReportsComponent,
    AddSectionComponent,
    AddReportComponent,
    AddTagComponent,
    AllTagsComponent,
    AllDataSourcesComponent,
    AddDataSourceComponent,
    AllNewsComponent,
    AddNewsComponent,
    AllGroupsComponent,
    AddGroupComponent,
    AllUsersComponent,
    AddAlertComponent,
    AddUserComponent,
    AllGroupsComponent,
    AddGroupComponent,
    AllUsersComponent,
    AllELearningsComponent,
    AddELearningsComponent,
    AllTranslationsComponent,
    AddTranslationComponent,
    AddReportStepTwoComponent,
    AddReportStepOneComponent,
    AllWorkspaceMappingComponent,
    EditWorkspaceMappingComponent

  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    DragDropModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    DashboardComponent,
    SidebarComponent,
    AllSectionsComponent,
    SectionReportsComponent,
    AddSectionComponent,
    AddTagComponent,
    AllTagsComponent,
    AllDataSourcesComponent,
    AddDataSourceComponent,
    AllGroupsComponent,
    AddGroupComponent,
    AllUsersComponent,
    AllNewsComponent,
    AddNewsComponent,
    AddAlertComponent,
    AddUserComponent,
    AllELearningsComponent,
    AddELearningsComponent,
    AllTranslationsComponent,
    AddTranslationComponent,
    AddReportStepTwoComponent,
    AddReportStepOneComponent,
    AllWorkspaceMappingComponent,
    EditWorkspaceMappingComponent

  ],
  providers:[
    DashboardService,
  ]
})
export class DashboardModule { }
