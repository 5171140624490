import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  user!: IUser | null;
  userForm!: FormGroup;
  searchEmail!: string;
  allUsers$!: Observable<{firstName: string, lastName: string, email:string}[]>;
  usersLoading = false;
  emailSearch$ = new Subject<string>();
  selectedEmail!: {firstName: string, lastName: string, email:string};

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.init();
    this.onSearchEmailChanged();
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const userId = paramMap.get('id');
          if (userId) {
            return this.dashboardService.getUser(userId);
          }
          this.user = null;
          this.initUserForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.initUserForm();
        }
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  initUserForm(): void {
    this.userForm = this.formBuilder.group({
      firstName: [{value: this.user?.firstName ?? null, disabled: true}, [Validators.required]],
      lastName: [{value: this.user?.lastName ?? null, disabled: true}, [Validators.required]],
      email: [{value: this.user?.email ?? null, disabled: true}, [Validators.required]],
      isAdmin: [this.user?.isAdmin === true],
      enabled: [this.user?.enabled === false],
      sapBoId: [this.user?.sapBoId ?? null]
    });
  }

  submitForm(): void {
    const enabled = !this.userForm.controls.enabled.value;
    let form = {
      ...this.userForm.getRawValue(), enabled
    };
    this.addOrUpdate(form);
  }

  addOrUpdate(formValue: IUser): void {
    if (this.user) {
      this.dashboardService.updateUser(formValue, this.user.id).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['users'] } }]);
        this.snackbarService.show('Update successfully', 'success')
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );

    }
    else {
      this.dashboardService.addUser(formValue).subscribe(() => {
        this.router.navigate(['/admin', { outlets: { admin: ['users'] } }]);
        this.snackbarService.show('Create successfully', 'success');
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  onSearchEmailChanged():void{
    this.allUsers$ = concat(
      of([]), // default items
      this.emailSearch$.pipe(
        filter((res) => {return res !== null && res.length >= 1;}),
        distinctUntilChanged(),
        debounceTime(600),
        tap(() => (this.usersLoading = true)),
        switchMap((term: string) => {
          return this.dashboardService.validateUserByAD(term).pipe(
            map(resp => resp.value.map((elem: {givenName: string, surname: string, userPrincipalName: string}) => ({
              firstName: elem?.givenName,
              lastName: elem?.surname,
              email: elem?.userPrincipalName
            })).filter(e => e?.email !== null)),
            catchError(() => of([])), // empty list on error
            tap(() => (this.usersLoading = false))
          );
        })
      )
    );
  }

  onEmailSelected(): void{
    if(this.selectedEmail){
      this.userForm.controls.email.setValue(this.selectedEmail?.email);
      this.userForm.controls.firstName.setValue(this.selectedEmail?.firstName);
      this.userForm.controls.lastName.setValue(this.selectedEmail?.lastName);
    } else {
      this.userForm.controls.email.setValue(null);
      this.userForm.controls.firstName.setValue(null);
      this.userForm.controls.lastName.setValue(null);
    }
  }
}
