<section class="tag-container d-flex flex-column p-5">
  <div class="tag-header my-3">
    <h4 class="mb-3">TAG MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>{{tag ? 'EDIT A TAG' : 'ADD A TAG'}}</h6>
    </div>
  </div>

  <div class="tag-content mt-4" *ngIf="tagForm">
    <form [formGroup]="tagForm" class="g-3">

      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="tagName" class="form-label">Name</label>
          <input type="text" class="form-control" id="tagName" formControlName="name"
            [ngClass]="{'is-invalid': tagForm.controls.name.invalid && tagForm.controls.name.touched}">
          <div *ngIf="tagForm.controls.name.errors?.required && tagForm.controls.name.touched" class="invalid-feedback">
            a tag needs a name!
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="tagComment" class="form-label">Comment</label>
          <textarea class="form-control" formControlName="comment" id="tagComment" rows="3"></textarea>
        </div>
      </div>


      <div class="col-12 mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="enabled" id="tagDisabled"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="tagDisabled">
            Yes, disable this tag
          </label>
        </div>
      </div>

      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button submit tag"
          id="btn-submit-tag" [disabled]="tagForm.invalid" (click)="submitTag()">
          {{tag ? 'EDIT A TAG' : 'ADD A TAG'}}
        </button>
      </div>

    </form>
  </div>
</section>
