<section class="all-groups-container d-flex flex-column p-5">
  <div class="all-groups-header my-3">
    <h4 class="mb-3">USER RIGHT & MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL GROUPS</h6>
      <div class="d-flex align-items-center">
        <button class="btn-action btn px-4 py-2 me-3" (click)="addGroups()" type="button" role="button"
          attr.aria-label="button add group" id="btn-add-group">Create A Group</button>
      </div>
    </div>
  </div>
  <div class="all-groups-content mt-4">
    <div class="d-flex justify-content-end">
      <span
        class="result-text">Showing {{skip + 1}}-{{end}} of {{total}} items
      </span>
    </div>
    <div class="table-all-groups pb-5">
      <table class="table table-hover table-striped mt-3">
        <thead>
          <tr>
            <ng-container *ngFor="let header of tableHeader;">
              <th scope="col" class="text-center pointer">
                <div class="d-flex justify-content-between align-items-center mx-2">
                  <div> {{ header.label }}</div>
                </div>
              </th>
            </ng-container>
            <th scope="col" class="text-center">DISABLE</th>
            <th scope="col" class="text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          <div class="loader-container position-absolute pt-5 mt-5" *ngIf="!groups.length">
            <app-loader></app-loader>
          </div>
          <tr *ngFor="let group of groups">
            <td class="col col-md-3">{{group.name}}</td>
            <td class="col col-md-4">
              <div class="d-flex flex-wrap">
                <span class="type-item ng-label-tmp"
                  *ngFor="let sourcegrp of getTypeGroup(group.sourcegroups, 'SAP_BO')">
                  {{sourcegrp.name}} </span>
              </div>
            </td>
            <td class="col col-md-4">
              <div class="d-flex flex-wrap">
                <span class="type-item ng-label-tmp"
                  *ngFor="let sourcegrp of getTypeGroup(group.sourcegroups, 'SAP_SF')">
                  {{sourcegrp.name}}</span>
              </div>
            </td>
            <td class="col-md-1">
              <div class="d-flex justify-content-center">
                <i class="icon icon-16 icon-checkmark-black" *ngIf="!group.enabled"></i>
              </div>
            </td>
            <td class="col col-md-1">
              <div class="actions d-flex justify-content-center ms-5">
                <div class="icon icon-25 icon-edit pointer" (click)="editGroup(group.id)"
                  tooltip="Edit" placement="bottom"
                  containerClass="tooltipClass"></div>
                <div role="button" class="icon icon-25 icon-delete ms-2 pointer" (click)="deleteGroup(group.id)"
                  tooltip="Delete" placement="bottom"
                  containerClass="tooltipClass"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end mt-3" *ngIf="groups?.length">
        <pagination [directionLinks]="false" [totalItems]="total" [itemsPerPage]="limit" [(ngModel)]="currentPage"
          (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
    </div>
  </div>
</section>
