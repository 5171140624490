import { sourceTypeEnum } from "../enum/report.enum";
import { reportSourceTypeEnum } from "./report.interface";

export interface PlatformItem {
  parentId?: string;
  id: string;
  type: PlatformItemTypeEnum;
  name: string;
  platform: reportSourceTypeEnum;
  sourceType: sourceTypeEnum;
  selected?: boolean;
  url: string;
  embedUrl: string;
  description: string;
  paginated?: boolean;
  cuid: string;
}

export interface DataSource {
  id: number;
  name: string;
}

export enum PlatformItemTypeEnum {
  FOLDER = 'FOLDER',
  REPORT = 'REPORT'
}
export enum PlatformGroupTypeEnum {
  SAP_SF = 'sap-sf',
  SAP_BO = 'sap-bo'
}
