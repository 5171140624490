import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, from, Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: MsalService,
    public authService: AuthService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith(environment.publicHost)) {
      return from(this.auth.instance.acquireTokenSilent({ scopes: [`${environment.clientId}/.default`] })).pipe(
        catchError(err => {
          console.error(err);
          this.auth.instance.acquireTokenRedirect({ scopes: [`${environment.clientId}/.default`] });
          return EMPTY
        }),
        switchMap(tokenResponse => {
          request = request.clone({
            setHeaders: {
              'x-pap-token': 'Bearer ' + tokenResponse.accessToken,
            }
          });
          const connectWithEmail = localStorage.getItem('connectWithEmail')
          if (connectWithEmail) {
            request = request.clone({
              setHeaders: {
                'x-pap-connect-as': connectWithEmail
              }
            });
          }
          return next.handle(request)
        })
      );
    }
    return next.handle(request)
  }
}
