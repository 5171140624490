import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { headerWorkspaces } from 'src/app/core/constants/admin';
import { IWorkspace } from 'src/app/core/interfaces/group';
import { ITableHeader } from 'src/app/core/interfaces/section.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-all-workspace-mapping',
  templateUrl: './all-workspace-mapping.component.html',
  styleUrls: ['./all-workspace-mapping.component.scss']
})
export class AllWorkspaceMappingComponent implements OnInit {
  readonly DEFAULT_SORT = 'name';
  readonly limit = 10;
  sort = this.DEFAULT_SORT;
  currentPage = 1;
  total = 0;
  skip = 0;
  end = 0;
  is_desc = false;
  tableHeader: ITableHeader[] = headerWorkspaces;
  workspaces: IWorkspace[] = [];
  isLoading = false;
  searchValue = "";
  inSearch =false;

  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllWorkspaces();
  }

  getAllWorkspaces(): void {
    let search = this.inSearch ? this.searchValue : "";
    this.isLoading = true;
    this.dashboardService
      .getWorkspaces(this.skip, this.limit, this.sort, this.is_desc, search)
      .subscribe((data) => {
        this.total = data.total;
        this.workspaces = data.items;
        this.isLoading = false;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  searchWorkspaces(inSearch:boolean):void{
    this.inSearch = inSearch && this.searchValue.length > 0
    this.getAllWorkspaces();
  }

  editWorkspace(id: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['workspace', id] } }]);
  }

  sortTable(header: ITableHeader): void {
    if (header.isSortable !== false) {
      this.tableHeader.forEach((item) => {
        if (item.id === header.id) {
          item.direction = !item.direction;
          this.sort = header.name;
          this.is_desc = header.direction;
        } else {
          item.direction = false;
        }
      });
      this.getAllWorkspaces();
    }
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getAllWorkspaces();
  }

}
