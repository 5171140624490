<div class="w-100" [ngStyle]="{'background-color': isDarkBg ? '#0c1c30' : '#F3F4F6'}"
[ngClass]="adminWorkplace ? 'admin-footer ' : 'px-5'">
  <div class="main-footer w-100">
    <footer class="d-flex w-100 flex-wrap justify-content-between align-items-center p-4 pt-3 pap-bg-secondary rounded-top">
      <div class="d-flex align-items-center" [ngClass]="adminWorkplace ? 'col-md-3 admin-logo-content ' : 'col-md-4'">
        <span class="font-size-14 text-muted pointer">© L'OREAL </span>
      </div>
      <ul class="nav justify-content-end list-unstyled d-flex" [ngClass]="adminWorkplace ? 'col-md-9 col-lg-7 ' : 'col-md-8  col-lg-5'">
        <!-- <li class="mx-lg-3 mx-md-1"><a>{{'FOOTER.GETTING_STARTED' | translate }}</a></li> -->
        <!-- <li class="mx-3"><a>{{'FOOTER.FAQ' | translate }}</a></li> -->
        <!-- <li class="mx-3"><a>{{'FOOTER.E-LEARNINGS' | translate }}</a></li> -->
<!--         <li class="ms-3 contact-us-btn">
          <button type="button" role="button" id="btn-contact-us" class="btn btn-statistic font-size-13 trenda-regular text-dark-blue py-0 px-4">
            {{'FOOTER.CONTACT_US' | translate }}
          </button>
        </li> -->
      </ul>
    </footer>
  </div>
</div>

