import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { SearchService } from 'src/app/core/services/search.service';
import { SliderTypesEnum } from 'src/app/features/sliders-list/slider.enum';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  readonly sliderTypesEnum = SliderTypesEnum;
  loader = false;
  constructor(
    public searchService: SearchService,
    public authService: AuthService,
    private snackbarService: SnackbarService,

  ) { }

  ngOnInit(): void {
    this.authService.isAuthenticated.subscribe(
      (authenticated) => {
        // set the searchList
        if (authenticated) {
          const connectedUser = this.authService.getCurrentUser();
          forkJoin([
            this.searchService.searchGlobal(connectedUser),
            this.searchService.getMyFavorites(connectedUser, 12),
          ]).subscribe((data: any[]) => {
            this.searchService.searchResults = data[0]?.results?.map((section: any) => ({
              found: section?.found,
              hits: this.searchService.mapHitToDocument(section.hits, connectedUser),
              name: section.hits[0]?.document?.section_name_facet as string,
              out_of: section.out_of,
              visibility: section.visibility
            }))?.filter((s: any) => {
              if ( s?.hits?.length && connectedUser?.isAdmin){
                return s;
              } else if (s?.hits?.length && !connectedUser?.isAdmin &&  s?.visibility !== "PRIVATE"){
                return s;
              }
            });
            if (!this.searchService.searchResults?.length && !connectedUser.isAdmin) {
              this.loader = true;
              this.snackbarService.show('HOME.NO_RESULT_FOUND', 'info', true);
            }
            this.searchService.favorites = data[1]?.results?.map((section: any) => ({
              found: section?.found,
              hits: this.searchService.mapHitToDocument(section.hits, connectedUser),
              name: section.hits[0]?.document?.section_name_facet as string,
              out_of: section.out_of,
            }));
          });
        }
      }
    );
  }
}