import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient) { }

  logLogin(): Observable<any> {
    return this.http.get(`${API_URLS.ANALYTICS}/login`)
  }

  logLogout(): Observable<any> {
    return this.http.get(`${API_URLS.ANALYTICS}/logout`)
  }

  logSearch(searchText: string): Observable<any> {
    return this.http.get(`${API_URLS.ANALYTICS}/search`, { params: { q: searchText } })
  }
}
