import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SearchResultsComponent } from './search-results.component';

@NgModule({
  declarations: [
    SearchResultsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forRoot(),
  ],
  exports: [
    SearchResultsComponent,
  ],
  providers:[
  ]
})
export class SearchResultsModule { }
