import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { DateFormatPipe } from '../core/pipes/date-format.pipe';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SliderItemComponent } from '../features/slider-item/slider-item.component';
import { SlidersListComponent } from '../features/sliders-list/sliders-list.component';
import { SliderItemPopoverComponent } from '../features/slider-item-popover/slider-item-popover.component';
import { PopoverModule } from 'ngx-bootstrap/popover';



@NgModule({
  declarations: [
    LoaderComponent,
    DateFormatPipe,
    SliderItemComponent,
    SliderItemPopoverComponent,
    SlidersListComponent,
    SnackbarComponent

  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  exports: [
    LoaderComponent,
    DateFormatPipe,
    SliderItemComponent,
    SliderItemPopoverComponent,
    SlidersListComponent,
    SnackbarComponent
  ],
  providers: [
    DateFormatPipe
  ]
})
export class SharedModule { }
