<section class="all-users-container d-flex flex-column p-5">
  <div class="all-users-header my-3">
    <h4 class="mb-3">USER RIGHT & MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL USERS</h6>
    </div>
  </div>
  <div class="all-users-content mt-4">
      <div class="col-6 d-flex align-items-center position-relative">
        <div id="icon-search" class="icon icon-18 icon-search ms-2 position-absolute"></div>
        <input class="search-users form-control ps-5" [(ngModel)]="searchValue" name="search" type="search" (search)="searchUsers()" autocomplete="off"
          placeholder="Search through all users">
        <button type="button" role="button" attr.aria-label="Search" class="btn-search ms-2"
          (click)="searchUsers()">Search</button>
      </div>
    <div class="d-flex justify-content-end">
      <span
        class="result-text">Showing {{skip + 1}}-{{end}} of {{total}} items
      </span>
    </div>
    <div class="table-all-users pb-5">
      <table class="table table-hover table-striped mt-3">
        <thead>
          <tr>
            <ng-container *ngFor="let header of tableHeader;">
              <th scope="col" class="text-center" [ngClass]="{'pointer': !header?.isSortable}" (click)="sortTable(header)">
                <div class="d-flex justify-content-between align-items-center mx-2">
                  <div> {{ header.label }}</div>
                  <i class="icon icon-14 ml-2" *ngIf="header.isSortable !== false" [ngClass]="header?.direction ? 'icon-polygon-down' : 'icon-polygon'"></i>
                </div>
              </th>
            </ng-container>
            <th scope="col" class="text-center">DISABLE</th>
            <th scope="col" class="text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          <div class="loader-container position-absolute pt-5 mt-5" *ngIf="isLoading">
            <app-loader></app-loader>
          </div>
          <ng-container *ngIf="users.length !== null; else noData">
            <tr *ngFor="let user of users">
              <td class="col-md-1"> {{user?.firstName}} </td>
              <td class="col-md-1"> {{user?.lastName}}</td>
              <td class="col-md-auto">{{user?.email}}</td>
              <td class="col-md-1"> {{user?.sapBoId}}</td>
              <td class="col-md-auto">
                <div class="d-flex flex-wrap">
                  <span class="type-item ng-label-tmp" *ngFor="let group of user?.groups">
                    {{group?.name}} </span>
                </div>
              </td>
              <td class="col-md-1">
                <div class="d-flex justify-content-center">
                  <i class="icon icon-16 icon-checkmark-black" *ngIf="user?.isAdmin"></i>
                </div>
              </td>
              <td class="col-md-1">
                <div class="d-flex justify-content-center">
                  <i class="icon icon-16 icon-checkmark-black" *ngIf="!user?.enabled"></i>
                </div>
              </td>
              <td class="col-md-2">
                <div class="actions d-flex justify-content-center ms-5">
                  <div class="icon icon-25 icon-edit pointer" (click)="editUser(user.id)" tooltip="Edit"
                    placement="bottom" containerClass="tooltipClass"></div>
                  <div class="icon icon-25 mx-2 icon-see-report pointer" tooltip="" placement="bottom"
                    containerClass="tooltipClass">
                  </div>
                  <div class="icon-container">
                    <div *ngIf="!user?.isAdmin && authService?.getCurrentUser()?.id  !== user.id"
                      class="icon icon-25 icon-connect-as ms-2 pointer" (click)="connectAs(user.email)"
                      tooltip="connect As" placement="bottom" containerClass="tooltipClass">
                    </div>
                  </div>
                  <div role="button" class="icon icon-25 icon-delete ms-2 pointer" (click)="deleteUser(user.id)"
                    tooltip="Delete" placement="bottom" containerClass="tooltipClass"></div>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-template #noData>
            <tr>
              <td colspan="8" class="col-12 text-center">
                No Data Found
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="d-flex justify-content-end mt-3" *ngIf="users?.length">
        <pagination
        [maxSize]="4"
        [directionLinks]="true"
        [previousText]="'&laquo;'"
        [nextText]="'&raquo;'"
        [totalItems]="total"
        [itemsPerPage]="limit"
        [(ngModel)]="currentPage"
        [rotate]="false"
        (pageChanged)="pageChanged($event)">
      </pagination>
      </div>
    </div>
  </div>
</section>
