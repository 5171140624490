<section class="translation-container d-flex flex-column p-5">
  <div class="translation-header my-3">
    <h4 class="mb-3">Translation Management</h4>
    <div class="d-flex justify-content-between">
      <h6>{{translation ? 'edit Translation' : 'Add Translation'}}</h6>
    </div>
  </div>
  <div class="group-content mt-4" *ngIf="translationForm">
    <form [formGroup]="translationForm" class="g-3">
      <div class="row mb-3">
        <div class="row mb-3">
          <div class="col-xs-12 col-md-8 col-lg-6">
            <label for="translation-code" class="form-label">Code<span class="red-star">*</span></label>
            <input type="text" class="form-control text-uppercase" id="translation-code" formControlName="code"
            [ngClass]="{'is-invalid': translationForm.controls.code.invalid && translationForm.controls.code.touched}">
          <div *ngIf="translationForm.controls.code.errors?.required && translationForm.controls.code.touched" class="invalid-feedback">
            a translation must always have a code!
          </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="translation-name" class="form-label">Language<span class="red-star">*</span></label>
          <input type="text" class="form-control" id="translation-name" formControlName="name"
          [ngClass]="{'is-invalid': translationForm.controls.name.invalid && translationForm.controls.name.touched}">
        <div *ngIf="translationForm.controls.name.errors?.required && translationForm.controls.name.touched" class="invalid-feedback">
          which language are you translating?
        </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <div class="d-flex align-items-center">
            <label for="file" class="form-label">Upload File<span class="red-star">*</span></label>
            <div *ngIf="startUploadingFile" class="spinner-border spinner-border-sm text-secondary ms-2 mb-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <ng-container *ngIf="!displayFileInput; else input_file">
            <div class="d-flex align-items-center">
              <button type="button" class="btn me-4 btn-secondary btn-lg" disabled>{{translation?.translationsFile?.name}}</button>
              <div class="icon icon-13 icon-pencil-blue pointer" (click)="onDisplayFileInput()"></div>
            </div>
          </ng-container>
          <ng-template #input_file>
            <input class="form-control" [ngClass]="displayFileInput ? 'd-block' : 'd-none'" (change)="saveFile($event)"
              type="file" formControlName="translationsFile" id="file" accept=".csv, text/csv"
              [ngClass]="{'is-invalid': translationForm.controls.translationsFile.invalid && translationForm.controls.translationsFile.touched}">
            <div
              *ngIf="translationForm.controls.translationsFile.errors?.required && translationForm.controls.translationsFile.touched"
              class="invalid-feedback">
              no mapping file ? no translation!
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-12 my-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="enabled" id="translation-disabled"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="translation-disabled">
            Disable Translation ?
          </label>
        </div>
      </div>
      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" [disabled]="translationForm.invalid || startUploadingFile" (click)="submitTraduction()"
          type="button" role="button" attr.aria-label="button submit translation" id="btn-submit-group">
          {{translation ? 'EDIT TRANSLATION' : 'ADD TRANSLATION'}}

        </button>
      </div>
    </form>
  </div>
</section>
