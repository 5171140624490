import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { delay, mergeMap, takeUntil } from 'rxjs/operators';
import { sourceTypeEnum } from 'src/app/core/enum/report.enum';
import { IDocument } from 'src/app/core/interfaces/section.interface';
import { SliderItemPopoverService } from './slider-item.service';

@Component({
  selector: 'app-slider-item-popover',
  templateUrl: './slider-item-popover.component.html',
  styleUrls: ['./slider-item-popover.component.scss']
})
export class SliderItemPopoverComponent implements OnInit, OnDestroy {

  readonly REPORT = 'REPORT';
  readonly SAP = 'SAP';
  readonly sourceTypes = sourceTypeEnum;;

  @Input() index: number = 0;
  @Input() item!: IDocument;
  @Input() bgColor!: string;
  @Input() isTeams = false;

  subscription = new Subscription();
  _mouseEnterStream: Subject<boolean> = new Subject();
  _mouseLeaveStream: Subject<boolean> = new Subject();

  context: any = {};

  isOpen = false;

  constructor(
    private sliderItemService: SliderItemPopoverService
  ) { }

  ngOnInit(): void {
    this.openPopoverSubscription();
    this.closePopoverSubscription();
    this.context.item = this.item;
  }

  openPopoverSubscription(): void {
    this.subscription.add(
      this._mouseEnterStream.pipe(mergeMap((e) => {
        return of(e).pipe(
          delay(1000),
          takeUntil(this._mouseLeaveStream)
        );
      })).subscribe(() => {
        this.openPopover();
      })
    );
  }

  closePopoverSubscription(): void {
    this.subscription.add(
      this.sliderItemService.activeItemIndexSub.asObservable().subscribe(index => {
        if (this.index !== index) {
          this.isOpen = false;
        }
      })
    )
  }

  onMouseEnter(): void {
    this._mouseEnterStream.next();
  }

  onMouseLeave(): void {
    this._mouseLeaveStream.next();
    this.isOpen = false;
  }

  openPopover(): void {
    this.isOpen = true;
    this.sliderItemService.activeItemIndexSub.next(this.index);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
