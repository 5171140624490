<section class="section-container d-flex flex-column p-5">
  <div class="section-header my-3">
    <h4 class="mb-3">REPORT ORGANIZATION</h4>
    <div class="d-flex justify-content-between">
      <h6 >{{section ? 'EDIT A SECTION' : 'ADD A SECTION'}}</h6>
    </div>
  </div>
  <div class="section-content mt-4" *ngIf="sectionForm">
    <form [formGroup]="sectionForm" class="g-3">
      <div class="row mb-3" *ngIf="section">
        <div class="col-xs-12 col-md-4 col-lg-4 col-xl-2">
          <label for="sectionPosition" class="form-label">Position</label>
          <input type="number" class="form-control" formControlName="order" [ngClass]="{'is-invalid': sectionForm.controls.order.invalid}" id="sectionPosition">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="sectionName" class="form-label">Name</label>
          <input type="text" class="form-control"  id="sectionName" formControlName="name" [ngClass]="{'is-invalid': sectionForm.controls.name.invalid && sectionForm.controls.name.touched}" >
          <div  *ngIf="sectionForm.controls.name.errors?.required && sectionForm.controls.name.touched" class="invalid-feedback">
            a section must have a name!
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-6 col-lg-4">
          <label for="sectionCode" class="form-label">Code</label>
          <input type="text" class="form-control" [placeholder]="'3 letters maximum'" formControlName="code" id="sectionCode"  maxlength="3" [ngClass]="{'is-invalid': sectionForm.controls.code.invalid && sectionForm.controls.code.touched}" >
          <div  *ngIf="sectionForm.controls.code.errors?.required && sectionForm.controls.code.touched" class="invalid-feedback">
            a section must have a code!
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="sectiongroups" class="form-label">Select Groups</label>
          <ng-select class="custom input-uppercase" [items]="groups"  bindLabel="name"
          bindValue="id" formControlName="groups" ngDefaultControl [closeOnSelect]="false" [multiple]="true" id="sectiongroups">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="ng-label-tmp d-flex align-items-center px-2 br-4"
              (mousedown)="$event.stopPropagation()">
                <span>{{item.name}}</span>
                <i class="icon icon-12 icon-cross-white ms-2 pointer"
                (click)="clear(item)"></i>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
              <span class="ml-2"> {{item.name}} </span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-6 col-lg-4">
          <label for="sectionCode" class="form-label">Visibility</label>
          <div class="d-flex justify-content-between">
            <div>
              <input type="radio" class="mx-1" id="visibility" [value]="visibilityEnum.AVAILABLE" formControlName="visibility">
              <label class="form-label" for="visibility">{{visibilityEnum.AVAILABLE}}</label>
            </div>
            <div>
              <input type="radio" class="mx-1" id="visibility-unavailable" [value]="visibilityEnum.UNAVAILABLE"
                formControlName="visibility">
              <label class="form-label" for="visibility-unavailable">{{visibilityEnum.UNAVAILABLE}}</label>
            </div>
            <div>
              <input type="radio" class="mx-1" id="visibility-private" [value]="visibilityEnum.PRIVATE" formControlName="visibility">
              <label class="form-label" for="visibility-private">{{visibilityEnum.PRIVATE}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button" attr.aria-label="button submit section"
          id="btn-submit-section" [disabled]="sectionForm.invalid"  (click)="submitSection()">
          {{section ? 'EDIT A SECTION' : 'Add A SECTION'}}
        </button>
      </div>
    </form>
  </div>
</section>
