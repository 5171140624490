import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const {role, navigateTo} = route.data;
    const isAdmin = role === 'ADMIN';
    if(!this.authService?.loggedIn && navigateTo){
      this.router.navigateByUrl(navigateTo);
      return true;
    }
    return this.authService.isAuthenticated.pipe(
      map(isAuthenticated => {
        return (isAuthenticated && isAdmin && this.authService.getCurrentUser().isAdmin);
      }
    ));
  }
}
