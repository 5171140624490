import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  constructor(
    private elementRef: ElementRef,
  ) { }

  @Output() clickOutside: EventEmitter<void> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement:any): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }

}
