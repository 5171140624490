import { Component, Input } from '@angular/core';
import { INewsItem } from 'src/app/core/interfaces/news.interface';
import { SearchService } from 'src/app/core/services/search.service';
import { ThemeService } from 'src/app/core/services/theme.service';

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.scss'],
})
export class LatestNewsComponent {
  @Input() latestNews!: INewsItem[] | undefined;

  constructor(
    public themeService: ThemeService,
    public searchService: SearchService
  ) {}
}
