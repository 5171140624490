<div class="d-flex flex-column align-items-center justify-content-center container pap-logo-login">
  <div class="row">
    <div class="d-flex align-items-center justify-content-center">
      <img src="assets/images/logo-animated.gif" alt="Sign into PAP" width="360" height="100" />
    </div>
  </div>
  <div class="row mt-5">
    <div class="centered text-center">
      <p>User logged in, redirecting to Favorites..</p>
    </div>
  </div>
</div>
