<div class="px-3 d-flex align-items-center justify-content-center" *ngIf="show" [ngClass]="['snackbar-wrap', type]">
  <div class="h-100 w-100 mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center mt-3">
      <div class="h-100" class="icon icon-24 " [ngClass]="{
        'icon-checked-circle-white': type === 'success',
        'icon-warning-white' : type === 'error'
      }"></div>
      <div class="d-flex align-items-center">
        <p class="snackbar-text ubuntu-regular font-size-14 mb-0 ps-3">
          <ng-container *ngIf="toBeTranslated; else translatedContent">
            {{ message | translate}}
          </ng-container>
          <ng-template #translatedContent>
            {{ message }}
          </ng-template>
        </p>
      </div>
    </div>
    <div class="icon icon-12 icon-close pointer mt-2" (click)="hideSnackBar()"></div>
  </div>
</div>
