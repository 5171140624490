import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { concatMap } from 'rxjs/operators';
import { headerTranslations } from 'src/app/core/constants/admin';
import { ITableTagsHeader } from 'src/app/core/interfaces/tag.interface';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-all-translations',
  templateUrl: './all-translations.component.html',
  styleUrls: ['./all-translations.component.scss']
})
export class AllTranslationsComponent implements OnInit {
  readonly DEFAULT_SORT = 'name';
  readonly limit = 10;

  tableHeader: ITableTagsHeader[] = headerTranslations;
  translations: ITranslation[] = [];
  currentPage = 1;
  total = 0;
  skip = 0;
  end = 0;
  sort = this.DEFAULT_SORT;
  is_desc = false;

  constructor(
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private router: Router) { }

  ngOnInit(): void {
    this.getAllTranslations();
  }

  getAllTranslations(): void {
    this.dashboardService
      .getTranslations(this.skip, this.limit, this.sort, this.is_desc)
      .subscribe((data) => {
        this.total = data.total;
        this.translations = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  pageChanged(event: PageChangedEvent): void {
    this.skip = (event.page - 1) * event.itemsPerPage;
    this.getAllTranslations();
  }

  addTranslation(): void {
    this.router.navigate(['/admin', { outlets: { admin: ['translation'] } }]);
  }

  editTranslation(translationId: number): void {
    this.router.navigate(['/admin', { outlets: { admin: ['translation', translationId] } }]);
  }

  deleteTranslation(translationId: number): void {
    if (confirm("Are you sure to delete")) {
      this.dashboardService.deleteTranslation(translationId).pipe(
        concatMap(() => {
          this.snackbarService.show('Delete successfully', 'success');
          return this.dashboardService.getTranslations(this.skip, this.limit, this.sort, this.is_desc);
        })
      ).subscribe((data) => {
        this.total = data.total;
        this.translations = data.items;
        this.end = Math.min(10 * (this.currentPage), this.total);
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
    }
  }

  sortTable(header: ITableTagsHeader): void {
    this.tableHeader.forEach((item) => {
      if (item.id === header.id) {
        item.direction = !item.direction;
        this.sort = header.name;
        this.is_desc = header.direction;
      } else {
        item.direction = false;
      }
    });
    this.getAllTranslations();
  }

  downloadTemplate(): void {
    this.dashboardService.downloadTranslationTemplate().subscribe((data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const url= window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'template.csv';
      link.click();
    },
    () => this.snackbarService.show('Sorry, something went wrong', 'error'));
  }

}
