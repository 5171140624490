import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { INewsAdmin, newsTypeEnum } from 'src/app/core/interfaces/news-alert.interface';
import { SearchService } from 'src/app/core/services/search.service';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {
  news!: INewsAdmin | null;
  newsForm!: FormGroup;
  newsTypeEnum = newsTypeEnum;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  init(): void {
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const newsId = paramMap.get('id');
          if (newsId) {
            return this.dashboardService.getNew(newsId);
          }
          this.news = null;
          this.initNewsForm();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.news = data;
          this.initNewsForm();
        }
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error')
      );
  }

  initNewsForm(): void {
    this.newsForm = this.formBuilder.group({
      title: [this.news?.title ?? null, [Validators.required]],
      message: [this.news?.message ?? null, [Validators.required]],
      pinned: [this.news?.pinned === true],
      category: [this.news?.category ?? 'NEWS'],
      startsAt: [this.news?.startsAt ?? null, [Validators.required]],
      endsAt: [this.news?.endsAt ?? null],
      published: [this.news?.published?? true],
    });
  }

  submitNews(): void {
    if (this.newsForm.value.endsAt === null) this.newsForm.removeControl('endsAt');
    const form = this.newsForm.getRawValue() as INewsAdmin;
    this.addOrUpdate(form);
  }

  addOrUpdate(formValue: INewsAdmin): void {
    if (this.news) {
      this.dashboardService.updateNews(formValue, this.news.id).subscribe(() => {
        this.setSearchServiceNews();
        this.router.navigate(['/admin', { outlets: { admin: ['news'] } }]);
        this.snackbarService.show('Update successfully', 'success')
      },
        (err) => {
          if (err.status === 422) {
            this.snackbarService.show('Start date must be earlier than end date', 'error');
          } else {
            this.snackbarService.show('Sorry, something went wrong', 'error');
          }
        }
      );
    } else {
      this.dashboardService.addNews(formValue).subscribe(() => {
        this.setSearchServiceNews();
        this.router.navigate(['/admin', { outlets: { admin: ['news'] } }]);
        this.snackbarService.show('Create successfully', 'success');
      },
        (err) => {
          if (err.status === 422) {
            this.snackbarService.show('Start date must be earlier than end date', 'error');
          } else {
            this.snackbarService.show('Sorry, something went wrong', 'error');
          }
        }
      );
    }
  }

  setSearchServiceNews(): void {
    this.searchService.getNews().subscribe(
      (news) => this.searchService.setNews(news)
    );
  }
}
