import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { VisibilityEnum } from 'src/app/core/enum/visibility.enum';
import { IResult } from 'src/app/core/interfaces/section.interface';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { SearchService } from 'src/app/core/services/search.service';

@Component({
  selector: 'app-see-all-results',
  templateUrl: './see-all-results.component.html',
  styleUrls: ['./see-all-results.component.scss']
})
export class SeeAllResultsComponent implements OnInit {
  readonly visibilityEnum = VisibilityEnum;

  @ViewChild('tagContainer', { read: ElementRef, static: false }) tagContainer!: ElementRef;
  @ViewChild('dataSourcesContainer', { read: ElementRef, static: false }) dataSourcesContainer!: ElementRef;

  allSections: string[] = [];
  tagsFilters: string[] = [];
  allTags: {count:number, value: string}[] = [];
  allDataSources: {count:number, value: string}[] = [];
  dataSourcesFilters: string[] = [];
  searchTagTerm = '';
  searchDataSourceTerm = '';
  selectedTags: string[] = [];
  defaultSection: string = "FAVORITES";
  results!: IResult;
  currentUser!: IUser;
  isLoading = false;
  filterClicked = false;
  displayTagFilters = false;
  wasTagInside = false;
  displayInputTag = true;
  displayDataSourcesFilters = false;
  displayInputDataSource = true;
  wasInsideDataSource = false;

  constructor(
    public searchService: SearchService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((data) => {
      if(data?.typesenseKey){
        this.currentUser = data;
        this.searchService.getSectionsOrdered().subscribe((data => {
          this.allSections = data?.items?.map(section => section.name) || [];
          this.allSections = ['FAVORITES' , ...this.allSections];
          this.defaultSection = this.searchService?.selectedSection?.length ? this.searchService?.selectedSection : this.allSections[0];
          this.setResults();
        }))
      }
    });
  }

  goBack(): void {
    this.router.navigate(['/home']);
  }

  setSelected(item: string) : void {
    this.defaultSection = item;
    this.setResults();
  }

  setResults(): void {
    this.isLoading = true;
    if(this.defaultSection === 'FAVORITES'){
      this.searchService.getMyFavorites(this.currentUser, 50).subscribe(
        (data) => {
          this.isLoading = false;
          this.results = data?.results?.map((section: any) => ({
            found: section?.found,
            hits: this.searchService.mapHitToDocument(section.hits, this.currentUser),
            name: section.hits[0]?.document?.section_name_facet as string,
            out_of: section.out_of
          }))[0];
        }
      );
    } else{
      this.searchService.searchBySection(this.currentUser, this.defaultSection).subscribe(
        (data) => {
          this.isLoading = false;
          this.results = data?.results?.map((section: any) => ({
            found: section?.found,
            hits: this.searchService.mapHitToDocument(section.hits, this.currentUser),
            name: section.hits[0]?.document?.section_name_facet as string,
            out_of: section.out_of
          }))[0];
        }
      );
    }
  }

  filterResult(): void{
    this.filterClicked = !this.filterClicked;
  }

  onChangeSearchTagTerm():void{
    if(this.searchTagTerm.length > 0){
      const isFavorite =  this.defaultSection === 'FAVORITES' ? true : false;
      this.searchService.searchFilters(this.searchTagTerm, 'TAG', this.defaultSection, isFavorite, this.authService.getCurrentUser()).subscribe((data) => {
        this.allTags = data.facet_counts?.filter((tag:{count:number, value: string}) => tag?.value !== this.tagsFilters?.find(t => t === tag?.value));
        this.displayTagFilters = true;
      })
    } else {
      this.displayTagFilters = false;
    }
  }

  onTagSelected(tag: string):void{
    this.tagsFilters.push(tag);
    this.displayTagFilters = false;
    this.displayInputTag = false;
    this.searchTagTerm = '';
    this.filterAllResult();
  }

  deleteTag(index: number):void{
    this.tagsFilters.splice(index, 1);
    this.filterAllResult();
  }

  closeSearchTagsResults():void{
    this.displayTagFilters = false;
  }

  @HostListener('document:click', ['$event'])
  clickTagOutside(event: any): void {
    this.wasTagInside = this.tagContainer?.nativeElement.contains(event?.target);
    this.displayTagFilters = false;
  }

  displayTag():void{
    this.displayInputTag = true;
  }

  onChangeSearchDataSourceTerm():void{
    if(this.searchDataSourceTerm.length > 0){
      const isFavorite =  this.defaultSection === 'FAVORITES' ? true : false;
      this.searchService.searchFilters(this.searchDataSourceTerm, 'DATA_SOURCE', this.defaultSection, isFavorite, this.authService.getCurrentUser()).subscribe((data) => {
        this.allDataSources = data.facet_counts?.filter((s:{count:number, value: string}) => s?.value !== this.dataSourcesFilters?.find(t => t === s?.value));
        this.displayDataSourcesFilters = true;
      })
    } else {
      this.displayDataSourcesFilters = false;
    }
  }

  onDataSourceSelected(dataSource: string):void{
    this.dataSourcesFilters.push(dataSource);
    this.displayDataSourcesFilters = false;
    this.displayInputDataSource = false;
    this.searchDataSourceTerm = '';
    this.filterAllResult();
  }


  deleteDataSource(index: number):void{
    this.dataSourcesFilters.splice(index, 1);
    this.filterAllResult();
  }

  closeDataSourcesResults():void{
    this.displayDataSourcesFilters = false;
  }

  @HostListener('document:click', ['$event'])
  clickDataSourcesOutside(event: any): void {
    this.wasInsideDataSource = this.dataSourcesContainer?.nativeElement.contains(event?.target);
    this.displayDataSourcesFilters = false;
  }

  displayDataSource():void{
    this.displayInputDataSource = true;
  }

  clearAllFilters(): void{
    this.dataSourcesFilters = [];
    this.tagsFilters = [];
    this.displayInputDataSource = true;
    this.displayInputTag = true;
    this.setResults();
  }

  filterAllResult(): void{
    this.isLoading = true;
    const isFavorite =  this.defaultSection === 'FAVORITES' ? true : false;
    this.searchService.searchByFilters(this.defaultSection, isFavorite, this.dataSourcesFilters, this.tagsFilters, this.currentUser).subscribe(
      (data) => {
        this.isLoading = false;
        this.results = data?.results?.map((section: any) => ({
          found: section?.found,
          hits: this.searchService.mapHitToDocument(section.hits, this.currentUser),
          name: section.hits[0]?.document?.section_name_facet as string,
          out_of: section.out_of
        }))[0];
      }
    );
  }

}
