import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, takeUntil } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { API_URLS } from 'src/environments/environment';
import { GRAPH_ENDPOINT_GET_PHOTO } from 'src/environments/environment';
import { environmentEnSettings, environmentFrSettings } from '../constants/date';
import { IUser } from '../interfaces/user.interface';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly emailUser = localStorage.getItem('emailUser2')

  private currentUserSubject = new BehaviorSubject<IUser>({} as IUser);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  loggedIn = false;
  connectedUser!: IUser;
  environmentSettings = environmentEnSettings;
  imageToShow!: SafeUrl;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translate: TranslateService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private snackbarService: SnackbarService,
    private analyticsService: AnalyticsService,
    private router: Router) { }

  async updateLoggedInStatus(): Promise<void> {
    return new Promise((resolve) => {
      let connectWithEmail = localStorage.getItem('connectWithEmail')
      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
        this.getUserDetails().subscribe((userData: IUser) => {
          // Set current user data into observable
          this.currentUserSubject.next(userData);
          // Set isAuthenticated to true
          this.isAuthenticatedSubject.next(true);
          const languages = userData.languages.length > 0 ? userData?.languages?.map(l => l?.code) : ['en'];
          const currentLanguage = languages?.find(l => l?.toLowerCase()?.includes('en')) ? languages?.find(l => l?.toLowerCase()?.includes('en')) : languages[0] ;
          this.setLanguageTimeZone(currentLanguage || 'en');
          if (!connectWithEmail) {
          this.getImage().subscribe(((blob) => {
            let urlCreator = window.URL || window.webkitURL;
            this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(
              urlCreator.createObjectURL(blob)
            );
          }))
        }
        resolve();
        },
          (err) => {
            if (err.status === 403 && !connectWithEmail) {
              this.router.navigate(["/forbidden"]);
            }
            else {
            this.isAuthenticatedSubject.next(false);
            this.snackbarService.show('Sorry, something went wrong', 'error');
            setTimeout(() => {
              if (connectWithEmail) {
                localStorage.removeItem('connectWithEmail');
                location.reload();
              }
            }, 2000)}
          });
      });


    this.msalBroadcastService.msalSubject$.pipe(
      filter((result: EventMessage) => result.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START),
      takeUntil(this._destroying$)
    ).subscribe(() => {
      this.analyticsService.logLogin().subscribe();
    });
    })
  }

  getActiveAccount(): AccountInfo | null {
    return this.authService.instance.getActiveAccount();
  }

  private checkAndSetActiveAccount(): void {
    /**
    * If no active account set but there are accounts signed in, sets first account to active account
    * To use active account set here, subscribe to inProgress$ first in your component
    * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
    */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  // check if user is connected
  private setLoggedIn(): void {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  private getUserDetails(): Observable<IUser> {
    return this.http.get<IUser>(API_URLS.USER_DETAILS);
  }

  // logout and redirection
  logout(): void {
    // first send log to back then logout
    this.analyticsService.logLogout().pipe(finalize(() => {
      this.authService.logout();
      // Set current user to an empty object
      this.currentUserSubject.next({} as IUser);
      // Set auth status to false
      this.isAuthenticatedSubject.next(false);
    })).subscribe(() => { });
  }

  getCurrentUser(): IUser {
    return this.currentUserSubject.value;
  }

  setLanguageTimeZone(language: string): void {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
  }

  getImage(): Observable<Blob> {
    return this.http
      .get(GRAPH_ENDPOINT_GET_PHOTO, {

        responseType: 'blob',
        headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  destroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
