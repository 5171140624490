import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './core/config/config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { I18nHttpLoaderFactory } from './core/config/i18n-factory';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './pages/user/home/home.module';
import { FooterComponent } from './features/footer/footer.component';
import { HeaderComponent } from './features/header/header.component';
import { ReportDetailsModule } from './pages/user/report-details/report-details.module';
import { ClickOutsideDirective } from './core/directives/click-outside.directive';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { HasAuthorizationDirective } from './core/directives/has-authorization.directive';
import { AdminGuard } from './core/guards/admin-guard.service';
import { DashboardModule } from './pages/admin/dashboard/dashboard.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchResultsModule } from './pages/user/search-results/search-results.module';
import { SeeAllResultsModule } from './pages/user/see-all-results/see-all-results.module';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { CustomMissingTranslationHandler } from './core/config/CustomMissingTranslationHandler';
import { PrivacyTeamsComponent } from './pages/privacy-teams/privacy-teams.component';
import { TermsTeamsComponent } from './pages/terms-teams/terms-teams.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { TeamsAuthPopupComponent } from './pages/teams-auth-popup/teams-auth-popup.component';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ClickOutsideDirective,
    HasAuthorizationDirective,
    PrivacyTeamsComponent,
    TermsTeamsComponent,
    ForbiddenPageComponent,
    TeamsAuthPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    ReportDetailsModule,
    DashboardModule,
    SearchResultsModule,
    SeeAllResultsModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: I18nHttpLoaderFactory,
        deps: [HttpClient, Injector],
      },
    }),
    MsalModule,
    PowerBIEmbedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AdminGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
