import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportDetailsService } from './report-details.service';
import { ReportDetailsComponent } from './report-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReportHeaderComponent } from 'src/app/features/report-header/report-header.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { NgxDocViewerModule } from 'ngx-doc-viewer';


@NgModule({
  declarations: [
    ReportDetailsComponent,
    ReportHeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forRoot(),
    PowerBIEmbedModule,
    NgxDocViewerModule

  ],
  exports: [
    ReportDetailsComponent,
    ReportHeaderComponent
  ],
  providers:[
    ReportDetailsService,
  ]
})
export class ReportDetailsModule { }
