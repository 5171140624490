import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  isDarkMode = true;

  constructor() {}

  toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
  }
}
