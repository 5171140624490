<div class="result-container">
  <div class="result-content">
    <div class="mb-5">
      <ng-container *ngFor="let item of searchService.facetResults; let i = index">
        <app-sliders-list [sliderType]="sliderTypesEnum.SECTION" [index]="i" [section]="item"></app-sliders-list>
      </ng-container>
    </div>
  </div>
</div>
<div class="position-relative loader-container mt-5 pt-5" *ngIf="!searchService.facetResults?.length">
  <app-loader></app-loader>
</div>
