import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appHasAuthorization]'
})
export class HasAuthorizationDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private viewContainer: ViewContainerRef
  ) {}

  isAdmin!: boolean;

  ngOnInit(): void {
    this.authService.isAuthenticated.subscribe(
      (isAuthenticated) => {
        if (isAuthenticated && this.isAdmin && this.authService.getCurrentUser().isAdmin) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else{
          this.viewContainer.clear();
        }
      }
    );
  }

  @Input() set appHasAuthorization(condition: boolean) {
    this.isAdmin = condition;
  }

}
