<section class="all-data-sources-container d-flex flex-column p-5">
  <div class="all-data-sources-header my-3">
    <h4 class="mb-3">DATA SOURCE MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>ALL DATA SOURCES</h6>
      <div class="d-flex align-items-center">
        <button class="btn-action btn px-4 py-2 me-3" type="button" role="button"
          attr.aria-label="button add data_sources" id="btn-add-data-sources"
          (click)="addDataSource()">Add A Data Source</button>
      </div>
    </div>
  </div>
  <div class="all-data-sources-content mt-4">
    <div class="d-flex justify-content-end">
      <span
        class="result-text">Showing {{skip + 1}}-{{end}} of {{total}} items
      </span>
    </div>
    <div class="table-all-data-sources pb-5">
      <table class="table table-hover table-striped mt-3">
        <thead>
          <tr>
            <ng-container *ngFor="let header of tableHeader;">
              <th scope="col" class="text-center pointer" (click)="sortTable(header)">
                <div class="d-flex justify-content-between align-items-center mx-2">
                  <div> {{ header.label }}</div>
                  <i class="icon icon-14 ml-2" [ngClass]="header?.direction ? 'icon-polygon-down' : 'icon-polygon'"></i>
                </div>
              </th>
            </ng-container>
            <th scope="col" class="text-center">DISABLE</th>
            <th scope="col" class="text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          <div class="loader-container position-absolute pt-5 mt-5" *ngIf="!dataSources.length">
            <app-loader></app-loader>
          </div>
          <tr *ngFor="let dataSource of dataSources">
            <td class="col-md-1">{{dataSource?.id}}</td>
            <td class="col-md-auto">{{dataSource?.name}}</td>
            <td class="col-md-auto">{{dataSource?.refreshDate ?? ''}}</td>
            <td class="col-md-1">
              <div class="d-flex justify-content-center">
                <i class="icon icon-16 icon-checkmark-black" *ngIf="!dataSource?.enabled"></i>
              </div>
            </td>
            <td class="col-md-2">
              <div class="actions d-flex justify-content-center ms-5">
                <div class="icon icon-25 icon-edit pointer" (click)="editDataSource(dataSource.id)"
                  tooltip="Edit" placement="bottom"
                  containerClass="tooltipClass"></div>
                <div role="button" class="icon icon-25 icon-delete ms-2 pointer"
                  (click)="deleteDataSource(dataSource.id)"
                  tooltip="Delete" placement="bottom"
                  containerClass="tooltipClass"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end mt-3" *ngIf="dataSources?.length">
        <pagination
          [directionLinks]="false"
          [totalItems]="total"
          [itemsPerPage]="limit"
          [(ngModel)]="currentPage"
          (pageChanged)="pageChanged($event)">
        </pagination>
    </div>
    </div>
  </div>
</section>
