import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { exportTypeEnum, sourceTypeEnum } from 'src/app/core/enum/report.enum';
import { IAttachment, IReport } from 'src/app/core/interfaces/report.interface';
import { ReportDetailsService } from './report-details.service';
import { IEmbedConfiguration, models, Page, Report, service, factories } from 'powerbi-client';
import { MsalService } from '@azure/msal-angular';
import { NgxDocViewerComponent, viewerType } from 'ngx-doc-viewer';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { IELearning } from 'src/app/core/interfaces/e-learning.interface';
import { VisibilityEnum } from 'src/app/core/enum/visibility.enum';
@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss'],
})
export class ReportDetailsComponent implements OnInit {
  readonly sourceTypes = sourceTypeEnum;

  @ViewChild('fs') fs!: ElementRef;
  @ViewChild('viewer', { static: false }) viewer!: NgxDocViewerComponent;
  @ViewChild('container') container!: ElementRef;

  powerbi!: service.Service;
  report!: IReport;
  isLoading = false;
  reportUrl!: SafeResourceUrl;
  isTagsClosed = false;
  reportConfig: IEmbedConfiguration = {
    tokenType: models.TokenType.Aad,
    settings: {
      persistentFiltersEnabled: true,
      navContentPaneEnabled: false,
      filterPaneEnabled: false,
      personalBookmarksEnabled: true,
      panes: {
        bookmarks: {
          visible: true
        },
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  };

  currentReport!: Report;

  pages: Page[] = [];
  activePage!: Page;
  viewerType: viewerType = 'office';
  isPdf = false;
  powerbiToken = '';
  loadingExport = false;
  reportAttachments: IAttachment[] = [];
  readonly visibilityEnum = VisibilityEnum;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportDetailsService,
    public sanitizer: DomSanitizer,
    private auth: MsalService,
    private snackbarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
    this.setReportDetails();

  }

  setReportDetails(): void {
    this.isLoading = true;
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const reportId = paramMap.get('id');
          if (reportId) {
            return this.reportService.getReport(reportId);
          }
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          if (data.visibility === this.visibilityEnum.UNAVAILABLE) {
            this.router.navigate(['/home']);
          }
          else {
            this.report = data;
            this.reportAttachments = data?.attachments as IAttachment[];
            this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.report.embedUrl);
            this.isLoading = false;
            if (this.report.sourceType === 'POWER_BI') {
              this.doEmbedPowerBiItem();
            }

            if (this.report.sourceType === 'FILE') {
              this.viewerType = this.report.mainFile?.name?.includes('pdf') ? 'pdf' : 'office';
              this.isPdf = this.viewerType === 'pdf' ? true : false;
            }
          }
        }
      });
  }

  toggleFavorite(): void {
    if (this.report?.isFavorite) {
      this.reportService.removeFromFavorite(this.report?.id).subscribe((data) => {
        this.report = data;
      })
    } else {
      this.reportService.addToFavorite(this.report?.id).subscribe((data) => {
        this.report = data;
      })
    }
  }

  toggleTags(): void {
    this.isTagsClosed = !this.isTagsClosed;
  }

  openFullscreen(): void {
    if (this.report.sourceType === sourceTypeEnum.POWER_BI) {
      this.currentReport.fullscreen();
    } else {
      if (this.report?.embedUrl) {
        const el = (this.report.sourceType === sourceTypeEnum.FILE && !this.isPdf) ?
          this.viewer?.iframes?.first?.nativeElement : this.fs?.nativeElement;

        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
          if (el.requestFullscreen) {
            el.requestFullscreen();
          } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
          } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen((<any>Element).ALLOW_KEYBOARD_INPUT);
          } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
          }
        }
      }
    }
  }

  doEmbedPowerBiItem(): void {
    this.auth.acquireTokenSilent({ scopes: ['https://analysis.windows.net/powerbi/api/.default'] }).subscribe(result => {
      this.powerbiToken = result.accessToken;
      this.reportConfig = {
        ...this.reportConfig,
        type: 'report',
        accessToken: result.accessToken,
        embedUrl: this.report.embedUrl,
        id: this.report.sourceId
      }
      this.powerbi.reset(this.container.nativeElement);
      this.currentReport = <Report>this.powerbi.embed(this.container.nativeElement, this.reportConfig);
      this.currentReport.on('loaded', () => {
        this.currentReport.getPages().then((pages) => {
          this.pages = pages.filter(page => page?.visibility === 0);
          return this.currentReport?.getActivePage()?.then((page) => {
            this.activePage = page;
          })
        });
      });
    });
  }

  onPageClicked(page: Page): void {
    page.setActive();
    this.activePage = page;
  }

  onPrintClicked(): void {
    if (this.report.sourceType === sourceTypeEnum.POWER_BI) {
      this.currentReport?.print();
    }
  }

  async onExportClicked(event: exportTypeEnum | string): Promise<void> {

    var bookmark =await this.currentReport.bookmarksManager.capture();
    var state = bookmark.state != undefined ? bookmark.state : ''
    this.loadingExport = true;
    if (this.report.sourceType === sourceTypeEnum.POWER_BI) {
      this.reportService.exportPoFile(this.report?.id, event, this.powerbiToken, state).subscribe((data) => {

        this.onExportSuccess(data);
      },
        () => {
          this.snackbarService.show('Sorry, something went wrong', 'error')
          this.loadingExport = false;
        });
    } else {
      this.reportService.exportFile(this.report?.id).subscribe((data) => {
        this.onExportSuccess(data);
      },
        () => {
          this.snackbarService.show('Sorry, something went wrong', 'error')
          this.loadingExport = false;
        });
    }
  }

  onExportSuccess(file: Blob): void {
    const downloadURL = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = this.report.sourceType === sourceTypeEnum.POWER_BI ? `${this.report.name}` : `${this.report.mainFile?.name}`;
    link.click();
    this.loadingExport = false;
  }

  onLearningClick(learning: IELearning): void {
    if (learning?.url) {
      window.open(learning?.url || '', "_blank");
    }
  }

  onDocumentClick(doc: IAttachment): void {
    if (doc?.id) {
      this.reportService.downloadAttachment(this.report?.id, doc?.id).subscribe((data) => {
        const link = document.createElement('a');
        link.href = data.downloadUrl;
        link.download = data.name;
        link.click();
      },
        () => this.snackbarService.show('Sorry, something went wrong', 'error'));
    }
  }
}
