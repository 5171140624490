<section class="group-container d-flex flex-column p-5">
  <div class="group-header my-3">
    <h4 class="mb-3">USER RIGHT & MANAGEMENT</h4>
    <div class="d-flex justify-content-between">
      <h6>{{group ? 'EDIT GROUP' : 'CREATE A GROUP'}}</h6>
    </div>
  </div>
  <div class="group-content mt-4" *ngIf="groupForm">
    <form [formGroup]="groupForm" class="g-3">
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="" class="form-label">Name</label>
          <input class="form-control input" formControlName="name"
            [ngClass]="{'is-invalid': groupForm.controls.name.invalid && groupForm.controls.name.touched}">
          <div *ngIf="groupForm.controls.name.errors?.required && groupForm.controls.name.touched"
            class="invalid-feedback">
            a group needs a name!
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="" class="form-label">Business Object Groups</label>
          <ng-select class="custom input-uppercase" [items]="groupBO" bindLabel="name" bindValue="sourceId"
            formControlName="sourcegroupsBO" ngDefaultControl [closeOnSelect]="false" [multiple]="true" id="">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="ng-label-tmp d-flex align-items-center px-2 br-4" (mousedown)="$event.stopPropagation()">
                <span>{{item.name}}</span>
                <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
              <span class="ml-2"> {{item.name}} </span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="" class="form-label">Success Factors Groups</label>
          <ng-select class="custom input-uppercase" [items]="groupSF" bindLabel="name" bindValue="sourceId"
            formControlName="sourcegroupsSF" ngDefaultControl [closeOnSelect]="false" [multiple]="true" id="">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="ng-label-tmp d-flex align-items-center px-2 br-4" (mousedown)="$event.stopPropagation()">
                <span>{{item.name}}</span>
                <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
              <span class="ml-2"> {{item.name}} </span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <label for="users" class="form-label">Add User(s)</label>
          <ng-select class="custom input-uppercase" [items]="users" bindLabel="email" bindValue="id"
            formControlName="users" ngDefaultControl [closeOnSelect]="false" [multiple]="true" id="users">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="ng-label-tmp d-flex align-items-center px-2 br-4" (mousedown)="$event.stopPropagation()">
                <span>{{item.email}}</span>
                <i class="icon icon-12 icon-cross-white ms-2 pointer" (click)="clear(item)"></i>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
              <span class="ml-2"> {{item.email}} </span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-12 mb-3">
        <label for="" class="form-label">Disable</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="enabled" id="dataSourceDisabled"
            aria-describedby="invalidCheck3Feedback">
          <label class="form-check-label ms-2" for="">
            Yes, disable this group
          </label>
        </div>
      </div>
      <div class="col-12 mt-5">
        <button class="btn-action btn px-4 py-2 me-3" [disabled]="groupForm.invalid || !isResourceLoad" (click)="submitSourceGroupe()"
          type="button" role="button" attr.aria-label="button submit group" id="btn-submit-group">
          {{group ? 'Edit Group' : 'Create A Group'}}
        </button>
      </div>
    </form>
  </div>
</section>
