<div class="alert mt-4  d-flex flex-sm-row flex-column align-items-md-center" *ngIf="searchService.news?.latestAlert">
  <div class="icon icon-30 icon-alert-outline-light-red"></div>
  <div class="header">
    <div class="subtitle">{{ searchService.news?.latestAlert?.startsAt | dateFormat: 'short'}} - {{
      searchService.news?.latestAlert?.startsAt | date: 'hh:mm'}}</div>
    <div class="title font-size-14 trenda-bold ">
      {{searchService.news?.latestAlert?.title}}
    </div>
  </div>
  <div class="content font-size-14">
    {{searchService.news?.latestAlert?.message}}
  </div>
</div>
<div class="container-fluid p-5 ms-lg-4">
  <div class="row flex-lg-row align-items-start g-5 py-5 ms-lg-2" *ngIf="latestNews?.length">
    <!--     <div class="col-lg-5 col-xl-5 col-xxl-4 d-flex flex-column align-items-start justify-content-start pe-0">
      <h3 class="mt-2 font-size-20">HR KPIs</h3>
      <div class="d-flex align-items-center justify-content-start">
        <div class="card-kpi card me-2">
          <div class="card-header" [ngClass]="themeService.isDarkMode ? 'card-header-dark-bg' : 'card-header-light-bg'">
            HEADCOUNT
          </div>
          <div class="card-body ps-4 w-100 h-100">
            <div class="d-flex justify-content-start align-items-center">
              <p class="trenda-bold text-blue font-size-19 mb-2">-3,9%</p>
            </div>
            <div class="data-source ms-2 mt-2 font-size-12 text-black">Data source: 2021</div>
          </div>
        </div>
        <div class="card card-kpi">
          <div class="card-header" [ngClass]="themeService.isDarkMode ? 'card-header-dark-bg' : 'card-header-light-bg'">
            GENDER BALANCE
          </div>
          <div class="card-body h-100 w-100">
            <div class="d-flex justify-content-center align-items-center mt-2">
              <p class="trenda-bold text-dark-slate-blue font-size-19 mb-0">65%</p>
              <div class="vr mx-4 bg-secondary"></div>
              <p class="trenda-bold text-blue font-size-19 mb-0">45%</p>
            </div>
            <div class="data-source ms-2 mt-2 font-size-12 text-black">Data source: 2021</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-12 col-sm-7 px-lg-0 col-lg-7 col-xl-7 col-xxl-8">
      <ng-container *ngIf="latestNews?.length">
        <h3 class="font-size-20">{{ "HOME.LATEST_NEWS_TITLE" | translate }}</h3>
        <div id="carouselExampleIndicators" class="carousel mt-4 slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button *ngFor="let news of latestNews; index as i; first as isFirst" [id]="'btn-slide-' + i" type="button"
              data-bs-target="#carouselExampleIndicators" [attr.data-bs-slide-to]="i" [ngClass]="{'active': isFirst}"
              aria-current="true" aria-label="Slide 1"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let item of latestNews; index as i; first as isFirst"
              [ngClass]="{'active': isFirst}">
              <div class="card h-100">
                <div class="card-header py-2 mt-3 font-size-16 text-blue">{{item.title}}</div>
                <div class="card-body bg-transparent ">
                  <div class="blockquote mb-0">
                    <footer class="blockquote-footer mb-0"><cite title="Source Title">
                        {{ item.startsAt | dateFormat: 'short'}} {{ item.startsAt | date: 'hh:mm'}}
                      </cite></footer>
                    <p class="font-size-16">
                      {{item.message}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>
